
import { Component } from 'vue-property-decorator'
import ProcessingStepMixin from '../../ProcessingStepMixin.vue'
import { IPSAddtitionalProcessingStep } from '@/types/processingSteps'

@Component({
  components: {
    ProcessingStepMixin
  }
})
export default class ProjectAdditionalStepMixin extends ProcessingStepMixin {
  // todo add or use a type for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAdditonalSteps (additionalSteps: any[]): IPSAddtitionalProcessingStep[] {
    return additionalSteps.map((step) => {
      return {
        key: step.slug,
        title: step.title,
        completed: this.stepIsCompleted(step),
        required: this.stepHasRequiredFields(step),
        active: false,
        buttons: {
          save: true,
          saveAndNext: true
        },
        additional_step: step,
        form_errors: {}
      }
    })
  }

  // todo add or use a type for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepIsCompleted (additionalStep: any): boolean {
    // todo add or use a type for this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return additionalStep.additional_fields.every((field: any) => {
      if (!field.required) {
        return true
      }
      return field.value.value !== null && field.value.value !== undefined && field.value.value !== ""
    })
  }

  // todo add or use a type for this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepHasRequiredFields (additionalStep: any): boolean {
    // todo add or use a type for this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return additionalStep.additional_fields.some((field: any) => field.required)
  }
}
