
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import SingleSelectField from '@/components/processing_steps/commons/fields/SingleSelectField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { IPSAdminEditOrganization, IPSAdminOrgaCompletionForm, IPSSelectOption } from '@/types/processingSteps'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    SingleSelectField
  }
})
export default class AdminOrgaCompletitionForm extends Vue {
  // todo: remove organization and set the other select options as for available_content_review_status
  @Prop() organization!: IPSAdminEditOrganization
  @Prop() completitionForm: IPSAdminOrgaCompletionForm
  @Prop() available_content_review_status: IPSSelectOption[]
}
