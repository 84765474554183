
import { IHdsFilterValueBadge } from '@/types/hdsFilter'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FilterValueBadge extends Vue {
  @Prop() badgeItem!: IHdsFilterValueBadge
  @Prop({ default: '' }) customClass!: string

  get badgeText(): string {
    return this.badgeItem.text
  }

  get isEditable (): boolean {
    return this.badgeItem.isEditable
  }

  onClick() {
    this.$emit('clicked', this.badgeItem)
  }
}
