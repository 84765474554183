
import { Component, Vue } from 'vue-property-decorator'
import { isArray } from 'lodash'

@Component
export default class ShowcaseCategoryMixin extends Vue {
  get categoryKey (): string {
    return isArray(this.$route.query.category) ? this.$route.query.category[0] : this.$route.query.category
  }

  get categoryOption (): string {
    if (this.categoryKey === "promotion_recommendation") {
      return "1"
    } else if (this.categoryKey === "funding_request") {
      return "2"
    } else if (this.categoryKey === "funding_result") {
      return "3"
    }
  }

  get categoryDisplay (): string {
    return this.getDisplayByKey(this.categoryKey)
  }

  getDisplayByKey (categoryKey): string {
    if (categoryKey === "promotion_recommendation") {
      return this.$gettext("Promotion recommendation")
    } else if (categoryKey === "funding_request") {
      return this.$gettext("Funding request")
    } else if (categoryKey === "funding_result") {
      return this.$gettext("Funding result")
    }
  }
}
