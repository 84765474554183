
import ToastMixin from '@/mixins/ToastMixin'
import axios from 'axios'
import { isArray } from 'lodash'
import { Component, Prop } from 'vue-property-decorator'
import { SITE_URLS } from '@/utils/helpers'
import { IPSAddtitionalProcessingStep, IPSSelectOption, IProcessingStep } from '@/types/processingSteps'

@Component
export default class ProcessingStepMixin extends ToastMixin {
  @Prop() stepKeyFromUrl: string
  @Prop() isSiteAdmin: boolean
  activeStep: IProcessingStep | IPSAddtitionalProcessingStep | null = null
  steps: (IProcessingStep | IPSAddtitionalProcessingStep)[] = []
  usersOrganizations: IPSSelectOption[] = []
  stepFromUrl: IProcessingStep | IPSAddtitionalProcessingStep | null = null

  mounted () {
    this.stepFromUrl = this.steps.find(step => step.key === this.stepKeyFromUrl)
  }

  async fetchUsersOrganizations(): Promise<void> {
    // todo put to api urls
    await axios.get("/api/v4/processing-steps/users-organizations/")
      .then(response => {
        this.usersOrganizations = response.data
      })
      .catch(() => {
        this.showError({
          title: this.$gettext("Oops! Something went wrong."),
          message: this.$gettext("Error fetching organization. Please contact an administrator.")
        })
      })
  }

  isPreviousStep(stepToGo: IProcessingStep | IPSAddtitionalProcessingStep, currentStep: IProcessingStep | IPSAddtitionalProcessingStep, allSteps: (IProcessingStep | IPSAddtitionalProcessingStep)[]) {
    const currentStepIndex = allSteps.findIndex(step => step.key === currentStep.key)
    const stepToGoIndex = allSteps.findIndex(step => step.key === stepToGo.key)
    return stepToGoIndex < currentStepIndex
  }

  setActiveStep (step: IProcessingStep | IPSAddtitionalProcessingStep | null): void {
    if (step !== null && step !== undefined) {
      this.activeStep = step
      this.setUrlParam(this.activeStep.key)
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }
    const stepKey = this.stepKeyFromUrl

    if (stepKey && stepKey !== "undefined") {
      this.activeStep = this.steps.find(step => step.key === stepKey)
      if (this.activeStep === undefined) {
        this.activeStep = this.steps[0]
      }
      this.stepFromUrl = this.activeStep
      if (this.stepFromUrl) {
        this.activeStep = this.stepFromUrl
        this.setUrlParam(this.stepFromUrl.key)
      }
    } else {
      if (this.steps.length > 0) {
        this.activeStep = this.steps[0]
      }
    }
  }

  setUrlParam (urlKey: string) {
    const url = new URL(window.location.href)
    const key = this.activeStep ? this.activeStep.key : null
    if (key !== null && key !== undefined && key !== "undefined") {
      if (!urlKey) {
        url.searchParams.set('step', key)
      } else {
        url.searchParams.set('step', urlKey)
      }
      window.history.pushState({}, '', url.toString())
    }
  }

  showInformation (infoData: { title: string, message: string }): void {
    this.makeToast("warning", infoData.title, infoData.message)
  }

  showError (errorData: { title: string, message: string } | null): void {
    let message = errorData && errorData.message ? errorData.message : this.$gettext("Please correct the errors in the form.")
    if (errorData && errorData.message && Array.isArray(errorData.message)) {
      message = errorData && errorData.message ? errorData.message.join(", ") : this.$gettext("Please correct the errors in the form.")
    }
    const title = errorData && errorData.title ? errorData.title : this.$gettext("Error")
    this.makeToast("danger", title, message)
    const psContent = document.querySelector('.ps-content')
    if (psContent) {
      if (psContent) {
        psContent.classList.add('shake')
        setTimeout(() => {
          psContent.classList.remove('shake')
        }, 500)
      }
    }
  }

  showSuccessMsg (successData: { title: string, message: string } | null) {
    const title = successData ? successData.title : this.$gettext("Success")
    // todo use propper translation for default message
    const defaultMessage = this.activeStep.title + " " + this.$gettext("saved successfully.")
    const message = successData ? successData.message : defaultMessage
    this.makeToast("success", title, message)
  }

  getNextStep(steps: (IProcessingStep | IPSAddtitionalProcessingStep)[], currentStep: IProcessingStep | IPSAddtitionalProcessingStep): IProcessingStep | IPSAddtitionalProcessingStep {
    const currentStepIndex = steps.findIndex(step => step.key === currentStep.key)
    if (currentStepIndex === steps.length - 1) {
      return null
    }
    return steps[currentStepIndex + 1]
  }

  gotoNextStepOrFinish(steps: (IProcessingStep | IPSAddtitionalProcessingStep)[], currentStep: IProcessingStep | IPSAddtitionalProcessingStep): void {
    const nextStep = this.getNextStep(steps, currentStep)
    if (nextStep) {
      this.setActiveStep(nextStep)
    } else {
      this.finish("project")
    }
  }

  gotoPreviousStep(steps: (IProcessingStep | IPSAddtitionalProcessingStep)[], currentStep: IProcessingStep | IPSAddtitionalProcessingStep): void {
    const currentStepIndex = steps.findIndex(step => step.key === currentStep.key)
    if (currentStepIndex === 0) {
      return
    }
    const previousStep = steps[currentStepIndex - 1]
    this.setActiveStep(previousStep)
  }

  finish(stepType: string): void {
    if (stepType === "project") {
      if (this.isSiteAdmin) {
        window.location.href = SITE_URLS.FOERDER_APP.SITE_ADMIN.PROJECT.LIST
      } else {
        window.location.href = SITE_URLS.FOERDER_APP.ACCOUNT.PROJECT.LIST
      }
    }
    // todo redirect to some list, according to the processing step type (project, orga...)
  }

  setAdditionalStepValid(step: IProcessingStep | IPSAddtitionalProcessingStep) {
    this.steps = this.steps.map(s => {
      if (s.key === step.key) {
        s.completed = true
      }
      return s
    })
  }

  setAdditionalStepInvalid(step: IProcessingStep | IPSAddtitionalProcessingStep) {
    this.steps = this.steps.map(s => {
      if (s.key === step.key) {
        s.completed = false
      }
      return s
    })
  }

  allStepsValid (): boolean {
    let valid = true
    this.steps.forEach(step => {
      if (!step.completed && step.required) {
        valid = false
      }
    })
    return valid
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormErrorsFromErrorResponse (error: any, form: any): void {
    if (error.response && error.response.data) {
      const errors = error.response.data
      Object.keys(errors).forEach(key => {
        if (key in form) {
          const fieldError = isArray(errors.key) ? errors.key.join(", ") : errors.key
          form.key = fieldError
        }
      })
    }
  }

  isIPSAdditionalProcessingStep(step: IProcessingStep | IPSAddtitionalProcessingStep): boolean {
    return (step as IPSAddtitionalProcessingStep).additional_step !== undefined
  }

  get termsAndPrivacyHtml (): string {
    return this.$gettext('I agree to the <a href="https://www.hausdesstiftens.org/rechtliches/nutzungsbedingungen/">terms of use</a> and the <a href="https://www.hausdesstiftens.org/rechtliches/datenschutz/">privacy policy</a>.')
  }
}
