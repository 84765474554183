
import { Component, Vue, Prop } from 'vue-property-decorator'
import CharField from '../../commons/fields/CharField.vue'
import EmailInput from '../../commons/fields/EmailInput.vue'
import FormContainer from '../../commons/forms/FormContainer.vue'
import AddressForm from '../../commons/forms/AddressForm.vue'
import { IPSOrgaBaseInfoForm } from '@/types/processingSteps'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import EditButton from '@/components/buttons/EditButton.vue'

@Component({
  components: {
    CharField,
    FormContainer,
    EmailInput,
    AddressForm,
    EditButton
  }
})
export default class BaseInformationForm extends Vue {
  @Prop() form!: IPSOrgaBaseInfoForm
  @Prop() formErrors!: IPSOrgaBaseInfoForm
  @Prop({ default: false }) readOnly!: boolean
  @Prop({ default: false }) organizationDataIsSubmitted!: boolean

  emailChangeModalOpen = false
  emailChangeError = ''

  async changeOrganizationEmail (): Promise<void> {
    if (!this.isValidEmail(this.form.email)) {
      this.emailChangeError = this.$gettext('Please enter a valid email address')
      return
    }
    if (await this.validateEmail()) {
      this.emailChangeModalOpen = false
      this.$emit('change-orga-email')
    }
  }

  isValidEmail (value: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(value)
  }

  async validateEmail(): Promise<boolean> {
    let valid = false
    const data = {
      promoter_email: this.form.email
    }
    await axios.post(API_URLS.SIGNUP.VALIDATE, data).then(() => {
      this.emailChangeError = ''
      valid = true
    }).catch((error) => {
      this.emailChangeError = error.response.data.email
    })
    return valid
  }
}
