
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IHdsFilter, IHdsFilterSet } from '@/types/hdsFilter'
import AddButton from '../buttons/AddButton.vue'
import DeleteButton from '../buttons/DeleteButton.vue'
import EditButton from '../buttons/EditButton.vue'
import GenericTable from '../GenericTable.vue'
import ConfirmModal from '../processing_steps/commons/modals/ConfirmModal.vue'
import FilterValueBadge from '../hds_filter/FilterValueBadge.vue'

@Component({
  components: {
    EditButton,
    AddButton,
    DeleteButton,
    GenericTable,
    ConfirmModal,
    FilterValueBadge
  }
})
export default class FilterTable extends Vue {
  @Prop({ required: true }) filterSets!: IHdsFilterSet[]

  filterSetToDelete: IHdsFilterSet | null = null
  deleteModalOpen = false

  fields = [
    {
      key: 'index_col',
      label: this.$gettext('Group'),
      sortable: true
    },
    {
      key: 'filter_values',
      label: this.$gettext('Filter Values')
    },
    {
      key: 'projects_count',
      label: this.$gettext('Count')
    },
    {
      key: 'fixed_action_col',
      label: this.$gettext('Actions')
    }
  ]

  customFields = ["filter_values", "index_col", "projects_count"]

  // another function determinates if the filter itself can be edited
  canEditFilterSet (filterSet: IHdsFilterSet) {
    return !filterSet.isAdminFilterSet
  }

  removeFilterValue(filterSet: IHdsFilterSet, filter: IHdsFilter, value: string | number | boolean) {
    this.$emit('removeFilterValue', filterSet, filter, value)
  }

  openDeleteModal(filterSet: IHdsFilterSet) {
    this.filterSetToDelete = filterSet
    this.deleteModalOpen = true
  }

  deleteFilterSet() {
    this.deleteModalOpen = false
    setTimeout(() => {
      this.$emit('deleteFilterSet', this.filterSetToDelete)
    }, 400)
  }

  getFilterText(filter: IHdsFilter): string[] {
    return filter.values.map(value => {
      const option = filter.filterOptions.find(opt => opt.value === value)
      return option ? option.text : value.toString()
    })
  }
}
