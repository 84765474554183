
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FormContainer,
    CheckboxField
  }
})
export default class CompletitionLayout extends Vue {
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[] }[]}[]
  @Prop() stepTitle!: string
  @Prop() statusTitle!: string
  @Prop() statusExplanation!: string
  @Prop({ default: null }) statusIconClass!: string | null
  @Prop({ default: null }) summaryDescription!: string | null
}
