
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import CharField from '@/components/processing_steps/commons/fields/CharField.vue'
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import SingleSelectField from '@/components/processing_steps/commons/fields/SingleSelectField.vue'
import TextField from '@/components/processing_steps/commons/fields/TextField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { IPSAdminCompletionForm, IPSSelectOption } from '@/types/processingSteps'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CheckboxField,
    TextField,
    FormTitle,
    FormContainer,
    CharField,
    SingleSelectField
  }
})
export default class AdminCompletitionForm extends Vue {
  @Prop() completitionForm: IPSAdminCompletionForm
  @Prop() availableInternalStatusOptions: IPSSelectOption[]
  @Prop() availableReviewStatusOptions: IPSSelectOption[]
  @Prop() availableContentReviewStatusOptions: IPSSelectOption[]
  @Prop() availableContentStatusOptions: IPSSelectOption[]

  get isReviewStatusRejected() {
    return this.completitionForm.review_status === "3"
  }

  get isContentReviewStatusRejected() {
    return this.completitionForm.content_review_status === "3"
  }
}
