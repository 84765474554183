import { IHdsFilterSet } from '@/types/hdsFilter'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class HdsFilterMixin extends Vue {
  aQueryParamIsAlreadyHasAValueInOneOfTheAdminFilterSet (queryParam: string, isAdminFilterSets: IHdsFilterSet[]): boolean {
    return isAdminFilterSets.some((filterSet) => {
      return filterSet.filters.some((filter) => {
        return filter.query_param === queryParam && filter.values.length > 0
      })
    })
  }

  setAllAdminFilterValuesAsValuesOfTheUsersFilterset (adminFilterSets: IHdsFilterSet[], userFilterSet: IHdsFilterSet): void {
    adminFilterSets.forEach((adminFilterSet) => {
      adminFilterSet.filters.forEach((adminFilter) => {
        const userFilter = userFilterSet.filters.find((userFilter) => userFilter.query_param === adminFilter.query_param)
        if (userFilter && adminFilter.values.length > 0) {
          userFilter.values = adminFilter.values
          userFilter.readOnly = true
        }
      })
    })
  }
}
