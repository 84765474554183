
import { Component, Mixins, Prop } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import CodeStringInput from '@/components/funding_code/shared/CodeStringInput.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { IShowcasePage } from '@/types/showcase'
import ByFundingCode from '@/components/showcase_generator/project_select/ByFundingCode.vue'
import ByProject from './project_select/ByProject.vue'
import ByOrganization from './project_select/ByOrganization.vue'
@Component({
  components: {
    CodeStringInput,
    ByFundingCode,
    ByProject,
    ByOrganization
  }
})
export default class ShowcaseProjectSelect extends Mixins(UserMixin) {
  @Prop({ required: false }) showcasePage: IShowcasePage
  selectedMethod = null
  fadeClass = ''
  methodOptions = [
    {
      value: 'projects',
      text: this.$gettext("Individual")
    },
    {
      value: 'organization',
      text: this.$gettext("Select by organization")
    },
    {
      value: 'funding_code',
      text: this.$gettext("Select by funding code")
    }
  ]

  get currentProjectsCount () {
    if (this.showcasePage) {
      return this.showcasePage.projects_count
    } else {
      return 0
    }
  }

  mounted () {
    this.setInitialMethodOptions()
  }

  setInitialMethodOptions () {
    if (this.showcasePage) {
      this.selectedMethod = this.showcasePage.project_selection_method
    }
  }

  handleMethodSelected () {
    // used to create page and redirect to edit
    this.$emit('method-selected')
  }

  removeSelectedProjects (projects) {
    const formData = new FormData()
    formData.append("remove_projects", String(true))
    const projectSlugs = projects.map(item => {
      if (typeof item === 'object' && item.slug) {
        return item.slug
      }
      if (typeof item === 'string') {
        return item
      }
      return null
    }).filter(slug => slug !== null)

    for (let i = 0; i < projectSlugs.length; i++) {
      formData.append('projects', projectSlugs[i])
    }
    this.postData(formData)
  }

  removeSelectedOrganizations (organizations) {
    const formData = new FormData()
    formData.append("remove_projects", String(true))
    const organizationSlugs = organizations.map(item => {
      if (typeof item === 'object' && item.slug) {
        return item.slug
      }
      if (typeof item === 'string') {
        return item
      }
      return null
    }).filter(slug => slug !== null)

    for (let i = 0; i < organizationSlugs.length; i++) {
      formData.append('organizations', organizationSlugs[i])
    }
    this.postData(formData)
  }

  addSelectedProjects (projects) {
    const projectSlugs = projects.map(item => item.slug)
    const formData = new FormData()
    for (let i = 0; i < projectSlugs.length; i++) {
      formData.append('projects', projectSlugs[i])
    }
    this.postData(formData)
  }

  addSelectedOrganizations (organizations) {
    const organizationSlugs = organizations.map(item => item.slug)
    const formData = new FormData()
    for (let i = 0; i < organizationSlugs.length; i++) {
      formData.append('organizations', organizationSlugs[i])
    }
    this.postData(formData)
  }

  postData (formData) {
    this.fadeClass = ""
    formData.append('selected_method', this.selectedMethod)
    const slug = this.showcasePage ? this.showcasePage.slug : ''
    axios.post(API_URLS.SHOWCASE_PAGE.ADD_PROJECTS(slug), formData).then(response => {
      this.$emit("project-selection-changed", response.data)
      this.fadeClass = "fade-element"
    })
  }

  handleProjectSelectionChanged (responseData) {
    this.$emit("project-selection-changed", responseData)
  }
}
