
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import { ISummaryItem } from '@/types/processingSteps'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CompletitionLayout
  }
})
export default class AccountProjectIsFinished extends Vue {
  @Prop() projectSlug!: string
  @Prop() projectListUrl!: string
  @Prop() createProjectUrl!: string
  @Prop() summaryItems!: ISummaryItem[]

  get projectDetailUrl (): string {
    return "/project/" + this.projectSlug + "/"
  }

  gotoProjectList (): void {
    window.location.href = this.projectListUrl
  }

  gotoCreateProject (): void {
    window.location.href = this.createProjectUrl
  }
}
