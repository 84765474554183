
import { IPSEditProject } from '@/types/processingSteps'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ProjectSummaryMixin extends Vue {
  project: IPSEditProject | null = null

  get summaryItems () {
    return [
      this.organizationDetails
    ]
  }

  get organizationDetails (): { title: string, items: { title: string, values: string[]}[]} {
    return {
      title: this.$gettext('Funding requirement'),
      items: [
        {
          title: this.$gettext('Project\'s name'),
          values: [this.project.title]
        },
        {
          title: this.$gettext('Funding scope\'s name title'),
          values: [this.project.funding_scope_title || '-']
        },
        {
          title: this.$gettext('Financing need'),
          values: [this.project.goal.in_currency_display]
        },
        {
          title: this.$gettext('Project\'s location'),
          values: [this.project.street, this.project.postal_code, this.project.city, this.project.country]
        }
      ]
    }
  }

  get contactPersonDetails () {
    return {
      title: this.$gettext('Contact person'),
      items: [
        {
          title: this.$gettext('Contact person\'s name'),
          values: [this.project.public_contact_person?.addressable_name || '-']
        }
      ]
    }
  }

  formatDate (date: string): string {
    return new Date(date).toLocaleDateString()
  }

  formatStatuaryPurposes (purposes: { text: string, value: boolean, key: string }[]): string[] {
    return purposes
      .filter(purpose => purpose.value)
      .map((purpose, index) => `${index + 1}. ${purpose.text}`)
  }
}
