

import { Component, Mixins, Ref, Prop } from 'vue-property-decorator'
import ToastMixin from '@/mixins/ToastMixin'
import MobileMixin from '@/mixins/MobileMixin'
import BreadcrumbHeader from '@/components/BreadCrumbHeader.vue'
import InputGroup from '@/components/InputGroup.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import { BvTableFieldArrayWithStickColumn, IBreadcrumb, TGenericObject } from '@/types/base'
import { API_URLS } from "@/utils/helpers"
import { IFundingCode, IFundingCodeForm } from "@/types/funding-code"
import GenericTableMixin from "@/mixins/GenericTableMixin"
import GenericTable from "@/components/GenericTable.vue"
import RedirectToFormBtn from "@/components/funding_code/RedirectToFormBtn.vue"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import CodeStringInput from "@/components/funding_code/shared/CodeStringInput.vue"
import axios from "axios"
import { BvComponent } from "bootstrap-vue"
import FormContainer from '../../commons/forms/FormContainer.vue'
import DeleteButton from "@/components/buttons/DeleteButton.vue"
import ConfirmModal from '@/components/processing_steps/commons/modals/ConfirmModal.vue'

@Component({
  components: {
    BreadcrumbHeader,
    InputGroup,
    CodeStringInput,
    GenericTable,
    DeleteModal,
    RedirectToFormBtn,
    FormContainer,
    DeleteButton,
    ConfirmModal
  },
  mixins: [MobileMixin, GenericTableMixin]
})
export default class FundingCodeForm extends Mixins(LocaleMixin, ToastMixin) {
  @Ref() readonly selectableTable!: BvComponent
  @Prop() projectSlug!: string
  tableName = "site_admin_funding_code_table"
  searchString = ''
  filtersChanged = false
  fundingCodeFormModalOpen = false
  selectedFundingCode: IFundingCode = null
  deleteModalOpen = false
  pageSize = 25
  tableLoading = false
  saving = false
  infoModalOpen = false

  get breadcrumbs (): IBreadcrumb[] {
    return [
      {
        title: this.$gettext('Codes'),
        url: '#'
      }
    ]
  }

  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'title', label: this.$gettext('Funding program title') },
    { key: 'description', label: this.$gettext('Description'), formatter: (value: string) => value.substring(0, 30) },
    { key: 'code_string', label: 'Number' },
    { key: 'is_active', label: this.$gettext('Status') },
    { key: 'fixed_action_col', label: this.$gettext('Actions') }
  ]

  customFields = ["is_active"]

  get apiUrl () {
    return API_URLS.FUNDING_CODE.LIST_PROJECT_STEP(this.projectSlug)
  }

  get stepDescription (): string {
    return this.$gettext('Enter the eight-digit funding code to apply specifically for one or more calls for proposals. You will only receive the code from the body issuing the call.<br><br>If you do not have a funding code, you can skip this step.')
  }

  statusFormatter (value: boolean): string {
    let iconClass = "fa fa-close text-danger fa-lg"
    if (value) {
      iconClass = "far fa-check text-hds-highlight fa-lg"
    }
    return `<i class="${iconClass}"></i>`
  }

  modifyTableItemsFunc (tableItems): TGenericObject[] {
    tableItems.forEach(fundingCode => {
      if (fundingCode.form_link || fundingCode.form_link_desc) {
        fundingCode._showDetails = true
      }
    })
    return tableItems
  }

  setCodeString (codeString) {
    this.fundingCodeForm.codeString = codeString
  }

  onRowSelected (selectedItems: IFundingCode): void {
    this.selectedFundingCode = selectedItems
  }

  openInfoModal (fundingCode: IFundingCode): void {
    this.selectedFundingCode = fundingCode
    this.infoModalOpen = true
  }

  fundingCodeForm: IFundingCodeForm = {
    title: '',
    formLink: '',
    formLinkDesc: '',
    codeString: '',
    description: '',
    document_href: '',
    isActive: true
  }

  async onDocumentHref (documentHref: string, fundingCodeId: string): Promise<void> {
    const url = "/api/v4/projects/" + this.projectSlug + "/add-document-href-to-fundingscope/"
    const data = {
      document_href: documentHref,
      funding_code_id: fundingCodeId
    }
    await axios.post(url, data).then(() => {
      this.selectableTable.loadItems()
    }).catch(() => {
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to save document link'))
    })
  }

  reset () {
    this.fundingCodeForm.title = ''
    this.fundingCodeForm.codeString = ''
    this.fundingCodeForm.description = ''
    this.selectedFundingCode = null
    this.deleteModalOpen = false
    this.fundingCodeFormModalOpen = false
    this.$emit('close')
    this.selectableTable.loadItems()
  }

  openDeleteModal (fundingCode: IFundingCode): void {
    this.selectedFundingCode = fundingCode
    this.deleteModalOpen = true
  }

  async removeCodeFromProject (): Promise<void> {
    if (!this.selectedFundingCode) return
    const codeString = this.selectedFundingCode.code_string
    const projectSlug = this.projectSlug
    axios.post(API_URLS.PROJECTS.REMOVE_FUNDING_CODE(projectSlug), {
      code_string: codeString
    }).then(() => {
      this.makeToast(
        'success',
        this.$gettext('Success'),
        this.$gettextInterpolate(this.$gettext('Deleted code %{ codeString }'), { codeString })
      )
      this.deleteModalOpen = false
    }).catch(() => {
      this.makeToast(
        'danger',
        this.$gettext('Error'),
        this.$gettextInterpolate(this.$gettext('Failed to remove %{ codeString }'), { codeString })
      )
    }).then(() => {
      this.reset()
    })
  }

  async addNewCode (): Promise<void> {
    const title = this.fundingCodeForm.title
    const codeString = this.fundingCodeForm.codeString
    const description = this.fundingCodeForm.description
    const apiUrl = API_URLS.PROJECTS.ADD_FUNDING_CODE(this.projectSlug)
    axios.post(apiUrl, {
      title,
      code_string: codeString,
      description,
      is_active: true
    })
      .then(() => {
        this.makeToast(
          'success',
          this.$gettext('Success'),
          this.$gettextInterpolate(this.$gettext('Added code: %{ codeString }'), { codeString })
        )
      })
      .catch((error) => {
        const errors = Object.values(error.response.data).join("\n")
        this.makeToast(
          'danger',
          this.$gettext('Failed to create code '),
          this.$gettextInterpolate(this.$gettext('%{ errors }'), { errors })
        )
      }).then(() => {
        this.reset()
      })
  }
}
