import { render, staticRenderFns } from "./HdsCheckbox.vue?vue&type=template&id=35bb4f98&scoped=true&"
import script from "./HdsCheckbox.vue?vue&type=script&lang=ts&"
export * from "./HdsCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./HdsCheckbox.vue?vue&type=style&index=0&id=35bb4f98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bb4f98",
  null
  
)

export default component.exports