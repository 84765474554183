
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import { ISummaryItem } from '@/types/processingSteps'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    CompletitionLayout
  }
})
export default class FundingScopeIsPublicForm extends Vue {
  @Prop() isComplete!: boolean
  @Prop() summaryItems!: ISummaryItem[]
}
