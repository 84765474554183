
import debounce from 'lodash/debounce'
import { Component, Mixins } from 'vue-property-decorator'
import LocaleMixin from '@/mixins/LocaleMixin'
import ToastMixin from '@/mixins/ToastMixin'
import MobileMixin from '@/mixins/MobileMixin'
import UserMixin from '@/mixins/UserMixin'
import ExportModal from '@/components/modals/ExportModal.vue'
import DuplicateProjectModal from '@/components/modals/DuplicateProjectModal.vue'
import PageSizeSelect from '@/components/PageSizeSelect.vue'
import { IMinimalProject, IProject } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'
import { PlatformFeaturesModule } from '@/store/modules/platformFeatures'
import { BvTableFieldArrayWithStickColumn } from '@/types/base'
import GenericTable from "@/components/GenericTable.vue"
import axios from "axios"
import DeleteModal from "@/components/modals/DeleteModal.vue"
import InfoModal from "@/components/modals/InfoModal.vue"
import EditButton from '@/components/buttons/EditButton.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import DuplicateButton from '@/components/buttons/DuplicateButton.vue'
import PreviewButton from '@/components/buttons/PreviewButton.vue'
import ProjectLinkButton from '@/components/buttons/ProjectLinkButton.vue'
import CopyURLButton from '@/components/buttons/CopyURLButton.vue'
import QrCodeButton from '@/components/buttons/QrCodeButton.vue'
import DeleteButton from "@/components/buttons/DeleteButton.vue"
import OrgaHasNoFundingScopesBanner from '@/components/processing_steps/banners/OrgaHasNoFundingScopesBanner.vue'
import ConfirmModal from '@/components/processing_steps/commons/modals/ConfirmModal.vue'
import BreadcrumbHeader from '@/components/processing_steps/BreadcrumbHeader.vue'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import OrgaNotSubmittedBanner from "@/components/processing_steps/banners/OrgaNotSubmittedBanner.vue"
import StatusFormatterMixin from '@/mixins/StatusFormatterMixin'

@Component({
  components: {
    OrgaNotSubmittedBanner,
    DeleteButton,
    InfoModal,
    DeleteModal,
    GenericTable,
    PageSizeSelect,
    ExportModal,
    DuplicateProjectModal,
    EditButton,
    DuplicateButton,
    PreviewButton,
    ProjectLinkButton,
    CopyURLButton,
    QrCodeButton,
    OrgaHasNoFundingScopesBanner,
    ConfirmModal,
    BreadcrumbHeader,
    FormTitle,
    IconButton
  },
  mixins: [MobileMixin, UserMixin]
})
export default class AccountProjectList extends Mixins(ToastMixin, MobileMixin, UserMixin, LocaleMixin, StatusFormatterMixin) {
  $refs!: { selectableTable: GenericTable }
  pageSize = 25
  searchString = ''
  tableLoading = true
  infoModalOpen = false
  duplicateProjectModalOpen = false
  deleteModalOpen = false
  finishModalOpen = false
  pauseModalOpen = false
  resumeModalOpen = false
  sendMailModalOpen = false
  deleteErrorMessage = ''
  selectedProject: IProject = null
  selectedProjects: (IProject | IMinimalProject)[] = []
  createDisabled = false

  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'custom_id', label: this.$gettext('Custom ID'), sortable: true },
    { key: 'title', label: this.$gettext('Project name'), sortable: true, stickyColumn: false, tdClass: 'wrap' },
    { key: 'status', label: 'Status', sortable: false },
    { key: 'view_actions', label: this.$gettext('Project links') },
    { key: 'fixed_action_col', label: this.$gettext('Actions') }
  ]

  customFields = ["status", "view_actions"]

  breadcrumbs = [
    { title: this.$gettext("Needs"), url: "/account/projects-new/", icon: 'projectIcon' }
  ]

  get apiUrl (): string {
    return API_URLS.PROJECTS.ACCOUNT_LIST
  }

  get userIsActivated (): boolean {
    const userInfoDiv = document.querySelector('#user-info')
    return userInfoDiv && userInfoDiv.getAttribute('is_activated') === 'True'
  }

  get description (): string {
    return this.$gettext('Here you can create new projects for which you need specific financing or revise existing financing projects. For example, you can specify here how much money you need for a renovation, a new employee position, a new car, therapy, etc.')
  }

  // todo add type
  getShowButton(project, buttonType: 'preview' | 'edit' | 'pause' |'resume' |
    'widget' | 'qr' | 'email' | 'finish'
  ): boolean {
    if (buttonType === 'preview') {
      return !project.status.live
    } else if (buttonType === 'edit') {
      return !project.status.finished
    } else if (buttonType === 'pause') {
      return project.status.live
    } else if (buttonType === 'resume') {
      return project.status.paused
    } else if (buttonType === 'widget') {
      return project.status.live
    } else if (buttonType === 'qr') {
      return project.status.live
    } else if (buttonType === 'email') {
      return project.status.live
    } else if (buttonType === 'finish') {
      return !project.status.archived && !project.status.draft
    }
    return false
  }

  setCreatedDisabled (showInfoBanner: { is_status_draft: boolean, is_rejected: boolean, show_orga_not_submitted_banner: boolean, show_orga_has_no_funding_scopes_banner: boolean, show_organization_not_live_banner: boolean, }): void {
    this.createDisabled = showInfoBanner.show_orga_not_submitted_banner || showInfoBanner.show_orga_has_no_funding_scopes_banner
  }

  handleSearchChange (event) {
    this.searchString = event.target.value
  }

  openDeleteModal (project: IProject): void {
    this.selectedProject = project
    this.deleteModalOpen = true
    this.deleteErrorMessage = ''
  }

  openFinishProjectModal (project: IProject): void {
    this.selectedProject = project
    this.finishModalOpen = true
  }

  openPauseProjectModal (project: IProject): void {
    this.selectedProject = project
    this.pauseModalOpen = true
  }

  openResumeProjectModal (project: IProject): void {
    this.selectedProject = project
    this.resumeModalOpen = true
  }

  openSendMailModal (project: IProject): void {
    this.selectedProject = project
    this.sendMailModalOpen = true
  }

  openDuplicateProjectModal (project?: IProject): void {
    project ? this.selectedProject = project : this.selectedProject = null
    this.duplicateProjectModalOpen = true
  }

  get apiUrlExtraParams () {
    return {
      own: 'true'
    }
  }

  urlPrefix () {
    if (window.location.href.includes("site-admin")) {
      return "/site-admin/"
    } else {
      return "/account/"
    }
  }

  hrefEditContactPerson (data: { item: { slug: string } }) {
    return this.urlPrefix() + "project/" + data.item.slug + "/update/"
  }

  getProjectLink (projectSlug: string) {
    return window.location.origin + "/project/" + projectSlug + "/"
  }

  async copyWidgetUrlToClipboard (slug: string) {
    await navigator.clipboard.writeText(window.location.origin + "/project/" + slug + "/widget/")
    this.makeToast(
      'success',
      this.$gettext('Success'),
      this.$gettext('Successfully copied widget url to clipboard.')
    )
  }

  async confirmDelete (): Promise<void> {
    this.deleteErrorMessage = ''
    if (!this.selectedProject) return
    const { title, slug } = this.selectedProject
    axios.delete(API_URLS.PROJECTS.RETRIEVE(slug) + '?own')
      .then(() => {
        this.deleteModalOpen = false
        this.makeToast(
          'success',
          this.$gettext('Success'),
          this.$gettextInterpolate(this.$gettext('Deleted project %{ title }'), { title })
        )
        this.$refs.selectableTable.loadItems()
      })
      .catch(res => {
        if (res.response.data && res.response.data.detail) {
          this.deleteErrorMessage = res.response.data.detail
        }
        this.makeToast(
          'danger',
          this.$gettext('Error'),
          this.$gettext('The project cannot be deleted.')
        )
      })
  }

  async created (): Promise<void> {
    PlatformFeaturesModule.fetchPlatformFeatures()
    this.handleSearchChange = debounce(this.handleSearchChange, 500)
    this.fetchUserProfile()
  }

  async duplicateProject (): Promise<void> {
    if (!this.selectedProjects && !this.selectedProject) return
    const slugList = [this.selectedProject.slug]
    await axios.post(API_URLS.PROJECTS.DUPLICATE, {
      project_slugs: slugList
    }).then(() => {
      this.makeToast(
        'success',
        this.$gettext('Success'),
        this.$gettext('Successfully duplicated')
      )
      this.$emit('close')
      this.$emit('success')
    }).catch(error => {
      this.makeToast(
        'danger',
        this.$gettext('Defunding failed!'),
        ''
      )
      throw Error(error.response)
    })
    this.duplicateProjectModalOpen = false
    this.$refs.selectableTable.loadItems()
  }

  async sendProjectViaEmail (): Promise<void> {
    if (!this.selectedProject) return
    await axios.post(API_URLS.PROJECTS.SEND_PROJECT_VIA_EMAIL(this.selectedProject.slug))
      .then(() => {
        this.makeToast(
          'success',
          this.$gettext('Success'),
          this.$gettext('We have sent you an email with your project.')
        )
        this.sendMailModalOpen = false
      })
      .catch(() => {
        this.makeToast(
          'danger',
          this.$gettext('Error'),
          this.$gettext('The project cannot be sent via email.')
        )
        this.sendMailModalOpen = false
      })
    this.selectedProject = null
  }

  async changeProjectStatus (queryParam: 'finish' | 'pause'| 'resume'): Promise<void> {
    this.finishModalOpen = false
    this.pauseModalOpen = false
    this.resumeModalOpen = false
    if (!this.selectedProject) return
    const url = `/api/v3/projects/${this.selectedProject.slug}/?${queryParam}&own`
    await axios.put(url)
      .then(() => {
        this.makeToast(
          'success',
          this.$gettext('Success'),
          this.$gettext('The project has been finished.')
        )
      })
      .catch(() => {
        this.makeToast(
          'danger',
          this.$gettext('Error'),
          this.$gettext('The project cannot be finished.')
        )
      })
    this.selectedProject = null
    this.$refs.selectableTable.loadItems()
  }
}
