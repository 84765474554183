
import { ITab } from '@/types/detailPages'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ImageSlider from '@/components/explore/ImageSlider.vue'
import ContactPerson from '@/components/explore/ContactPerson.vue'
import SimpleSocialMediaArea from '@/components/detail_pages/SimpleSocialMediaArea.vue'
import MapComponent from './MapComponent.vue'
import { ILocation } from '@/types/projects'

@Component({
  name: 'TabContainer',
  components: {
    ImageSlider,
    ContactPerson,
    SimpleSocialMediaArea,
    MapComponent
  }
})
export default class TabContainer extends Vue {
  @Prop({ default: true }) showTabs!: boolean
  @Prop({ default: false }) showMap!: boolean
  @Prop() tabs!: ITab[]
  @Prop({ required: false }) projectLocations!: ILocation[]

  expanded = false

  setActiveTab (key: string): void {
    this.expanded = false
    this.tabs.forEach(tab => {
      tab.is_active = tab.key === key
    })
  }

  getTrimmedHtmlBefore (htmlText: string, maxlength: number): string {
    const div = document.createElement('div')
    div.innerHTML = htmlText

    let truncated = ''
    let length = 0

    function traverseNodes(node: Node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const words = node.textContent.split(' ')
        for (const word of words) {
          if (length + word.length + 1 > maxlength) {
            truncated += '...'
            length = maxlength
            break
          } else {
            truncated += (truncated ? ' ' : '') + word
            length += word.length + 1
          }
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        truncated += `<${(node as Element).tagName.toLowerCase()}>`

        Array.from(node.childNodes).forEach(childNode => {
          if (length < maxlength) {
            traverseNodes(childNode)
          }
        })

        truncated += `</${(node as Element).tagName.toLowerCase()}>`
      }
    }

    Array.from(div.childNodes).forEach(childNode => {
      if (length < maxlength) {
        traverseNodes(childNode)
      }
    })

    return truncated
  }

  getTabByKey (key: string): ITab {
    return this.tabs.find(tab => tab.key === key) as ITab
  }

  getTabBackgroundColor (tabKey) {
    const tab = this.getTabByKey(tabKey)
    if (tab.is_active) {
      return {
        "background-color": tab.color + " !important"
      }
    }
  }

  getTabColor (tabKey) {
    const tab = this.getTabByKey(tabKey)
    if (tab.is_active) {
      return {
        color: tab.color + " !important"
      }
    }
  }
}
