
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ProjectSummary from '@/components/processing_steps/project/forms/completition_forms/ProjectSummary.vue'
import IconAndText from '@/components/processing_steps/banners/IconAndText.vue'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    ProjectSummary,
    IconAndText,
    CompletitionLayout
  }
})
export default class AccountPublishProjectForm extends Vue {
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[]}[]}[]
  @Prop() termsAccepted!: boolean
  @Prop() projectDetailUrl!: string
  @Prop() isComplete!: boolean
  @Prop() termsAndPrivacyHtml!: string

  get statusExplanation (): string {
    if (this.isComplete) {
      return this.$gettext('This funding requirement is complete. You can publish it.')
    } else {
      return this.$gettext('The data on this funding requirement is incomplete.')
    }
  }

  gotoProjectDetailPage (): void {
    window.open(this.projectDetailUrl, '_blank')
  }
}
