

import { IProcessingStepButtons } from '@/types/processingSteps'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormButtons extends Vue {
  @Prop({ default: true }) showPreviousBtn!: boolean
  @Prop({ default: true }) showNextBtn!: boolean
  @Prop({ default: true }) showSaveAndNextBtn!: boolean
  @Prop({ default: true }) showSaveBtn!: boolean
  @Prop() buttons!: IProcessingStepButtons

  showFireworks = false

  previousStep (): void {
    this.$emit('previous')
  }

  nextStep (): void {
    this.$emit('next')
  }

  save (): void {
    this.showFireworks = true
    setTimeout(() => {
      this.showFireworks = false
    }, 5000)
    this.$emit('save')
  }

  saveAndNext (): void {
    this.$emit('save-and-next')
  }

  finish (): void {
    this.$emit('finish')
  }
}
