
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import FieldValidationMixin from "@/components/processing_steps/FieldValidationMixin.vue"

@Component
export default class CurrencyField extends FieldValidationMixin {
  @Prop({ default: '' }) error!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: '' }) maxValue!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: false }) isDisabled!: boolean
  @Prop({ default: null }) label!: string

  displayError = ''
  state: boolean | null = null

  @Watch('error')
  onErrorChange () {
    this.displayError = this.error
  }

  @Emit('input')
  updateValue(event: InputEvent) {
    const newValue = (event.target as HTMLInputElement).value
    if (isNaN(Number(newValue))) {
      this.state = false
      this.displayError = this.$gettext('Please enter a number!')
    } else if (this.maxValue && newValue > this.maxValue) {
      this.state = false
      this.displayError = this.$gettext('Please choose a lower number!')
    } else if (!this.requiredFieldIsEmpty(newValue, this.required) && newValue === '') {
      this.state = false
      this.displayError = this.$gettext('This field is required')
    }
    return newValue
  }
}
