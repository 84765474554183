
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import { ISummaryItem } from '@/types/processingSteps'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CompletitionLayout
  }
})
export default class AccountProjectIsPaused extends Vue {
  @Prop() projectSlug!: string
  @Prop() summaryItems!: ISummaryItem[]

  get projectDetailUrl (): string {
    return "/project/" + this.projectSlug + "/"
  }

  resumeProject (): void {
    this.$emit('resume-project')
  }

  endFundingRequirement (): void {
    this.$emit('pause-funding-requirement')
  }
}
