
import { IResultReportForm } from '@/types/foerderApp'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ResultReportMixin extends Vue {
  resultReportSlug = ''

  resultReportForm: IResultReportForm = {
    title: '',
    text: '',
    links: [],
    is_public: false
  }

  onResultReportFormUpdated (form: IResultReportForm): void {
    this.resultReportForm = form
  }

  onResultReportFetched (resultReportSlug: string): void {
    this.resultReportSlug = resultReportSlug
  }
}
