
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import { ISummaryItem } from '@/types/processingSteps'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    CompletitionLayout
  }
})
export default class AccountPublishFundingScopeForm extends Vue {
  @Prop() termsAccepted!: boolean
  @Prop() isDraft!: boolean
  @Prop() isComplete!: boolean
  @Prop() summaryItems!: ISummaryItem[]
  @Prop() termsAndPrivacyHtml!: string

  get isCompleteButNotSubmitted (): boolean {
    return this.isComplete && this.isDraft
  }

  get statusExplanation (): string {
    if (this.isComplete) {
      return this.$gettext('The data for this funding scope is complete. You can now publish it.')
    } else {
      return this.$gettext('The data for this funding area is incomplete.')
    }
  }
}
