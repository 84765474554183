

import { Mixins, Component, Prop } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import { API_URLS } from '@/utils/helpers'
import { IPhoto } from '@/types/photos'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import DocumentField from '../commons/fields/DocumentField.vue'
import { IPSEditContactPerson } from '@/types/processingSteps'

@Component({
  components: {
    FormTitle,
    FormContainer,
    DocumentField
  },
  mixins: [UserMixin]
})
export default class ContactPersonPhoto extends Mixins(UserMixin) {
  @Prop() contactPerson: IPSEditContactPerson
  @Prop() existingPhotos!: IPhoto[]
  @Prop({ default: false }) useCropper!: boolean
  @Prop({ default: false }) isStencilCircle!: boolean
  intervalId = null

  get logoUploadUrl () {
    if (!this.contactPerson) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.contactPerson.photo_album_slug)
  }

  handlePhotoUpdated (): void {
    this.$emit('files-updated')
  }

  onFileInputClicked (): void {
    this.$emit('file-input-clicked')
  }
}
