
import axios from 'axios'
import { Component, Mixins } from 'vue-property-decorator'
import ProcessingStepMixin from '../../ProcessingStepMixin.vue'
import { IPSAdminCompletionForm, IPSEditProject } from '@/types/processingSteps'
import BaseInformationValidationMixin from './BaseInformationValidationMixin.vue'

@Component({
  components: {
    ProcessingStepMixin
  }
})
export default class ProjectBaseInformationMixin extends Mixins(ProcessingStepMixin, BaseInformationValidationMixin) {
  // todo add or use a type for this
  availableOrganizations: { text: string, value: string }[] = []

  availableProjectCategories: { text: string, value: string }[] = []

  projectBaseInformationForm = {
    title: "",
    category: "",
    organization: {}
  }

  projectBaseInformationFormErrors = {
    title: "",
    category: "",
    organization: {}
  }

  projectLocationFormErrors = {
    street: "",
    postal_code: "",
    city: "",
    country: "",
    additional_information: ""
  }

  fundingScopeFormErrors = {
    funding_scope_slug: ""
  }

  adminCompletitionForm: IPSAdminCompletionForm = {
    content_rejected_message: "",
    content_review_status: "",
    in_default_list: false,
    internal_status: "",
    is_banned: false,
    review_rejected_message: "",
    review_status: "",
    status: 0,
    voting_review_rejected_message: "",
    voting_review_status: ""
  }

  get baseInformationFromIsValid () {
    if (!(this.financeForm.goal_in_cents > 0)) {
      this.financeFormErrors.goal_in_cents = this.$gettext('Please enter a goal amount higher than 0')
      return false
    }
    const requiredFields = ["title", "category", "organization",
      "street", "postal_code", "city", "country", "goal_in_cents"
    ]
    const data = {
      ...this.projectBaseInformationForm,
      ...this.projectLocationForm,
      ...this.financeForm
    }
    const hasFundingScope = !!this.selectedFundingScopesSlug && this.selectedFundingScopesSlug !== "undefined"
    return requiredFields.every(field => data[field]) && hasFundingScope
  }

  setAvailableOrganizations (availableOrganizations): void {
    this.availableOrganizations = availableOrganizations
  }

  setAvailableProjectCategories (availableProjectCategories): void {
    this.availableProjectCategories = availableProjectCategories
  }

  setProjectBaseInformationForm (project: IPSEditProject) {
    this.projectBaseInformationForm.title = project ? project.title : ""
    this.projectBaseInformationForm.category = project ? project.category : ""
    this.projectBaseInformationForm.organization = project && project.organization ? project.organization.slug : this.availableOrganizations.length > 0 ? this.availableOrganizations[0].value : ""
  }

  setProjectFinanceForm (project: IPSEditProject) {
    this.financeForm.goal_in_cents = project ? project.goal?.in_currency : null
  }

  setProjectCompletionForm (project: IPSEditProject) {
    this.adminCompletitionForm.content_rejected_message = project ? project.content_rejected_message : ""
    this.adminCompletitionForm.content_review_status = project ? project.content_review_status : ""
    this.adminCompletitionForm.in_default_list = project ? project.in_default_list : false
    this.adminCompletitionForm.internal_status = project ? project.internal_status : ""
    this.adminCompletitionForm.is_banned = project ? project.is_banned : false
    this.adminCompletitionForm.review_rejected_message = project ? project.review_rejected_message : ""
    this.adminCompletitionForm.review_status = project ? project.review_status : ""
    this.adminCompletitionForm.status = project ? project.status : 1
    this.adminCompletitionForm.voting_review_rejected_message = project ? project.voting_review_rejected_message : ""
    this.adminCompletitionForm.voting_review_status = project ? project.voting_review_status : ""
  }

  setProjectLocationForm (project: IPSEditProject) {
    this.projectLocationForm.street = project && project.street !== "" ? project.street : ""
    this.projectLocationForm.postal_code = project && project.postal_code !== "" ? project.postal_code : ""
    this.projectLocationForm.city = project && project.city !== "" ? project.city : ""
    this.projectLocationForm.country = project && project.country !== "" ? project.country : "DE"
    this.projectLocationForm.additional_information = project ? project.additional_information : ""
    this.projectLocationForm.longitude = project ? project.longitude : 0
    this.projectLocationForm.latitude = project ? project.latitude : 0
  }

  async saveBaseInformation(projectSlug: string): Promise<boolean> {
    let url = ""
    let saved = false
    const postData = {
      projectpromoter: this.projectBaseInformationForm.organization,
      title: this.projectBaseInformationForm.title,
      category: this.projectBaseInformationForm.category,
      ...this.projectLocationForm
    }

    const update = projectSlug !== ""

    if (update) {
      url = "/api/v3/projects/" + projectSlug + "/?own"
      if (this.isSiteAdmin) {
        url = "/api/v3/projects/" + projectSlug + "/"
      }
      await axios.put(url, postData).then(() => {
        saved = true
        const successData = {
          message: this.$gettext('Project base information saved successfully!'),
          title: this.$gettext('Success!')
        }
        this.showSuccessMsg(successData)
      }).catch(error => {
        this.setFormErrors(error.response.data)
      })
      return saved
    }
  }

  async createProject (): Promise<boolean | string> {
    let created = false
    const postData = {
      projectpromoter: this.projectBaseInformationForm.organization,
      title: this.projectBaseInformationForm.title,
      category: this.projectBaseInformationForm.category,
      ...this.projectLocationForm
    }
    let url = "/api/v3/projects/?own"
    if (this.isSiteAdmin) {
      url = "/api/v3/projects/"
    }
    created = await axios.post(url, postData).then(response => {
      return response.data.slug
    }).catch(error => {
      this.setFormErrors(error.response.data)
      return false
    })
    return created
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormErrors(data: any) {
    for (const field in this.projectLocationFormErrors) {
      if (Object.prototype.hasOwnProperty.call(data, field)) {
        if (Array.isArray(data[field]) && data[field].length > 0) {
          this.projectLocationFormErrors[field] = data[field][0]
        } else {
          this.projectLocationFormErrors[field] = data[field]
        }
      }
    }

    for (const field in this.projectBaseInformationFormErrors) {
      if (Object.prototype.hasOwnProperty.call(data, field)) {
        if (Array.isArray(data[field]) && data[field].length > 0) {
          this.projectBaseInformationFormErrors[field] = data[field][0]
        } else {
          this.projectBaseInformationFormErrors[field] = data[field]
        }
      }
    }
    const errorData = {
      message: this.$gettext('Please correct the errors in the form'),
      title: this.$gettext('Error!')
    }
    this.showError(errorData)
  }
}
