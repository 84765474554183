
import { Component, Vue, Prop } from 'vue-property-decorator'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'

@Component({
  components: {
    CompletitionLayout
  }
})
export default class AccountOrgaWaitingForReview extends Vue {
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[] }[]}[]

  get fundingScopeCreateUrl (): string {
    return "/account/funding-scopes/create/"
  }
}
