import { render, staticRenderFns } from "./AddressForm.vue?vue&type=template&id=13d3b9cc&"
import script from "./AddressForm.vue?vue&type=script&lang=ts&"
export * from "./AddressForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports