
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import CodeStringInput from '@/components/funding_code/shared/CodeStringInput.vue'
import GenericTable from '@/components/GenericTable.vue'
import { BvTableFieldArrayWithStickColumn } from '@/types/base'
import { BvTableCtxObject } from "bootstrap-vue"
import DeleteButton from '@/components/buttons/DeleteButton.vue'
import { IShowcasePage } from '@/types/showcase'
import { API_URLS } from '@/utils/helpers'
import CopyURLButton from '@/components/buttons/CopyURLButton.vue'
@Component({
  components: {
    CodeStringInput,
    GenericTable,
    DeleteButton,
    CopyURLButton
  }
})
export default class DisplayedProjectsOverview extends Mixins(UserMixin) {
  @Prop({ required: false }) showcasePage: IShowcasePage
  @Prop({ required: false }) refresh
  $refs!: { projectTable: GenericTable }
  pageSize = 10
  tableLoading = true
  tableContext: BvTableCtxObject | null = null
  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'title', label: this.$gettext('Title'), sortable: true },
    { key: 'organization', label: this.$gettext('Organization') },
    { key: 'funding_codes', label: this.$gettext('Funding codes'), formatter: this.fundingCodeFormatter },
    { key: 'fixed_action_col', label: this.$gettext('Actions') }
  ]

  @Watch('refresh')
  onRefreshChanged () {
    this.$refs.projectTable.loadItems()
  }

  get apiUrl () {
    return API_URLS.SHOWCASE_PAGE.PROJECTS(this.showcasePage.slug)
  }

  fundingCodeFormatter (fundingCodes) {
    return fundingCodes.map(obj => obj.code_string).join(', ')
  }

  sortingChanged (ctx: BvTableCtxObject): void {
    this.tableContext = ctx
  }

  getAffiliateLink (data): string {
    return window.location.origin + '/project/' + data.item.slug + '/?showcase=' + this.showcasePage.slug
  }
}
