
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import HdsCheckbox from '../processing_steps/commons/fields/HdsCheckbox.vue'

interface Project {
  title: string
  slug: string
  selected: boolean
}

@Component({
  components: {
    HdsCheckbox
  }
})
export default class FilteredProjectTable extends Vue {
  @Prop({ required: true }) projects!: Project[]
  @Prop({ default: false }) isGrowingFilter!: boolean

  selectMode = false
  allSelected = false
  selectAllText = ""
  selectedProjectsCount = 0

  fields = [
    {
      key: 'title',
      label: this.$gettext('Project selection'),
      thStyle: { width: '75%' },
      sortable: true
    },
    {
      key: 'selectedProjectsCount',
      label: this.$gettext('Individual selection'),
      thStyle: { width: '25%' }
    }
  ]

  @Watch('isGrowingFilter')
  onIsGrowingFilterChanged(newValue: boolean) {
    if (newValue === true) {
      this.projects.forEach(project => {
        project.selected = true
      })
      this.selectMode = true
      this.updateSelection()
      this.selectMode = false
    }
  }

  mounted() {
    this.setSelectedProjectsCount()
    this.setAllSelected(this.projects.filter(project => project.selected).length === this.projects.length)
  }

  setAllSelected (allSelected: boolean): void {
    if (allSelected) {
      this.selectAllText = this.$gettext('Unselect all')
      this.allSelected = true
    } else {
      this.selectAllText = this.$gettext('Select all')
      this.allSelected = false
    }
  }

  toggleSelectAll (newVal: boolean) {
    if (newVal === false) {
      this.projects.forEach(project => {
        project.selected = false
      })
      this.setAllSelected(false)
    } else {
      this.projects.forEach(project => {
        project.selected = true
      })
      this.setAllSelected(true)
    }
  }

  setSelectedProjectsCount () {
    this.selectedProjectsCount = this.projects.filter(project => project.selected).length
  }

  get displayedProjects() {
    return this.selectMode ? this.projects : this.projects.filter(project => project.selected)
  }

  toggleSelection() {
    this.selectMode = !this.selectMode
  }

  updateSelection() {
    this.$emit('update:projects', this.projects)
    this.setSelectedProjectsCount()
  }

  @Watch('projects', { deep: true })
  onProjectsChanged() {
    this.setSelectedProjectsCount()
    this.$emit('update:projects', this.projects)
  }
}
