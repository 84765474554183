
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IContactPerson } from '@/types/foerderApp'
import ContactPerson from '@/components/explore/ContactPerson.vue'
import axios from 'axios'
import SimpleSocialMediaArea from "@/components/detail_pages/SimpleSocialMediaArea.vue"
@Component({
  components: {
    SimpleSocialMediaArea,
    ContactPerson
  }
})
export default class ContactPersonPreview extends Vue {
  @Prop({ required: true }) contactPersonSlug: string

  contactPerson: IContactPerson | null = null

  get logo(): string {
    return this.contactPerson.related_organization.logos.length ? this.contactPerson.related_organization.logos[0].src : ''
  }

  mounted () {
    this.fetchContactPerson()
  }

  @Watch('contactPersonSlug')
  onContactPersonSlugChange() {
    this.fetchContactPerson()
  }

  async fetchContactPerson (): Promise<void> {
    if (!this.contactPersonSlug) {
      return
    }
    const url = `/api/v4/contactpersons/${this.contactPersonSlug}/`
    await axios.get(url).then((response) => {
      const data = response.data
      const photos = data.photo ? data.photo : []
      this.contactPerson = {
        slug: data.slug,
        term_of_address: data.term_of_address,
        academic_title: data.academic_title,
        job_title: data.job_title,
        first_name: data.first_name,
        last_name: data.last_name,
        mobile: data.mobile,
        phone: data.phone,
        fax: data.fax,
        email: data.email,
        vita: data.vita,
        street: data.street,
        additional_information: data.additional_information,
        addressable_name: data.addressable_name,
        postal_code: data.postal_code,
        city: data.city,
        country: data.country,
        public_contact_info: data.public_contact_info,
        photos,
        related_organization: data.related_organization
      }
    })
  }
}
