
import { Component, Vue, Prop } from 'vue-property-decorator'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
@Component({
  components: {
    FormTitle
  }
})
export default class FormContainer extends Vue {
  @Prop({ default: "" }) title!: string
  @Prop({ default: "" }) description!: string
  @Prop({ default: false }) noForm!: boolean
  @Prop({ default: false }) isStepTitle!: boolean
  @Prop({ default: null }) iconClass!: string | null
}
