
import { Component, Mixins, Ref } from 'vue-property-decorator'
import StepContainer from '../commons/StepContainer.vue'
import OrganisationStepMixin from './mixins/OrganizationStepMixin.vue'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import ProcessingStepMixin from '../ProcessingStepMixin.vue'
import BaseInformationForm from './forms/BaseInformationForm.vue'
import ContactPersonSelect from '../commons/forms/ContactPersonSelect.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import PSBankAccountForm from '../commons/forms/PSBankAccountForm.vue'
import OrganisationDescriptionForm from './forms/OrganisationDescriptionForm.vue'
import ColorInput from './forms/ColorInput.vue'
import DocumentField from "@/components/processing_steps/commons/fields/DocumentField.vue"
import { IPhoto } from "@/types/photos"
import ProofForm from "@/components/processing_steps/organization/forms/ProofForm.vue"
import EditAdditionalStep from "@/components/processing_steps/additional_steps/EditAdditionalStep.vue"
import ProjectAdditionalStepMixin from "@/components/processing_steps/project/mixins/ProjectAdditionalStepMixin.vue"
import AccountPublishOrgaForm from './forms/completition/AccountPublishOrgaForm.vue'
import { IPSAddtitionalProcessingStep, IPSAdminEditOrganization, IPSAdminOrgaCompletionForm, IPSEditOrganization, IPSProofStepPostData, IPSSelectOption, IProcessingStep } from '@/types/processingSteps'
import AdminOrgaCompletitionForm from './forms/completition/AdminOrgaCompletitionForm.vue'
import AccountOrgaIsLiveForm from './forms/completition/AccountOrgaIsLiveForm.vue'
import AccountOrgaWaitingForReview from './forms/completition/AccountOrgaWaitingForReview.vue'
import AdminSelectForm from "@/components/processing_steps/commons/forms/AdminSelectForm.vue"
import AddSubCategory from "@/components/sub_categories/AddSubCategory.vue"
import WhatWeNeedStepMixin from '@/components/processing_steps/project/mixins/WhatWeNeedStepMixin.vue'
import WhatWeNeed from '@/components/processing_steps/project/forms/WhatWeNeed.vue'
import OrganisationSummaryMixin from './mixins/OrganizationSummaryMixin.vue'
import moment from 'moment'
@Component({
  components: {
    AddSubCategory,
    AdminSelectForm,
    EditAdditionalStep,
    ProofForm,
    DocumentField,
    StepContainer,
    BaseInformationForm,
    ContactPersonSelect,
    FormContainer,
    PSBankAccountForm,
    OrganisationDescriptionForm,
    ColorInput,
    AccountPublishOrgaForm,
    AdminOrgaCompletitionForm,
    AccountOrgaIsLiveForm,
    AccountOrgaWaitingForReview,
    WhatWeNeed
  }
})
export default class EditOrganization extends Mixins(OrganisationStepMixin, WhatWeNeedStepMixin, ProcessingStepMixin, ProjectAdditionalStepMixin, OrganisationSummaryMixin) {
  @Ref() contactPersonSelect!: ContactPersonSelect
  organization: IPSEditOrganization | IPSAdminEditOrganization | null = null
  existingLogos: IPhoto[] = []
  existingPhotos: IPhoto[] = []
  existingDocuments: IPhoto[] = []
  existingProofOfAccountDocuments: IPhoto[] = []
  existingNoticeOfExemptionDocuments: IPhoto[] = []
  selectedStatutoryPurposes: string[] = []
  selectedAdmins: string[] = []
  organizationSlug: string | null = null

  setOrganizationSlug (): void {
    const currentUrl = window.location.pathname
    if (currentUrl.endsWith("update/")) {
      this.organizationSlug = currentUrl.split("organization/")[1].split("/update/")[0]
    } else {
      this.organizationSlug = null
    }
  }

  get organizationIsDraft (): boolean {
    return this.organization ? this.organization.status.choice === 1 : false
  }

  get documentUploadUrl (): string {
    if (!this.organization) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.organization.what_we_need.document_album_slug)
  }

  get postUrl (): string {
    let url = "/api/v3/organizations/"
    if (this.organizationSlug) {
      url = "/api/v3/organizations/" + this.organizationSlug + "/"
    }
    if (!this.isSiteAdmin) {
      url += "?own"
    }
    return url
  }

  get contactPersonUrl(): string {
    return API_URLS.CONTACT_PERSON.LIST
  }

  get addContactPersonToInstanceUrl(): string {
    return API_URLS.CONTACT_PERSON.ADD_TO_INSTANCE
  }

  get organizationIsLive (): boolean {
    const value = this.organization ? this.organization.review_status.choice === 2 : false
    return value
  }

  get userCanEditBaseInformation (): boolean {
    if (!this.organization) return true
    if (this.organizationIsDraft) return true
    if (this.isSiteAdmin) return true
    if (this.organization.platform_settings.promoter_base_information_edit) return true
    return false
  }

  get userCanEditBankInformation (): boolean {
    if (!this.organization) return true
    if (this.organizationIsDraft) return true
    if (this.isSiteAdmin) return true
    if (this.organization.platform_settings.promoter_bank_information_edit) return true
  }

  get userCanEditDescription (): boolean {
    if (!this.organization) return true
    if (this.organizationIsDraft) return true
    if (this.isSiteAdmin) return true
    if (this.organization.platform_settings.promoter_description_edit) return true
  }

  get requiredFieldsComplete (): boolean {
    const requiredStepsKeys = this.steps.filter(step => step.required).map(step => step.key)
    for (const key of requiredStepsKeys) {
      const step = this.steps.find(step => step.key === key)
      if (!step || !step.completed) {
        if (step.key !== "completition") {
          return false
        }
      }
    }
    return true
  }

  get organizationDataIsSubmitted (): boolean {
    if (!this.organization) return false
    if (!this.organizationIsDraft) return true
    return false
  }

  get proofFormIsEditable (): boolean {
    if (!this.organization) return true
    if (this.organizationIsDraft) return true
    if (this.isSiteAdmin) return true
    if (this.organization.platform_settings.promoter_notice_of_exemption_edit) return true
    return false
  }

  baseInformationForm = {
    title: "",
    email: "",
    street: "",
    additional_information: "",
    postal_code: "",
    city: "",
    country: "DE",
    phone: "",
    fax: "",
    website: ""
  }

  baseInformationFormErrors = {
    title: "",
    email: "",
    street: "",
    additional_information: "",
    postal_code: "",
    city: "",
    country: "",
    phone: "",
    fax: "",
    website: ""
  }

  bankAccountForm = {
    owner: "",
    bank_name: "",
    iban: "",
    bic: ""
  }

  bankAccountFormErrors = {
    owner: "",
    bank_name: "",
    iban: "",
    bic: ""
  }

  proofForm = {
    notice_of_exemption_expiration_date: "",
    finance_authority: "",
    issuing_date: "",
    tax_no: "",
    assessment_period: "",
    notice_of_exemption_type: "",
    available_notice_of_exemption_types: [],
    options: [],
    available_legal_entity_choices: [],
    different_legal_entity_choice: "",
    different_legal_entity_title: "",
    different_legal_entity_street: "",
    different_legal_entity_postal_code: "",
    different_legal_entity_city: "",
    different_legal_entity_name: "",
    different_legal_entity_phone: "",
    different_legal_entity_email: "",
    available_organization_types: [],
    organization_type: ""
  }

  proofFormErrors = {
    notice_of_exemption_expiration_date: "",
    finance_authority: "",
    issuing_date: "",
    tax_no: "",
    assessment_period: "",
    notice_of_exemption_type: "",
    different_legal_entity_choice: "",
    different_legal_entity_title: "",
    different_legal_entity_street: "",
    different_legal_entity_postal_code: "",
    different_legal_entity_city: "",
    different_legal_entity_name: "",
    different_legal_entity_phone: "",
    different_legal_entity_email: ""
  }

  adminCompletitionForm: IPSAdminOrgaCompletionForm = {
    content_review_status: 0,
    nonprofit_review_status: 0,
    internal_status: 0,
    status: 0,
    review_status: 0
  }

  accountPublishOrgaTermsAccepted = false

  handleFilesUploaded (): void {
    this.fetchOrganization()
  }

  async mounted (): Promise<void> {
    this.setOrganizationSlug()
    await this.fetchOrganization()
    await this.setAllData()
  }

  async onContactPersonUpdated (): Promise<void> {
    await this.fetchOrganization()
    this.checkAndValidateStep(this.activeStep)
  }

  async setAllData (): Promise<void> {
    this.setSteps()
    this.setActiveStep(null)
    if (this.organization) {
      this.setBaseInformationForm()
      this.setProofForm()
      this.setBankForm()
      this.setWhatWeNeedForm(this.organization)
      const selectedAdmins = []
      for (let i = 0; i < this.organization.admins.length; i++) {
        selectedAdmins.push(this.organization.admins[i].username)
      }
      this.selectedAdmins = selectedAdmins
      if (this.isSiteAdmin) {
        if ("available_nonprofit_review_status" in this.organization) {
          this.setAdminCompletitionForm(this.organization)
        }
      }
      this.updateBreadCrumbs()
      this.checkAndValidateSteps()
    }
  }

  checkAndValidateSteps (steps: (IProcessingStep | IPSAddtitionalProcessingStep)[] = this.steps) {
    steps.forEach(step => {
      this.checkAndValidateStep(step)
    })
  }

  onIbanNotFound (): void {
    this.showError({
      title: this.$gettext("Error"),
      message: this.$gettext("IBAN not found")
    })
  }

  async publicContactPersonChanged (): Promise<void> {
    await this.fetchOrganization()
    this.checkAndValidateStep(this.activeStep)
  }

  async fetchOrganization() {
    if (!this.organizationSlug) return
    try {
      const response = await axios.get(API_URLS.ORGANIZATIONS.EDIT_DATA(this.organizationSlug))
      this.organization = response.data
      this.existingPhotos = this.organization.photos
      this.existingLogos = this.organization.logos
      if (this.organization && this.organization.what_we_need) {
        this.existingDocuments = this.organization.what_we_need.documents
      }
      this.existingProofOfAccountDocuments = this.organization.proof_of_account_files
      this.existingNoticeOfExemptionDocuments = this.organization.notice_of_exemption_files.concat(this.organization.notice_of_exemption_photos)
      this.additionalStepsAfterFirstStep = this.createAdditonalSteps(this.organization.additional_steps_base_information)
    } catch {
      this.showError(null)
    }
  }

  get logoUploadUrl (): string {
    return this.organization ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.organization.logo_album_slug) : null
  }

  get photoUploadUrl (): string {
    return this.organization ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.organization.photo_album_slug) : null
  }

  get proofOfAccountFileUploadUrl (): string {
    return this.organization ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.organization.proof_of_account_album_slug) : null
  }

  get noticeOfExemptionUploadUrl (): string {
    return this.organization ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.organization.notice_of_exemption_album_slug) : null
  }

  get available_content_review_status (): IPSSelectOption[] {
    return this.organization ? (this.organization as IPSAdminEditOrganization).available_content_review_status : []
  }

  async updateColor (newValue: string) {
    const url = API_URLS.ORGANIZATIONS.RETRIEVE(this.organizationSlug) + "update-color/"
    if (!newValue || newValue === "#") {
      newValue = "#7D9B38"
    }
    const data = {
      color: newValue
    }
    axios.post(url, data).catch(() => {
      const oldColor = this.organization.color
      this.organization.color = ''
      this.$nextTick(() => {
        this.organization.color = oldColor
      })
      this.showError({
        title: this.$gettext("Error"),
        message: this.$gettext("Please chose a valid hex color")
      })
    })
  }

  setBaseInformationForm () {
    if (!this.organization) return
    this.baseInformationForm = {
      title: this.organization.title,
      email: this.organization.email,
      street: this.organization.street,
      additional_information: this.organization.additional_information,
      postal_code: this.organization.postal_code,
      city: this.organization.city,
      country: this.organization.country,
      phone: this.organization.phone,
      fax: this.organization.fax,
      website: this.organization.website
    }
  }

  setProofForm () {
    if (!this.organization) return
    this.proofForm = {
      notice_of_exemption_expiration_date: this.organization.notice_of_exemption.notice_of_exemption_expiration_date || "",
      finance_authority: this.organization.notice_of_exemption.finance_authority,
      issuing_date: this.organization.notice_of_exemption.issuing_date,
      tax_no: this.organization.notice_of_exemption.tax_no,
      assessment_period: this.organization.notice_of_exemption.assessment_period,
      notice_of_exemption_type: this.organization.notice_of_exemption.notice_of_exemption_type,
      available_notice_of_exemption_types: this.organization.notice_of_exemption.available_notice_of_exemption_types,
      options: this.organization.notice_of_exemption.statutory_purposes,
      available_legal_entity_choices: this.organization.notice_of_exemption.available_legal_entity_choices,
      different_legal_entity_choice: this.organization.notice_of_exemption.different_legal_entity_choice,
      different_legal_entity_title: this.organization.notice_of_exemption.different_legal_entity_title,
      different_legal_entity_street: this.organization.notice_of_exemption.different_legal_entity_street,
      different_legal_entity_postal_code: this.organization.notice_of_exemption.different_legal_entity_postal_code,
      different_legal_entity_city: this.organization.notice_of_exemption.different_legal_entity_city,
      different_legal_entity_name: this.organization.notice_of_exemption.different_legal_entity_name,
      different_legal_entity_phone: this.organization.notice_of_exemption.different_legal_entity_phone,
      different_legal_entity_email: this.organization.notice_of_exemption.different_legal_entity_email,
      available_organization_types: this.organization.notice_of_exemption.available_organization_types,
      organization_type: this.organization.notice_of_exemption.organization_type
    }
  }

  setWhatWeNeedForm (organization: IPSEditOrganization): void {
    this.whatWeNeedForm.slug = organization.what_we_need?.slug || ""
    this.whatWeNeedForm.title = organization.what_we_need?.title || ""
    this.whatWeNeedForm.description = organization.what_we_need?.description || ""
    this.whatWeNeedForm.links = organization.what_we_need?.links || []
    this.whatWeNeedForm.document_album_slug = organization.what_we_need?.document_album_slug || ""
  }

  setBankForm () {
    if (!this.organization) return
    this.bankAccountForm = {
      owner: this.organization.owner,
      bank_name: this.organization.bank_name,
      iban: this.organization.iban,
      bic: this.organization.bic
    }
  }

  async saveBaseInformation (): Promise<boolean> {
    let saved = false
    this.resetBaseInformationFormErrors()
    const newColor = this.organization.color
    if (this.isCreate) {
      const createData = {
        ...this.baseInformationForm,
        street_contact: this.baseInformationForm.street,
        postal_code_contact: this.baseInformationForm.postal_code,
        city_contact: this.baseInformationForm.city
      }
      await axios.post(this.postUrl, createData).then(async (response) => {
        const organizationSlug = response.data.slug
        window.history.pushState({}, "", `/site-admin/organization/${organizationSlug}/update/`)
        this.organizationSlug = organizationSlug
        await this.updateColor(newColor)
        saved = true
        this.showSuccessMsg(null)
        await this.fetchOrganization()
        await this.setAllData()
      }).catch((error) => {
        this.showError(null)
        Object.keys(error.response.data).forEach(key => {
          const value = error.response.data[key]
          if (Array.isArray(value) && value.length > 0) {
            this.baseInformationFormErrors[key] = value[0]
          } else {
            this.baseInformationFormErrors[key] = value
          }
        })
      })
    } else {
      await axios.put(this.postUrl, this.baseInformationForm)
        .then(async () => {
          await this.updateColor(newColor)
          this.showSuccessMsg(null)
          saved = true
        })
        .catch((error) => {
          this.showError(null)
          Object.keys(error.response.data).forEach(key => {
            const value = error.response.data[key]
            if (Array.isArray(value) && value.length > 0) {
              this.baseInformationFormErrors[key] = value[0]
            } else {
              this.baseInformationFormErrors[key] = value
            }
          })
        })
    }
    return saved
  }

  resetBaseInformationFormErrors () {
    this.baseInformationFormErrors = {
      title: "",
      email: "",
      street: "",
      additional_information: "",
      postal_code: "",
      city: "",
      country: "",
      phone: "",
      fax: "",
      website: ""
    }
  }

  resetBankAccountFormErrors () {
    this.bankAccountFormErrors = {
      owner: "",
      bank_name: "",
      iban: "",
      bic: ""
    }
  }

  resetProofFormErrors () {
    this.proofFormErrors = {
      notice_of_exemption_expiration_date: "",
      finance_authority: "",
      issuing_date: "",
      tax_no: "",
      assessment_period: "",
      notice_of_exemption_type: "",
      different_legal_entity_choice: "",
      different_legal_entity_title: "",
      different_legal_entity_street: "",
      different_legal_entity_postal_code: "",
      different_legal_entity_city: "",
      different_legal_entity_name: "",
      different_legal_entity_phone: "",
      different_legal_entity_email: ""
    }
  }

  async saveBankForm (): Promise<boolean> {
    let saved = false
    const apiUrl = this.getPostUrlForBankAccount(this.organizationSlug)
    this.resetBankAccountFormErrors()
    await axios.put(apiUrl, this.bankAccountForm).then(async () => {
      this.showSuccessMsg(null)
      saved = true
      await this.fetchOrganization()
      this.checkAndValidateStep(this.activeStep)
    }).catch((error) => {
      this.showError(null)
      Object.keys(error.response.data).forEach(key => {
        const value = error.response.data[key]
        if (Array.isArray(value) && value.length > 0) {
          this.bankAccountFormErrors[key] = value[0]
        } else {
          this.bankAccountFormErrors[key] = value
        }
      })
    })
    return saved
  }

  async saveProof (): Promise<boolean> {
    if (this.existingNoticeOfExemptionDocuments.length === 0) {
      this.showError({ title: this.$gettext("Error"), message: this.$gettext("Please upload a document of proof.") })
      return false
    }
    let saved = false
    const apiUrl = this.getPostUrlForProofForm(this.organizationSlug)
    this.resetProofFormErrors()
    await axios.put(apiUrl, this.getPostRequestData()).then(async () => {
      saved = true
      await this.fetchOrganization()
      this.setProofForm()
      this.showSuccessMsg(null)
      this.checkAndValidateStep(this.activeStep)
    }).catch((error) => {
      this.showError(null)
      Object.keys(error.response.data).forEach(key => {
        const value = error.response.data[key]
        if (Array.isArray(value) && value.length > 0) {
          this.proofFormErrors[key] = value[0]
        } else {
          this.proofFormErrors[key] = value
        }
      })
    })
    if (this.selectedStatutoryPurposes.length === 0) {
      this.showError({ title: this.$gettext("Error"), message: this.$gettext("Please select at least one statutory purpose") })
      return false
    }
    return saved
  }

  async saveWhoWeAre (): Promise<boolean> {
    let saved = false
    const url = "/api/v4/organizations/" + this.organizationSlug + "/save-who-we-are/" + (this.isSiteAdmin ? "" : "?own=true")
    const data = {
      description: this.whatWeNeedForm.description
    }
    await axios.put(url, data)
      .then(response => {
        if (response.status === 200) {
          saved = true
        }
      })
      .catch(() => {
        this.showError(null)
      })
    return saved
  }

  showTermsError (): void {
    this.showError({ title: this.$gettext("Error"), message: this.$gettext("Please accept the terms") })
  }

  async saveCompletition (): Promise<boolean> {
    const incompleteSteps = this.steps.filter(step => !step.completed && step.key !== "completition")
    if (incompleteSteps.length > 0) {
      const message = incompleteSteps.map(step => step.title).join(", ")
      this.showError({ title: this.$gettext('Please complete all steps'), message })
      this.setActiveStep(incompleteSteps[0])
      return false
    }
    let apiUrl = ""
    if (this.isSiteAdmin) {
      apiUrl = "/api/v3/organizations/" + this.organizationSlug + "/?verify"

      const data = { ...this.adminCompletitionForm }
      if (data.internal_status === 0) {
        delete data.internal_status
      }

      axios.put(apiUrl, data).then(() => {
        this.showSuccessMsg(null)
        return true
      }).catch((error) => {
        const title = this.$gettext("Error")
        let message = this.$gettext("An error occurred")
        if (error && error.response && error.response.data && error.response.data.non_field_errors && error.response.data.non_field_errors.length > 0) {
          message = error.response.data.non_field_errors[0]
        }
        this.showError({ title, message })
      })
    } else {
      if (!this.accountPublishOrgaTermsAccepted) {
        this.showTermsError()
        return
      }
      apiUrl = "/api/v3/organizations/" + this.organizationSlug + "/?publish&own"
      const data = {
        terms: this.accountPublishOrgaTermsAccepted ? "ja" : "nein" // todo: api accepts terms if len=2, but not true/false
      }
      axios.put(apiUrl, data).then(async () => {
        this.showSuccessMsg(null)
        await this.fetchOrganization()
        return true
      }).catch(() => {
        this.showError(null)
      })
    }
  }

  redirectToTable (): void {
    const url = this.isSiteAdmin ? "/site-admin/organizations-new/" : "/account/"
    window.history.pushState({}, "", url)
    window.location.reload()
  }

  updateExemptionDate (): void {
    this.proofForm.notice_of_exemption_expiration_date = this.calculateExemptionDate(this.proofForm.issuing_date, this.proofForm.notice_of_exemption_type.toString())
  }

  calculateExemptionDate (issuingDate: string, exemptionType: string): string {
    const dateFormat = "YYYY-MM-DD"
    const momentDate = moment(issuingDate, dateFormat)
    switch (exemptionType) {
      case "1":
        return momentDate.add(5, 'years').format(dateFormat)
      case "2":
        return momentDate.add(3, 'years').format(dateFormat)
      default:
        return ""
    }
  }

  getPostRequestData(): IPSProofStepPostData {
    const data = {
      notice_of_exemption_expiration_date: this.proofForm.notice_of_exemption_expiration_date,
      finance_authority: this.proofForm.finance_authority,
      issuing_date: this.proofForm.issuing_date,
      tax_no: this.proofForm.tax_no,
      assessment_period: this.proofForm.assessment_period,
      notice_of_exemption_type: this.proofForm.notice_of_exemption_type,
      different_legal_entity_choice: this.proofForm.different_legal_entity_choice,
      different_legal_entity_title: this.proofForm.different_legal_entity_title,
      different_legal_entity_street: this.proofForm.different_legal_entity_street,
      different_legal_entity_postal_code: this.proofForm.different_legal_entity_postal_code,
      different_legal_entity_city: this.proofForm.different_legal_entity_city,
      different_legal_entity_name: this.proofForm.different_legal_entity_name,
      different_legal_entity_phone: this.proofForm.different_legal_entity_phone,
      different_legal_entity_email: this.proofForm.different_legal_entity_email,
      organization_type: this.proofForm.organization_type
    }
    for (const item of this.proofForm.options) {
      data[item.key] = this.selectedStatutoryPurposes.includes(item.key)
    }
    return data
  }

  onSelectedChanged(selectedItems: string[]): void {
    this.selectedStatutoryPurposes = selectedItems
  }

  getPostUrlForBankAccount (orgaSlug: string) {
    // todo get from API_URLS
    if (!this.isSiteAdmin) {
      return "/api/v3/organizations/" + orgaSlug + "/?bankdata&own"
    } else {
      return "/api/v3/organizations/" + orgaSlug + "/?bankdata"
    }
  }

  getPostUrlForDescription (orgaSlug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v3/organizations/" + orgaSlug + "/?description&own"
    } else {
      return "/api/v3/organizations/" + orgaSlug + "/?description"
    }
  }

  getPostUrlForProofForm (orgaSlug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v3/organizations/" + orgaSlug + "/?proof&own"
    } else {
      return "/api/v3/organizations/" + orgaSlug + "/?proof"
    }
  }

  save (): void {
    this.additionalStepsAfterFirstStep.forEach(step => {
      const apiUrl = step.additional_step.api_url
      const contentType = step.additional_step.content_type
      const data = {
        content_type: contentType
      }
      for (const field of step.additional_step.additional_fields) {
        data[field.slug] = field.value
      }
      axios.put(apiUrl, data).then(() => {
        this.showSuccessMsg(null)
      }).catch(() => {
        this.showError(null)
      })
    })
  }

  async saveSelectedAdmins (): Promise<boolean> {
    let saved = false
    const selectedAdmins = { administrated_by: this.selectedAdmins } // Wrapping the list in a dictionary
    const apiUrl = this.getPostUrlForSelectedAdmins(this.organizationSlug)
    await axios.patch(apiUrl, selectedAdmins).then(async () => {
      this.showSuccessMsg(null)
      await this.fetchOrganization()
      saved = true
      this.checkAndValidateStep(this.activeStep)
    }).catch(() => {
      this.showError(null)
    })
    return saved
  }

  getPostUrlForSelectedAdmins (organizationSlug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v4/organizations/" + organizationSlug + "/add-administrator/" + "?own/"
    } else {
      return "/api/v4/organizations/" + organizationSlug + "/add-administrator/"
    }
  }

  async saveAdditionalStep (step: IPSAddtitionalProcessingStep, projectSlug: string): Promise<boolean> {
    let saved = false
    const additionalStep = step.additional_step
    const apiUrl = additionalStep.api_url
    const additionalFields = additionalStep.additional_fields
    let contentType = null
    const data = {
      content_type: null,
      object_slug: projectSlug
    }
    for (const field of additionalFields) {
      // TODO: If a field has no content type, the field is an photo album, so it is not supposed to be in the request data, because the file is uploaded directly in photocomponent
      if (!field.value.content_type) {
        continue
      }
      contentType = field.value.content_type ? field.value.content_type : contentType
      data[field.slug] = field.value.value
    }
    data.content_type = contentType
    await axios.put(apiUrl, data).then(async () => {
      saved = true
      this.setAdditionalStepValid(step)
      const message = additionalStep.title + " " + this.$gettext('saved successfully')
      this.showSuccessMsg({ title: 'Success!', message })
    }).catch(error => {
      this.steps = this.steps.map(step => {
        (step as IPSAddtitionalProcessingStep).form_errors = error.response.data
        return step
      })
      this.showError({ title: 'Error!', message: error })
    })
    return saved
  }

  get isCreate (): boolean {
    return this.organizationSlug === null
  }

  updateBreadCrumbs() {
    // todo do the same for the other components
    // todo add urls
    const tableUrl = ""
    const createUrl = ""
    let breadcrumbs = []
    const organizationIcon = "organizationIcon"
    if (this.isCreate) {
      breadcrumbs = [
        { title: this.$gettext("Organization"), url: tableUrl },
        { title: this.$gettext("Create"), url: createUrl, icon: organizationIcon }
      ]
    } else {
      breadcrumbs = [
        { title: this.$gettext("Organization"), url: tableUrl, icon: organizationIcon },
        { title: `${this.organization.title} (${this.organization.status.display})`, url: tableUrl }
      ]
    }
    this.$emit("breadcrumbs-set", breadcrumbs) // emits to ProcessingSteps.vue to set the new breadcrumbs
  }

  handlePhotoUpdated (): void {
    this.fetchOrganization()
  }

  stepClicked (clickedStep) {
    // todo add for this and the btn funcions below:
    // e.g. validateBaseInformation() - check if title and so on
    // if not valid, show error
    // if valid use ProcessingStepMixin to change the step
    this.setActiveStep(clickedStep)
  }

  async saveBtnClicked (): Promise<boolean> {
    let saved = false
    if (this.activeStep.key === "basic") {
      saved = await this.saveBaseInformation()
    } else if (this.activeStep.key === "who_we_are") {
      saved = await this.saveWhoWeAre()
      this.checkAndValidateStep(this.activeStep)
    } else if (this.activeStep.key === "bank_account") {
      if (this.userCanEditBankInformation) {
        saved = await this.saveBankForm()
      } else {
        saved = true
      }
    } else if (this.activeStep.key === "proof") {
      if (this.proofFormIsEditable) {
        saved = await this.saveProof()
      } else {
        saved = true
      }
    } else if (this.activeStep.key === "completition") {
      saved = await this.saveCompletition()
    } else if (this.activeStep.key === "contact_persons") {
      saved = await this.saveSelectedAdmins()
      this.checkAndValidateStep(this.activeStep)
    } else if (['assignment'].includes(this.activeStep.key)) {
      saved = true
    } else {
      if (this.isIPSAdditionalProcessingStep(this.activeStep)) {
        saved = await this.saveAdditionalStep((this.activeStep as IPSAddtitionalProcessingStep), this.organizationSlug)
      }
    }
    return saved
  }

  async saveAndNextBtnClicked (): Promise<void> {
    const saved = await this.saveBtnClicked()
    if (saved) {
      this.gotoNextStepOrFinish(this.steps, this.activeStep)
    }
  }

  finishBtnClicked () {
    if (this.activeStep.key === "completition") {
      if (this.accountPublishOrgaTermsAccepted) {
        this.checkAndValidateStep(this.activeStep)
      } else {
        this.showTermsError()
        return
      }
      if (this.allStepsValid()) {
        this.saveCompletition()
      } else {
        const incompleteSteps = this.steps.filter(step => !step.completed)
        if (incompleteSteps.length > 0) {
          this.setActiveStep(incompleteSteps[0])
        }
        const title = this.$gettext("Please fill out the required fields")
        const message = incompleteSteps.map(step => step.title).join(", ")
        this.showError({ title, message })
      }
    }
  }

  checkAndValidateStep(step: IProcessingStep) {
    if (step.required === false) {
      this.setAdditionalStepValid(step)
    } else {
      if (step.key === 'contact_persons') {
        if (this.organization.has_public_contact_person) {
          this.setAdditionalStepValid(step)
        } else {
          this.setAdditionalStepInvalid(step)
        }
      } else if (step.key === "bank_account") {
        if (!this.organization) {
          this.setAdditionalStepInvalid(step)
        } else if (this.organization.bank_name && this.organization.owner && this.organization.iban && this.organization.bic) {
          this.setAdditionalStepValid(step)
        } else {
          this.setAdditionalStepInvalid(step)
        }
      } else if (step.key === "description") {
        if (this.organization.description_de && this.organization.description_activity_de && this.organization.description_strength_de) {
          this.setAdditionalStepValid(step)
        } else {
          this.setAdditionalStepInvalid(step)
        }
      } else if (step.key === "proof") {
        if (this.organizationHasValidProof) {
          this.setAdditionalStepValid(step)
        } else {
          this.setAdditionalStepInvalid(step)
        }
      } else if (step.key === "who_we_are") {
        if (this.whatWeNeedForm.description) {
          this.setAdditionalStepValid(step)
        } else {
          this.setAdditionalStepInvalid(step)
        }
      } else if (step.key === "completition") {
        if (this.organizationIsLive || !this.organizationIsDraft) {
          this.setAdditionalStepValid(step)
        } else {
          if (this.isSiteAdmin) {
            this.setAdditionalStepValid(step)
          } else {
            const termsAccepted = this.accountPublishOrgaTermsAccepted // todo implement, if checkbox is checked
            if (termsAccepted || this.organizationIsLive || this.organizationIsComplete) {
              this.setAdditionalStepValid(step)
            } else {
              this.setAdditionalStepInvalid(step)
            }
          }
        }
      }
    }
  }

  async onLogoUploaded (logos: IPhoto[]): Promise<void> {
    this.existingLogos = logos || []
    this.checkAndValidateSteps()
  }

  setAdminCompletitionForm (organization: IPSAdminEditOrganization) {
    if (this.organization) {
      this.adminCompletitionForm.content_review_status = organization.content_review_status.choice
      this.adminCompletitionForm.nonprofit_review_status = organization.nonprofit_review_status.choice
      this.adminCompletitionForm.internal_status = organization.internal_status.choice
      this.adminCompletitionForm.status = organization.status.choice
      this.adminCompletitionForm.review_status = organization.review_status.choice
    }
  }

  get organizationIsComplete () {
    return this.allStepsValid()
  }

  onUserWasInvited ():void {
    const message = this.$gettext("The user was invited successfully")
    this.showSuccessMsg({ title: this.$gettext("Success"), message })
  }

  async photoUploaded () {
    await this.fetchOrganization()
    this.checkAndValidateStep(this.activeStep)
  }

  get organizationHasValidProof (): boolean {
    if (this.organization) {
      const hasFile = this.organization.notice_of_exemption_files.length > 0 || this.organization.notice_of_exemption_photos.length > 0
      const hasData = this.organization.notice_of_exemption.finance_authority &&
        this.organization.notice_of_exemption.issuing_date &&
        this.organization.notice_of_exemption.tax_no &&
        this.organization.notice_of_exemption.assessment_period &&
        this.organization.notice_of_exemption.notice_of_exemption_type
      if (hasFile && hasData) {
        return true
      }
    }
    return false
  }

  async changeOrganizationEmail (): Promise<void> {
    let saved = false
    await axios.post(API_URLS.ORGANIZATIONS.CHANGE_EMAIL(this.organization.slug), {
      email: this.baseInformationForm.email
    })
      .then(() => {
        saved = true
      })
      .catch((error) => {
        Object.keys(error.response.data).forEach(key => {
          const value = error.response.data[key]
          if (Array.isArray(value) && value.length > 0) {
            this.baseInformationFormErrors[key] = value[0]
          } else {
            this.baseInformationFormErrors[key] = value
          }
        })
      })
    if (saved) {
      this.showInformation({ title: this.$gettext("We have sent you an email"), message: this.$gettext("Please follow the link in the email, to confirm your new email within 30 minutes.") })
    } else {
      this.showError({ title: this.$gettext("Error"), message: this.$gettext("An error occurred") })
    }
  }

  openCreateContactModal (): void {
    this.contactPersonSelect.openEditModal(null)
  }
}
