
import { Component, Mixins, Ref } from 'vue-property-decorator'
import StepNavigation from '../StepNavigation.vue'
import FormButtons from '../FormButtons.vue'
import axios from 'axios'
import { API_URLS, SITE_URLS } from '@/utils/helpers'
import StepContainer from '../commons/StepContainer.vue'
import EditAdditionalStep from '../additional_steps/EditAdditionalStep.vue'
import { IPhoto } from '@/types/photos'
import DocumentField from '../commons/fields/DocumentField.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import ProjectBaseInformationForm from './forms/ProjectBaseInformationForm.vue'
import LocationForm from '../commons/forms/LocationForm.vue'
import ContactPersonSelect from '../commons/forms/ContactPersonSelect.vue'
import AddSubCategory from '@/components/sub_categories/AddSubCategory.vue'
import SelectBankAccount from './forms/SelectBankAccount.vue'
import ProjectStepMixin from './mixins/ProjectStepMixin.vue'
import ProjectBaseInformationMixin from './mixins/ProjectBaseInformationMixin.vue'
import ProjectAdditionalStepMixin from './mixins/ProjectAdditionalStepMixin.vue'
import FundingCodeForm from './forms/FundingCodeForm.vue'
import ResultReportForm from './forms/ResultReportForm.vue'
import ResultReportMixin from './mixins/ResultReportMixin.vue'
import ProcessingStepMixin from '../ProcessingStepMixin.vue'
import AdminCompletitionForm from './forms/completition_forms/AdminCompletitionForm.vue'
import AccountProjectIsLiveForm from './forms/completition_forms/AccountProjectIsLiveForm.vue'
import ProjectFinancingNeedsForm from "@/components/processing_steps/project/forms/ProjectFinancingNeedsForm.vue"
import AccountPublishProjectForm from './forms/completition_forms/AccountPublishProjectForm.vue'
import { IResultReportForm, ISubCategory } from '@/types/foerderApp'
import { IPSAddtitionalProcessingStep, IPSAdminCompletionForm, IPSEditProject, IPSMinimalContactPerson, IProcessingStep } from '@/types/processingSteps'
import AccountProjectWaitingForReview from './forms/completition_forms/AccountProjectWaitingForReview.vue'
import SelectFundingScope from './forms/SelectFundingScope.vue'
import AccountProjectIsPaused from './forms/completition_forms/AccountProjectIsPaused.vue'
import AccountProjectIsFinished from './forms/completition_forms/AccountProjectIsFinished.vue'
import AdminSelectForm from "@/components/processing_steps/commons/forms/AdminSelectForm.vue"
import OrgaNotLiveBanner from '@/components/processing_steps/banners/OrgaNotLiveBanner.vue'
import ConfirmModal from '@/components/processing_steps/commons/modals/ConfirmModal.vue'
import WhatWeNeedStepMixin from './mixins/WhatWeNeedStepMixin.vue'
import WhatWeNeed from './forms/WhatWeNeed.vue'
import ProjectSummaryMixin from './mixins/ProjectSummaryMixin.vue'

@Component({
  components: {
    AdminSelectForm,
    ProjectFinancingNeedsForm,
    StepNavigation,
    FormButtons,
    StepContainer,
    EditAdditionalStep,
    DocumentField,
    FormContainer,
    ProjectBaseInformationForm,
    LocationForm,
    ContactPersonSelect,
    AddSubCategory,
    SelectBankAccount,
    ProjectStepMixin,
    ProjectBaseInformationMixin,
    ProjectAdditionalStepMixin,
    FundingCodeForm,
    ResultReportForm,
    ResultReportMixin,
    ProcessingStepMixin,
    AdminCompletitionForm,
    AccountProjectIsLiveForm,
    AccountPublishProjectForm,
    AccountProjectWaitingForReview,
    SelectFundingScope,
    AccountProjectIsPaused,
    AccountProjectIsFinished,
    OrgaNotLiveBanner,
    ConfirmModal,
    WhatWeNeed
  }
})
export default class EditProject extends Mixins(
  WhatWeNeedStepMixin,
  ProjectStepMixin,
  ProjectBaseInformationMixin,
  ProjectAdditionalStepMixin,
  ResultReportMixin,
  ProcessingStepMixin,
  ProjectSummaryMixin
) {
  @Ref() contactPersonSelect!: ContactPersonSelect

  activeStep = null

  exitstingPhotos: IPhoto[] = []
  existingDocuments: IPhoto[] = []

  projectSlug: string | null = null

  accountPublishProjectTermsAccepted = false

  selectedAdmins: string[] = []

  finishModalOpen = false

  pauseModalOpen = false

  differentProjectCategoryIds = []

  getPostUrlForFundingAccount (projectSlug: string) {
    // todo collect all required api urls (create project on foerderapp demo and get the url and also which data in which format is sent.)
    // e.g. project is created:
    // POST /api/v3/projects/???
    // data: title: string, category: string (category.slug) and so on
    // put the urls in API_URLS and find a suitable name

    // here it is: funding_pot: "donation-bank-account" (bank account slug)
    // the api endpoint reads the param funding_pot and
    // then knows which serializer to use to save the data
    if (!this.isSiteAdmin) {
      return "/api/v3/projects/" + projectSlug + "/?funding_pot&own"
    } else {
      return "/api/v3/projects/" + projectSlug + "/?funding_pot"
    }
  }

  getPostUrlForSelectedAdmins (projectSlug: string) {
    if (!this.isSiteAdmin) {
      return "/api/v4/projects/" + projectSlug + "/add-administrator/?own"
    } else {
      return "/api/v4/projects/" + projectSlug + "/add-administrator/"
    }
  }

  getCompletionUrl(projectSlug: string) {
    if (this.isSiteAdmin) {
      return "/api/v3/projects/" + projectSlug + "/?publish"
    } else {
      return "/api/v3/projects/" + projectSlug + "/?own&publish"
    }
  }

  getProjectSlug (): void {
    const currentUrl = window.location.pathname
    if (currentUrl.endsWith("update/")) {
      this.projectSlug = currentUrl.split("project/")[1].split("/update/")[0]
    } else {
      this.projectSlug = null
    }
  }

  get requiredStepsComplete (): boolean {
    const requiredStepsKeys = [
      "basic",
      "what_we_need",
      "contact_persons",
      "funding_code"
    ]
    for (const key of requiredStepsKeys) {
      const step = this.steps.find(step => step.key === key)
      if (!step || !step.completed) {
        return false
      }
    }
    return true
  }

  get projectIsLive (): boolean {
    return (this.project && this.project.live && !this.projectIsFinished) || false
  }

  get projectIsDraft (): boolean {
    return this.project?.is_status_draft || false
  }

  get projectIsPaused (): boolean {
    return this.project?.status === 4 || false
  }

  get projectIsFinished (): boolean {
    return this.project?.is_finished || false
  }

  get orgaIsWaitingForReview (): boolean {
    return this.project?.organization?.current_status?.show_organization_not_live_banner || false
  }

  get photoUploadUrl (): string {
    if (!this.project) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.project.photo_album_slug)
  }

  get documentUploadUrl (): string {
    if (!this.project) { return "" }
    return API_URLS.PHOTOS.UPLOAD_PHOTO(this.project.what_we_need.document_album_slug)
  }

  get contactPersonUrl(): string {
    return API_URLS.CONTACT_PERSON.LIST
  }

  get addContactPersonToInstanceUrl(): string {
    return API_URLS.CONTACT_PERSON.ADD_TO_INSTANCE
  }

  get isCreate (): boolean {
    return this.projectSlug === null
  }

  get projectListUrl (): string {
    return this.isSiteAdmin ? "/site-admin/projects-new/" : "/account/projects-new/"
  }

  get projectDetailUrl (): string {
    if (!this.projectSlug) {
      return ""
    }
    return "/project/" + this.projectSlug + "/"
  }

  get projectPreviewUrl (): string {
    return "/project/" + this.project.slug + "/?preview_token=" + this.project.preview_token
  }

  get createProjectUrl (): string {
    return this.isSiteAdmin ? "/site-admin/projects/create/" : "/account/projects/create/"
  }

  get publicContactPersonTableItem (): IPSMinimalContactPerson[] {
    return this.project ? [this.project.public_contact_person] : []
  }

  async mounted (): Promise<void> {
    await this.setAllData()
  }

  async setAllData (): Promise<void> {
    this.getProjectSlug()
    await this.fetchProject()
    this.setAvailableOrganizations(this.project.available_organizations)
    this.setSteps()
    this.setActiveStep(null)
    this.setProjectBaseInformationForm(this.project)
    this.setWhatWeNeedForm(this.project)
    this.setAvailableProjectCategories(this.project.available_project_categories)
    this.setProjectLocationForm(this.project)
    this.setProjectFinanceForm(this.project)
    this.setProjectCompletionForm(this.project)
    this.selectedAdmins = this.project?.admins?.map(admin => admin.username) || []
    this.selectedFundingScopesSlug = this.project.funding_scope_slug
    this.steps.forEach(step => {
      this.checkAndValidateStep(step)
    })
    this.updateBreadCrumbs()
  }

  async onPublicContactPersonChanged (): Promise<void> {
    await this.fetchProject()
    this.checkAndValidateStep(this.activeStep)
  }

  async saveResultReport (resultReportForm: IResultReportForm): Promise<boolean> {
    let saved = false
    const url = API_URLS.RESULT_REPORT.RETRIEVE(this.resultReportSlug)
    await axios.put(url, resultReportForm)
      .then(async () => {
        this.showSuccessMsg(null)
        saved = true
        await this.fetchProject()
        this.checkAndValidateStep(this.activeStep)
      }).catch(() => {
        this.showError(null)
      })
    return saved
  }

  setWhatWeNeedForm (project: IPSEditProject): void {
    this.whatWeNeedForm.slug = project.what_we_need?.slug || ""
    this.whatWeNeedForm.description = project.what_we_need?.description || ""
    this.whatWeNeedForm.links = project.what_we_need?.links || []
    this.whatWeNeedForm.document_album_slug = project.what_we_need?.document_album_slug || ""
  }

  async saveWhatWeNeed (): Promise<boolean> {
    let saved = false
    const url = "/api/v4/projects/" + this.project.slug + "/save-what-we-need/?what_we_need=true" + (this.isSiteAdmin ? "" : "&own=true")
    const data = {
      description: this.whatWeNeedForm.description
    }
    await axios.put(url, data)
      .then(response => {
        if (response.status === 200) {
          saved = true
        }
      })
      .catch(() => {
        this.showError(null)
      })
    return saved
  }

  async saveAdminCompletionForm (adminCompletionForm: IPSAdminCompletionForm) {
    let saved = false
    const url = this.getCompletionUrl(this.projectSlug)
    const data = {
      ...adminCompletionForm,
      administrated_by: this.project.administrated_by
    }
    await axios.put(url, data)
      .then(async () => {
        saved = true
        this.showSuccessMsg(null)
        await this.fetchProject()
        this.checkAndValidateStep(this.activeStep)
      }).catch(() => {
        const incompleteSteps = this.steps.filter(step => !step.completed)
        if (incompleteSteps.length > 0) {
          const message = incompleteSteps.map(step => step.title).join(", ")
          this.showError({ title: this.$gettext('Please complete all steps'), message })
          this.setActiveStep(incompleteSteps[0])
          return false
        } else {
          this.showError(null)
        }
      })
    return saved
  }

  async resumeProject (): Promise<void> {
    const url = "/api/v3/projects/" + this.projectSlug + "/?resume&own"
    await axios.put(url)
      .then(async () => {
        this.showSuccessMsg(null)
        await this.fetchProject()
      })
      .catch(() => {
        this.showError(null)
      })
  }

  get contactPersonHref (): string {
    if (this.isSiteAdmin) {
      return SITE_URLS.FOERDER_APP.SITE_ADMIN.CONTACT_PERSON.CREATE
    } else {
      return SITE_URLS.FOERDER_APP.ACCOUNT.CONTACT_PERSON.CREATE
    }
  }

  updateBreadCrumbs() {
    // todo do the same for the other components
    // todo add urls
    const tableUrl = this.isSiteAdmin ? "/site-admin/projects-new/" : "/account/projects-new/"
    const createUrl = ""
    let breadcrumbs = []
    const projectIcon = 'projectIcon'
    if (this.isCreate) {
      breadcrumbs = [
        { title: this.$gettext("Funding need"), url: tableUrl, icon: projectIcon },
        { title: this.$gettext("Create"), url: createUrl }
      ]
    } else {
      breadcrumbs = [
        { title: this.$gettext("Funding need"), url: tableUrl, icon: projectIcon },
        { title: this.project.title, url: '/processing-steps/' }
      ]
    }
    this.$emit("breadcrumbs-set", breadcrumbs) // emits to ProcessingSteps.vue to set the new breadcrumbs
  }

  async fetchProject(): Promise<void> {
    let url = API_URLS.PROJECTS.CREATE_DATA
    if (this.projectSlug) {
      url = API_URLS.PROJECTS.EDIT_DATA(this.projectSlug)
    }
    try {
      const response = await axios.get(url)
      this.project = response.data
      this.exitstingPhotos = this.project.photos
      if (this.project && this.project.what_we_need) {
        this.existingDocuments = this.project.what_we_need.documents
      }
      // todo additionalSteps have an ordering. You can choose after which step the additional step is added
      // this is for after the first step
      // add the same for the other positions (also in template and ProjectStepMixin)
      this.additionalStepsAfterFirstStep = this.createAdditonalSteps(this.project.additional_steps_base_information)
    } catch {
      this.showError(null)
    }
  }

  handleFilesUploaded (): void {
    this.fetchProject()
  }

  gotoStep (clickedStep: IProcessingStep): void {
    if (!this.baseInformationIsValid) {
      this.showError({ title: 'Error!', message: 'Please fill out the required fields' })
      return
    }
    this.setActiveStep(clickedStep)
  }

  async saveFundingPot (): Promise<boolean> {
    let saved = false
    const fundingPot = this.project.individual_bank_account
    const apiUrl = this.getPostUrlForFundingAccount(this.projectSlug)
    await axios.put(apiUrl, { funding_pot: fundingPot }).then(async () => {
      this.showSuccessMsg(null)
      await this.fetchProject()
      saved = true
      this.checkAndValidateStep(this.activeStep)
    }).catch(() => {
      this.showError(null)
    })
    return saved
  }

  async saveSelectedAdmins (): Promise<boolean> {
    let saved = false
    const selectedAdmins = { administrated_by: this.selectedAdmins } // Wrapping the list in a dictionary
    const apiUrl = this.getPostUrlForSelectedAdmins(this.projectSlug)
    await axios.post(apiUrl, selectedAdmins).then(async () => {
      this.showSuccessMsg(null)
      await this.fetchProject()
      saved = true
      this.checkAndValidateStep(this.activeStep)
    }).catch(() => {
      this.showError(null)
    })
    return saved
  }

  async handlePhotoUpdated (): Promise<void> {
    await this.fetchProject()
    this.checkAndValidateStep(this.activeStep)
  }

  async finishBtnClicked(): Promise<void> {
    const saved = await this.saveCompletion()
    if (saved) {
      this.showSuccessMsg(null)
      this.redirectToTable()
    }
  }

  async saveBtnClicked (currentStep: IProcessingStep | IPSAddtitionalProcessingStep): Promise<boolean> {
    let saved = false
    // todo add other steps - see also comment in getPostUrlForFundingAccount
    if (currentStep.key === "basic") {
      if (this.projectSlug) {
        saved = await this.saveBaseInformation(this.projectSlug)
        await this.saveAssignment()
        await this.saveFinancingNeeds()
      } else {
        if (!this.baseInformationFromIsValid) {
          this.showError({ title: 'Error!', message: 'Please fill out the required fields' })
          return false
        }
        const created = await this.createProject()
        this.projectSlug = typeof created === 'string' ? created : null
        if (typeof created === 'string') {
          this.projectSlug = created
          await this.saveAssignment()
          await this.saveFinancingNeeds()
          const updateUrl = "/account/project/" + this.projectSlug + "/update/"
          // todo store urls somewhere else
          window.history.pushState({}, "", updateUrl)
          this.showSuccessMsg(null)
          await this.setAllData()
          this.gotoNextStepOrFinish(this.steps, currentStep)
        }
      }
    } else if (currentStep.key === "individual_bank_account") {
      saved = await this.saveFundingPot()
    } else if (currentStep.key === "result_report") {
      saved = await this.saveResultReport(this.resultReportForm)
    } else if (currentStep.key === "completion") {
      this.saveCompletion()
      saved = true
    } else if (currentStep.key === "what_we_need") {
      saved = await this.saveWhatWeNeed()
      await this.fetchProject()
      this.checkAndValidateStep(this.activeStep)
    } else if (currentStep.key === 'assignment') {
      saved = true
    } else if (currentStep.key === "contact_persons") {
      await this.fetchProject()
      saved = await this.saveSelectedAdmins()
      this.checkAndValidateStep(this.activeStep)
    } else if (["photos", "funding_code"].includes(currentStep.key)) {
      this.gotoNextStepOrFinish(this.steps, currentStep)
    } else if (this.isIPSAdditionalProcessingStep(currentStep)) {
      saved = await this.saveAdditionalStep((currentStep as IPSAddtitionalProcessingStep), this.projectSlug)
    }
    return saved
  }

  async saveAndNextBtnClicked (currentStep: IProcessingStep): Promise<void> {
    const saved = await this.saveBtnClicked(currentStep)
    if (this.projectSlug) {
      if (this.baseInformationFromIsValid && saved) {
        this.gotoNextStepOrFinish(this.steps, currentStep)
      }
    }
  }

  async saveFinancingNeeds (): Promise<void> {
    let url = "/api/v3/projectmilestones/"
    const update = this.project && this.project.milestones && this.project.milestones.length === 1
    if (update) {
      url += this.project.milestones[0].slug + "/"
    }
    const data = {
      goal_in_cents: this.financeForm.goal_in_cents,
      project: this.projectSlug,
      title: this.projectSlug + "-title",
      description: this.projectSlug + "-description"
    }
    if (update) {
      await axios.put(url, data).catch(() => {
        this.showError(null)
      })
    } else {
      await axios.post(url, data).catch(() => {
        this.showError(null)
      })
    }
  }

  async saveAssignment (): Promise<boolean> {
    let saved = false
    if (!this.selectedFundingScopesSlug) {
      this.showError({ title: 'Error!', message: this.$gettext('Please select a funding scope') })
      this.fundingScopeFormErrors.funding_scope_slug = this.$gettext('This field is required')
      return saved
    }
    let url = "/api/v4/projects/" + this.projectSlug + "/add-funding-scope-to-project/"
    if (!this.isSiteAdmin) {
      url += "?own"
    }
    await axios.post(url, { funding_scope_slug: this.selectedFundingScopesSlug }).then(async () => {
      saved = true
    }).catch(() => {
      this.showError(null)
    })
    return saved
  }

  async saveAdditionalStep (step: IPSAddtitionalProcessingStep, projectSlug: string): Promise<boolean> {
    let saved = false
    const additionalStep = step.additional_step
    const apiUrl = additionalStep.api_url
    const additionalFields = additionalStep.additional_fields
    let contentType = null
    const data = {
      content_type: null,
      object_slug: projectSlug
    }
    for (const field of additionalFields) {
      // If a field has no content type, the field is an photo album, so it is not supposed to be in the request data, because the file is uploaded directly in photocomponent
      if (!field.value.content_type) {
        continue
      }
      contentType = field.value.content_type ? field.value.content_type : contentType
      data[field.slug] = field.value.value
    }
    data.content_type = contentType
    await axios.put(apiUrl, data).then(() => {
      saved = true
      this.setAdditionalStepValid(step)
      const message = additionalStep.title + " " + this.$gettext('saved successfully')
      this.showSuccessMsg({ title: 'Success!', message })
    }).catch(error => {
      this.showError(null)
      this.steps = this.steps.map(step => {
        if ('form_errors' in step) {
          (step as IPSAddtitionalProcessingStep).form_errors = error.response.data
        }
        return step
      })
    })
    return saved
  }

  async saveCompletion (): Promise<boolean> {
    let saved = false
    this.steps[6].completed = true
    if (this.isSiteAdmin) {
      saved = await this.saveAdminCompletionForm(this.adminCompletitionForm)
    } else {
      if (this.project.is_status_draft) {
        saved = await this.publishProject()
      } else {
        saved = true
      }
    }
    return saved
  }

  redirectToTable () {
    window.location.href = this.isSiteAdmin ? "/site-admin/projects-new/" : "/account/projects-new/"
  }

  async publishProject (): Promise<boolean> {
    // todo refactor
    const termsAccepted = this.accountPublishProjectTermsAccepted
    if (termsAccepted) {
      this.checkAndValidateStep(this.activeStep)
    }
    const incompleteSteps = this.steps.filter(step => !step.completed)
    if (incompleteSteps.length > 0) {
      const message = incompleteSteps.map(step => step.title).join(", ")
      this.showError({ title: this.$gettext('Please complete all steps'), message })
      this.setActiveStep(incompleteSteps[0])
      return false
    }
    if (!termsAccepted) {
      this.showError({ title: this.$gettext('Please accept the terms and conditions'), message: this.$gettext('You need to accept the terms and conditions to publish the project.') })
      return false
    }
    let saved = false
    const url = this.getCompletionUrl(this.projectSlug)
    const data = {
      terms: termsAccepted
    }
    await axios.put(url, data).then(async () => {
      await this.fetchProject()
      this.checkAndValidateStep(this.activeStep)
      saved = true
    })
    return saved
  }

  async pauseFundingRequirement (): Promise<void> {
    // currently only used for orga users
    const url = "/api/v3/projects/" + this.projectSlug + "/?pause&own"
    axios.put(url)
      .then(async () => {
        this.showSuccessMsg(null)
        await this.fetchProject()
      })
      .catch(() => {
        this.showError(null)
      })
  }

  async endFundingRequirement (): Promise<void> {
    const url = "/api/v3/projects/" + this.projectSlug + "/?finish&own"
    await axios.put(url)
      .then(async () => {
        this.showSuccessMsg(null)
        await this.fetchProject()
      })
      .catch(() => {
        this.showError(null)
      })
  }

  enterOrganizationsAddress (): void {
    this.projectLocationForm.street = this.project.organization.street
    this.projectLocationForm.postal_code = this.project.organization.postal_code
    this.projectLocationForm.city = this.project.organization.city
    this.projectLocationForm.country = this.project.organization.country
    this.projectLocationForm.additional_information = this.project.organization.additional_information
  }

  onSubCategoriesFetched (subCategories: ISubCategory[]): void {
    if (subCategories.length) {
      const projectCategory = subCategories[0].project_category // this is a number
      if (!this.differentProjectCategoryIds.includes(projectCategory)) {
        this.differentProjectCategoryIds.push(projectCategory)
      }
    }
  }

  onSubCategoryAdded (projectCategoryId: number): void {
    if (!this.differentProjectCategoryIds.includes(projectCategoryId)) {
      this.differentProjectCategoryIds.push(projectCategoryId)
    }
  }

  onSubCategoryRemoved (projectCategoryId: number): void {
    this.differentProjectCategoryIds = this.differentProjectCategoryIds.filter(id => id !== projectCategoryId)
  }

  openCreateContactModal (): void {
    this.contactPersonSelect.openEditModal(null)
  }
}
