
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IShowcasePage } from '@/types/showcase'
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import ConfirmModal from '@/components/processing_steps/commons/modals/ConfirmModal.vue'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    CompletitionLayout,
    ConfirmModal
  }
})
export default class ShowcaseSummaries extends Vue {
  @Prop() showcasePage: IShowcasePage | null
  @Prop() termsAndPrivacyHtml: string
  @Prop() baseInformationIsValid!: boolean

  termsAccepted = false
  finishModalOpen = false

  get showcasePageStatus (): 'draft' | 'dataComplete' | 'public' | 'paused' | 'archived' {
    /*
        Draft = ChoiceItem(1, _("in Progress"))
        Public = ChoiceItem(2, _("complete"))
        Archived = ChoiceItem(3, _("archived"))
        Paused = ChoiceItem(4, _("Paused"))
    */
    switch (this.showcasePage?.status) {
      case 1:
        return this.dataIsComplete ? 'dataComplete' : 'draft'
      case 2:
        return 'public'
      case 3:
        return 'archived'
      case 4:
        return 'paused'
      default:
        return 'draft'
    }
  }

  get isDraft (): boolean {
    return this.showcasePage?.status === 1
  }

  get isPublic (): boolean {
    return this.showcasePage?.status === 2
  }

  get isPaused (): boolean {
    return this.showcasePage?.status === 4
  }

  get dataIsComplete (): boolean {
    const requiredDocs = [
      "photos"
    ]
    const requiredDocsFilled = requiredDocs.every(doc => this.showcasePage[doc].length > 0)
    const hasContactPerson = this.showcasePage.contact_person !== null
    const hasAFilterContainer = this.showcasePage.filter_container.length > 0

    return this.baseInformationIsValid && requiredDocsFilled && hasContactPerson && hasAFilterContainer
  }

  get statusTitle (): string {
    switch (this.showcasePageStatus) {
      case 'draft':
        // In Bearbeitung
        return this.$gettext('In progress')
      case 'public':
        // Ihr Fördervorschlag ist freigegeben.
        return this.$gettext('Your funding proposal is approved')
      case 'archived':
        // Ihr Fördervorschlag ist beendet.
        return this.$gettext('Your funding proposal has ended.')
      case 'dataComplete':
        return this.$gettext('Completed')
      default:
        return this.$gettext('Paused')
    }
  }

  get statusExplanation (): string {
    switch (this.showcasePageStatus) {
      case 'draft':
        // Die Daten zu diesem Fördervorschlag sind unvollständig.
        return this.$gettext('The data for this funding proposal is incomplete.')
      case 'public':
        // Sie können ihn pausieren oder beenden.
        return this.$gettext('You can pause or finish it.')
      case 'archived':
        // Sie können einen neuen Fördervorschlag erstellen.
        return this.$gettext('You can create a new funding proposal.')
      case 'dataComplete':
        // Dieser Fördervorschlag ist vollständig angelegt. Sie können ihn veröffentlichen.
        return this.$gettext('This funding proposal is fully created. You can publish it.')
      default:
        // Sie können ihn fortsetzen oder beenden.
        return this.$gettext('You can resume or finish it.')
    }
  }

  get statusIconClass (): string | null {
    switch (this.showcasePageStatus) {
      case 'public':
        return 'checkIcon'
      case 'paused':
        return 'pauseIcon'
      case 'archived':
        return 'projectFinishedIcon'
      case 'dataComplete':
        return null
      default:
        return null
    }
  }

  get summaryItems () {
    return [
      this.organizationDetails
    ]
  }

  get organizationDetails (): { title: string, items: { title: string, values: string[]}[]} {
    return {
      title: this.$gettext('Funding proposal'),
      items: [
        {
          title: this.$gettext('Title'),
          values: [this.showcasePage.title]
        }
      ]
    }
  }

  formatDate (date: string): string {
    return new Date(date).toLocaleDateString()
  }

  formatStatuaryPurposes (purposes: { text: string, value: boolean, key: string }[]): string[] {
    return purposes
      .filter(purpose => purpose.value)
      .map((purpose, index) => `${index + 1}. ${purpose.text}`)
  }

  async changeFundingPageStatus (status: number, statusVerbose: string): Promise<void> {
    const postData = {
      status
    }
    await axios.put(API_URLS.SHOWCASE_PAGE.UPDATE_COMPLETITION(this.showcasePage.slug), postData).then(async () => {
      this.$emit("funding-page-status-changed", statusVerbose)
    })
  }

  async publishFundingPage(): Promise<void> {
    if (!this.termsAccepted) {
      const errorData = {
        title: this.$gettext('Please accept the terms and conditions'),
        message: this.$gettext('You need to accept the terms and conditions to publish the funding proposal.')
      }
      this.$emit("show-error", errorData)
      return
    }
    this.changeFundingPageStatus(2, 'public')
  }

  async pauseFundingPage (): Promise<void> {
    this.changeFundingPageStatus(4, 'paused')
  }

  async finishFundingPage (): Promise<void> {
    this.changeFundingPageStatus(3, 'archived')
  }

  async resumeFundingPage (): Promise<void> {
    this.changeFundingPageStatus(2, 'resumed')
  }
}
