
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
@Component
export default class CheckboxField extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: '' }) checkboxText!: string
  @Prop({ default: false }) required!: boolean

  localValue = false
  state: boolean | null = null

  mounted() {
    this.localValue = this.value
  }

  @Emit('input')
  updateValue() {
    return this.localValue
  }

  @Watch('value')
  onPropValueChanged(newValue: boolean) {
    this.localValue = newValue
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }
}
