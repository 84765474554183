

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconButton extends Vue {
  @Prop({ required: false }) href!: string
  @Prop({ default: null }) tooltip!: string | null
  @Prop({ default: null }) iconClass: string | null
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: null }) route!: string | null
  @Prop({ default: true }) newTab!: boolean

  onButtonClicked (event: Event): void {
    if (this.route) {
      event.preventDefault()
      if (this.newTab) {
        const routeData = this.$router.resolve(this.route)
        window.open(routeData.href, '_blank')
      } else {
        this.$router.push(this.route)
      }
    } else {
      this.$emit("btn-clicked")
    }
  }
}
