

import { Mixins, Component, Prop } from 'vue-property-decorator'
import FileUploader from '@/components/file_uploader/FileUploader.vue'
import UserMixin from '@/mixins/UserMixin'
import { IPhoto } from '@/types/photos'

@Component({
  components: {
    FileUploader
  },
  mixins: [UserMixin]
})
export default class DocumentField extends Mixins(UserMixin) {
  @Prop({ required: true }) uploadUrl!: string | null
  @Prop({ default: '16:9' }) cropperFormat!: string
  @Prop({ default: false }) isLogo!: boolean
  @Prop({ default: false }) isMultiple!: boolean
  @Prop({ default: false }) isStencilCircle!: boolean
  @Prop({ default: false }) useCropper!: boolean
  @Prop({ default: 'files' }) formDataKey!: string
  @Prop({ default: () => [] }) existingPhotos!: IPhoto[]
  @Prop({ default: false }) isFile
  @Prop({ default: false }) acceptFiles
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop() uploadLimit: number
  @Prop({ default: false }) uploadDisabled: boolean
  @Prop({ default: false }) deleteFilesDisabled: boolean
  @Prop({ required: false }) imageItemClass: string
  @Prop({ default: false }) isRestrictedFile: boolean

  handlePhotoUpdated (photos: IPhoto[]): void {
    this.$emit("files-uploaded", photos)
  }

  onFileInputClicked (): void {
    this.$emit("file-input-clicked")
  }
}
