
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ColorInput extends Vue {
  @Prop() value: string

  get placeholder (): string {
    return '#fbbb59'
  }

  updateValue (event): void {
    this.$emit("change", event.target.value)
  }

  updateValueText (value): void {
    if (!value.startsWith('#')) {
      value = '#' + value
    }
    this.$emit("change", value)
  }
}
