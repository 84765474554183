
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import TextField from "@/components/processing_steps/commons/fields/TextField.vue"
import SingleSelectField from "@/components/processing_steps/commons/fields/SingleSelectField.vue"
import CharField from '../commons/fields/CharField.vue'
import EmailInput from '../commons/fields/EmailInput.vue'
import { IPSContactPersonBaseForm, IPSSelectOption } from '@/types/processingSteps'
import TermOfAddressField from "@/components/forms/fields/TermOfAddressField.vue"

@Component({
  components: {
    TermOfAddressField,
    SingleSelectField,
    TextField,
    FormTitle,
    FormContainer,
    CharField,
    EmailInput
  }
})
export default class ContactPersonForm extends Vue {
  @Prop() contactPersonForm!: IPSContactPersonBaseForm
  @Prop() contactPersonFormErrors!: IPSContactPersonBaseForm
  @Prop() availableOrganizations!: IPSSelectOption[]
}
