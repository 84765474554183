

import ToastMixin from '@/mixins/ToastMixin'
import { Component } from 'vue-property-decorator'

@Component
export default class ValidationMixin extends ToastMixin {
  // todo remove the whole component and use ProcessingStepMixin
  showStepValidationError () {
    this.makeToast('danger', 'error', 'Bitte füllen Sie alle Pflichtfelder aus.')
  }

  showStepValidationSuccess() {
    this.makeToast('success', 'Super!', 'Alle Pflichtfelder wurden ausgefüllt!')
  }
}
