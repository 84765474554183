
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CompletitionLayout
  }
})
export default class AccountOrgaIsLiveForm extends Vue {
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[] }[]}[]
}
