
import DeleteModal from '@/components/modals/DeleteModal.vue'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
@Component({
  components: {
    DeleteModal
  }
})
export default class UrlField extends Vue {
  @Prop({ required: false }) initialLink: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean

  formError = ''

  get state () {
    return this.formError ? false : null
  }

  get placeHolderHref () {
    return 'https://www.example.com/index'
  }

  @Emit('input')
  updateValue(newValue: string) {
    return newValue
  }
}
