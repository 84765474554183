
import { Component } from 'vue-property-decorator'
import EditContactPerson from '@/components/processing_steps/contact_person/EditContactPerson.vue'
import EditFundingScope from '@/components/processing_steps/funding_scope/EditFundingScope.vue'
import EditProject from '@/components/processing_steps/project/EditProject.vue'
import EditOrganization from '@/components/processing_steps/organization/EditOrganization.vue'
import BreadcrumbHeader from '@/components/processing_steps/BreadcrumbHeader.vue'
import StepNavigation from '@/components/processing_steps/StepNavigation.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { IPSBreadcrumb } from '@/types/processingSteps'
import ShowcasePageEdit from '@/components/showcase_generator/ShowcasePageEdit.vue'

@Component({
  components: {
    BreadcrumbHeader,
    StepNavigation
  }
})
export default class ProcessingSteps extends ToastMixin {
  stepData = null
  stepComponent = null
  breadcrumbs: IPSBreadcrumb[] = [
    { title: 'Home', url: '/' },
    { title: 'Processing Steps', url: '/processing-steps/' }
  ]

  navigationSteps = null

  stepKeyFromUrl = ""

  async mounted (): Promise<void> {
    const currentUrl = window.location.href
    this.stepKeyFromUrl = currentUrl.split('?step=')[1]
    this.getStepComponent()
  }

  updateBreadcrumbs (breadcrumbs: IPSBreadcrumb[]): void {
    this.breadcrumbs = breadcrumbs
  }

  get isSiteAdmin(): boolean {
    const regex = /site-admin\/(contactpersons|funding-scopes|project|organization|showcase).*\/(update|create)\//
    return regex.test(window.location.href)
  }

  get isContactPerson(): boolean {
    const regex = /(site-admin|account)\/contactpersons.*\/update\//
    const createRegex = /(site-admin|account)\/contactpersons.*\/create\//
    return regex.test(window.location.href) || createRegex.test(window.location.href)
  }

  get isFundingScope(): boolean {
    const regex = /(site-admin|account)\/funding-scopes.*\/update\//
    const createReges = /(site-admin|account)\/funding-scopes.*\/create\//
    return regex.test(window.location.href) || createReges.test(window.location.href)
  }

  get isProject(): boolean {
    const regex = /(site-admin|account)\/project.*\/update\//
    const createRegex = /(site-admin|account)\/project.*\/create\//
    return regex.test(window.location.href) || createRegex.test(window.location.href)
  }

  get isOrganization(): boolean {
    const regex = /(site-admin|account)\/organization.*\/update\//
    const createRegex = /(site-admin)\/organizations.*\/create\//
    return regex.test(window.location.href) || createRegex.test(window.location.href)
  }

  get isFundingPage(): boolean {
    const createRegex = /(site-admin|account)\/showcase\/create\/?(\?.*)?$/
    const updateRegex = /(site-admin|account)\/showcase\/[^/]+\/update\/?(\?.*)?$/
    const isFundingPage = createRegex.test(window.location.href) || updateRegex.test(window.location.href)
    return isFundingPage
  }

  async getStepComponent (): Promise<void> {
    if (this.isContactPerson) {
      this.stepComponent = EditContactPerson
    } else if (this.isFundingScope) {
      this.stepComponent = EditFundingScope
    } else if (this.isProject) {
      this.stepComponent = EditProject
    } else if (this.isOrganization) {
      this.stepComponent = EditOrganization
    } else if (this.isFundingPage) {
      this.stepComponent = ShowcasePageEdit
    }
  }
}
