
import { Component, Mixins, Prop } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import CodeStringInput from '@/components/funding_code/shared/CodeStringInput.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import { IShowcasePage } from '@/types/showcase'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import GenericTable from '@/components/GenericTable.vue'
import { isArray } from 'lodash'
@Component({
  components: {
    CodeStringInput,
    GenericTable
  }
})
export default class ByFundingCode extends Mixins(UserMixin) {
  @Prop({ required: false }) showcasePage: IShowcasePage
  $refs!: { fundingCodeRemoveTable: GenericTable }
  selectedMethod = 'funding_code'
  selectedItemsRemoveTable: TGenericObject[] = []

  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'selected', label: this.$gettext('Selected') },
    { key: 'title', label: this.$gettext('Funding program title') },
    { key: 'code_string', label: this.$gettext('Code'), sortable: true },
    { key: 'projects_count', label: this.$gettext('Projects count'), sortable: true },
    { key: 'fixed_action_col', label: this.$gettext('Actions'), sortable: true }
  ]

  get apiUrl (): string {
    return API_URLS.FUNDING_CODE.LIST
  }

  get apiUrlExtraParams () {
    return {
      showcase_page_slug: this.showcasePage.slug
    }
  }

  handleFundingCodeInput (fundingCode) {
    const formData = new FormData()
    formData.append('funding_codes', fundingCode)
    this.postData(formData)
  }

  removeFundingCode (fundingCode) {
    const formData = new FormData()
    formData.append("code_string", fundingCode.code_string)
    this.deleteCodes(formData)
  }

  removeSelectedFundingCodes () {
    const formData = new FormData()
    for (let i = 0; i < this.selectedItemsRemoveTable.length; i++) {
      formData.append('code_string', this.selectedItemsRemoveTable[i].code_string)
    }
    this.deleteCodes(formData)
  }

  removeAllFundingCodes () {
    const formData = new FormData()
    formData.append("remove_all", 'true')
    this.deleteCodes(formData)
  }

  deleteCodes (formData) {
    axios.post(API_URLS.SHOWCASE_PAGE.REMOVE_FUNDING_CODE(this.showcasePage.slug), formData).then(response => {
      this.$refs.fundingCodeRemoveTable.loadItems()
      this.$emit("project-selection-changed", response.data)
    })
  }

  postData (formData) {
    formData.append('selected_method', this.selectedMethod)
    const slug = this.showcasePage ? this.showcasePage.slug : ''
    axios.post(API_URLS.SHOWCASE_PAGE.ADD_PROJECTS(slug), formData).then(response => {
      if (this.selectedMethod === 'funding_code') {
        this.$refs.fundingCodeRemoveTable.loadItems()
        this.$emit("project-selection-changed", response.data)
      }
    })
  }

  onRowSelectedRemoveTable (selectedItems: TGenericObject): void {
    const selected = isArray(selectedItems) ? selectedItems : [selectedItems]
    this.selectedItemsRemoveTable = selected
  }
}
