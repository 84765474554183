
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CharField from '../../commons/fields/CharField.vue'
import FormContainer from '../../commons/forms/FormContainer.vue'
import SingleSelectField from "@/components/processing_steps/commons/fields/SingleSelectField.vue"
import DatePickerField from "@/components/processing_steps/commons/fields/DatePickerField.vue"
import { IPSProofForm } from '@/types/processingSteps'

@Component({
  components: {
    DatePickerField,
    SingleSelectField,
    CharField,
    FormContainer
  }
})
export default class ProofForm extends Vue {
  @Prop() form!: IPSProofForm
  @Prop() formErrors!: IPSProofForm
  @Prop({ default: () => [] }) selected: string[]
  @Prop({ default: false }) isSiteAdmin: boolean
  @Prop({ default: false }) isEditable: boolean
  selectableOptions = []
  selectedLocal = []
  allStatutoryPurposesSelected = false
  mounted() {
    this.setSelected()
  }

  get formHasDifferentLegalEntity(): boolean {
    let formValue = this.form.different_legal_entity_choice
    formValue = formValue.toString()
    return formValue === '3'
  }

  @Watch('selectedLocal')
  onSelectedLocalChanged() {
    this.$emit('update:selected', this.selectedLocal)
  }

  handleCheckboxChange(isChecked) {
    if (isChecked) {
      this.selectAllStatutoryPurposes()
      this.allStatutoryPurposesSelected = true
    } else {
      this.unselectAllStatutoryPurposes()
      this.allStatutoryPurposesSelected = false
    }
  }

  selectAllStatutoryPurposes() {
    this.selectedLocal = this.selectableOptions.map((item: { item: string }) => item.item)
  }

  get selectAllCheckboxText():string {
    if (this.allStatutoryPurposesSelected) {
      return this.$gettext('Deselect all')
    } else {
      return this.$gettext('Select all')
    }
  }

  unselectAllStatutoryPurposes() {
    this.selectedLocal = []
  }

  setSelected() {
    this.form.options.forEach((item: { text: string, value: boolean, key: string }) => {
      this.selectableOptions.push({ name: item.text, item: item.key })
      if (item.value === true) {
        this.selectedLocal.push(item.key)
        this.$emit('update:selected', this.selectedLocal)
      }
    })
  }
}
