
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IContactPersonSignUpForm, IPromoterSignupForm } from '@/types/promoterSignup'

@Component
export default class Summary extends Vue {
  @Prop() organizationForm!: IPromoterSignupForm
  @Prop() contactPersonForm!: IContactPersonSignUpForm

  acceptedTerms = false
  acceptedPrivacyPolicy = false

  get registrationSettings () {
    return {
      mustAcceptTerms: true,
      mustAcceptPrivacyPolicy: false,
      termsHtmlText: this.$gettext(`You must accept the <a href="https://www.hausdesstiftens.org/rechtliches/nutzungsbedingungen/">terms</a> and <a href="https://www.hausdesstiftens.org/rechtliches/datenschutz/">privacy policy</a> to register.`),
      privacyHtmlText: this.$gettext(`You must accept the <a href="https://www.hausdesstiftens.org/rechtliches/datenschutz/">privacy policy</a>.`)
    }
  }

  get term_of_address_display (): string {
    const notSelected = this.$gettext('Not selected')
    const textMr = this.$gettext('Mr.')
    const textMrs = this.$gettext('Mrs.')
    return this.contactPersonForm.term_of_address === 1 ? notSelected : this.contactPersonForm.term_of_address === 2 ? textMrs : textMr
  }

  gotoStep (key: 'organization' | 'contactPerson'): void {
    this.$emit('goto-step', key)
  }

  finish (): void {
    let valid = true
    if (!this.acceptedTerms && this.registrationSettings.mustAcceptTerms) {
      valid = false
      this.$emit('terms-not-accepted')
    }
    if (!this.acceptedPrivacyPolicy && this.registrationSettings.mustAcceptPrivacyPolicy) {
      valid = false
      this.$emit('privacy-not-accepted')
    }
    if (valid) {
      this.$emit('finish')
    }
  }
}
