
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IPSEditProject } from '@/types/processingSteps'
import { rawCountries } from '@/components/utils/countries'

@Component
export default class ProjectSummary extends Vue {
  @Prop() project!: IPSEditProject

  get summaryItems() {
    const city = this.project.postal_code + " " + this.project.city
    const country = rawCountries.find(c => c.value === this.project.country).text || ''
    return [
      {
        title: this.$gettext('Project title'),
        values: [this.project.title]
      },
      {
        title: this.$gettext('Project category'),
        values: [this.project.category_display]
      },
      {
        title: this.$gettext('Project funding need'),
        values: [this.project.goal.in_currency_display]
      },
      {
        title: this.$gettext('Location'),
        values: [this.project.street, this.project.additional_information, city, country]
      }
    ]
  }
}
