
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IPSEditProject, ISummaryItem } from '@/types/processingSteps'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'

@Component({
  components: {
    CompletitionLayout
  }
})
export default class AccountProjectIsLiveForm extends Vue {
  @Prop() project!: IPSEditProject
  @Prop() summaryItems!: ISummaryItem[]
}
