
import { IPSAdminEditOrganization, IPSEditOrganization } from '@/types/processingSteps'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class OrganisationSummaryMixin extends Vue {
  organization: IPSEditOrganization | IPSAdminEditOrganization | null = null

  get summaryItems () {
    return [
      this.organizationDetails,
      this.proofDetails,
      this.bankDataDetails
    ]
  }

  get organizationDetails (): { title: string, items: { title: string, values: string[]}[]} {
    return {
      title: this.$gettext('Organization'),
      items: [
        {
          title: this.$gettext('Organization\'s name'),
          values: [this.organization.title]
        },
        {
          title: this.$gettext('Email address'),
          values: [this.organization.email]
        },
        {
          title: this.$gettext('Address'),
          values: [this.organization.street, this.organization.postal_code, this.organization.city, this.organization.country]
        },
        {
          title: this.$gettext('Organization\'s phone number'),
          values: [this.organization.phone]
        },
        {
          title: this.$gettext('Organization\'s fax number'),
          values: [this.organization.fax || '-']
        },
        {
          title: this.$gettext('Organization\'s website'),
          values: [this.organization.website || '-']
        }
      ]
    }
  }

  get contactPersonDetails () {
    return {
      title: this.$gettext('Contact person'),
      items: [
        {
          title: this.$gettext('Contact person\'s name'),
          values: [this.organization.created_by.addressable_name]
        },
        {
          title: this.$gettext('Email address'),
          values: [this.organization.email_contact]
        },
        {
          title: this.$gettext('Contact person\'s phone number'),
          values: [this.organization.phone_contact]
        }
      ]
    }
  }

  get bankDataDetails () {
    return {
      title: this.$gettext('Bank account'),
      items: [
        {
          title: this.$gettext('Bank name'),
          values: [this.organization.bank_name]
        },
        {
          title: this.$gettext('Owner'),
          values: [this.organization.owner]
        },
        {
          title: this.$gettext('IBAN'),
          values: [this.organization.iban]
        },
        {
          title: this.$gettext('BIC'),
          values: [this.organization.bic]
        }
      ]
    }
  }

  get proofDetails () {
    return {
      title: this.$gettext('Information on the notice of exemption'),
      items: [
        {
          title: this.$gettext('Responsible tax office'),
          values: [this.organization.notice_of_exemption.finance_authority]
        },
        {
          title: this.$gettext('Issue date'),
          values: [this.formatDate(this.organization.notice_of_exemption.issuing_date)]
        },
        {
          title: this.$gettext('Tax number'),
          values: [this.organization.notice_of_exemption.tax_no]
        },
        {
          title: this.$gettext('Assessment period'),
          values: [this.organization.notice_of_exemption.assessment_period]
        },
        {
          title: this.$gettext('Proof type'),
          values: this.getOrganizationProofType()
        },
        {
          title: this.$gettext('Statute purposes'),
          values: this.formatStatuaryPurposes(this.organization.notice_of_exemption.statutory_purposes)
        }
      ]
    }
  }

  formatDate (date: string): string {
    return new Date(date).toLocaleDateString()
  }

  formatStatuaryPurposes (purposes: { text: string, value: boolean, key: string }[]): string[] {
    return purposes
      .filter(purpose => purpose.value)
      .map((purpose, index) => `${index + 1}. ${purpose.text}`)
  }

  getOrganizationProofType (): string[] {
    return [this.organization.notice_of_exemption.available_notice_of_exemption_types.find(type => type.value === this.organization.notice_of_exemption.notice_of_exemption_type).text]
  }
}
