
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class IbanField extends Vue {
  @Prop() value!: string
  @Prop() label!: string
  @Prop() charLimit!: number
  @Prop() readOnly!: boolean
  @Prop() required!: boolean

  get formattedValue(): string {
    return this.formatIban(this.value)
  }

  @Emit('input')
  onInput(event: Event): string {
    const inputValue = (event.target as HTMLInputElement).value.replace(/\s/g, '')
    return inputValue
  }

  formatIban(iban: string): string {
    return iban.replace(/(.{2})(.{4})/g, '$1 $2 ').trim()
  }

  @Emit('blur')
  onBlur(event: Event): string {
    return (event.target as HTMLInputElement).value
  }

  @Emit('focus')
  onFocus(event: Event): string {
    return (event.target as HTMLInputElement).value
  }
}
