
import { Component, Vue, Prop } from 'vue-property-decorator'
import FormContainer from '../../commons/forms/FormContainer.vue'
import TextField from '../../commons/fields/TextField.vue'
import { IPSOrgaDescriptionForm } from '@/types/processingSteps'

@Component({
  components: {
    FormContainer,
    TextField
  }
})
export default class OrganisationDescriptionForm extends Vue {
  @Prop() form!: IPSOrgaDescriptionForm
  @Prop() formErrors!: IPSOrgaDescriptionForm
  @Prop({ default: false }) readOnly!: boolean
}
