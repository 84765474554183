

import { Mixins, Component, Prop } from 'vue-property-decorator'
import FileUploader from '@/components/file_uploader/FileUploader.vue'
import UserMixin from '@/mixins/UserMixin'
import {
  IShowcasePage,
  IShowcasePageForm,
  IShowcasePageOrganizationSelect,
  IShowcasePageTemplateSelect
} from '@/types/showcase'
import axios from 'axios'
import { addContextToUrl, API_URLS, SITE_URLS } from '@/utils/helpers'
import ColorInput from './ColorInput.vue'
import ShowcaseProjectSelect from './ShowcaseProjectSelect.vue'
import NavigationLinks from '@/components/navigation_link/NavigationLinks.vue'
import ProjectLinkButton from '../buttons/ProjectLinkButton.vue'
import DisplayedProjectsOverview from './project_select/DisplayedProjectsOverview.vue'
import CharField from '@/components/processing_steps/commons/fields/CharField.vue'
import TextFieldEditor from '../processing_steps/commons/fields/TextFieldEditor.vue'

@Component({
  components: {
    FileUploader,
    ColorInput,
    ShowcaseProjectSelect,
    NavigationLinks,
    ProjectLinkButton,
    DisplayedProjectsOverview,
    CharField,
    TextFieldEditor
  },
  mixins: [UserMixin]
})
export default class ShowcasePageForm extends Mixins(UserMixin) {
  @Prop({ required: true }) showcaseForm: IShowcasePageForm
  @Prop({ required: true }) formErrors
  @Prop({ required: false }) showcasePage: IShowcasePage
  @Prop() category: string
  @Prop({ default: 10 }) textMinLength: number
  @Prop({ default: 1000 }) textMaxLength: number

  availableOrganizations: IShowcasePageOrganizationSelect[] = []
  availableTemplates: IShowcasePageTemplateSelect[] = []
  selectedOrganization = null
  existingImages = []
  existingLogo = []
  existingDocuments = []
  fullName: string
  albumInfo = null
  reloadDisplayedProjectsTable = false

  get photoUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.photo_album_slug) : null
  }

  get logoUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.logo_album_slug) : null
  }

  get documentUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.document_album_slug) : null
  }

  get showProjectSelect () {
    return true
  }

  get photoAlbumSlug () {
    return this.showcasePage ? this.showcasePage.photo_album_slug : null
  }

  get previewHref () {
    return SITE_URLS.SHOWCASE.PREVIEW(this.showcasePage.slug, this.showcasePage.preview_token)
  }

  get publicHref () {
    return SITE_URLS.SHOWCASE.PUBLIC(this.showcasePage.slug)
  }

  get organizationOptions () {
    const resultArray = []
    this.availableOrganizations.forEach((item) => {
      const option = {
        text: item.title,
        value: item.slug
      }
      resultArray.push(option)
    })
    resultArray.sort((a, b) => a.text.localeCompare(b.text))
    return resultArray
  }

  get templateOptions () {
    const resultArray = []
    this.availableTemplates.forEach((item) => {
      const option = {
        text: item.title,
        value: item.id
      }
      resultArray.push(option)
    })
    resultArray.sort((a, b) => a.title.localeCompare(b.title))
    return resultArray
  }

  get userIsOrgaUser () {
    return this.userProfile?.usertype?.choice === 4
  }

  get showcaseFormTextState () {
    const textLength = this.showcaseForm.text.length
    if (textLength < 1) {
      return null
    }
    return textLength <= this.textMaxLength && textLength >= this.textMinLength
  }

  mounted () {
    this.fetchAvailableOrganization()
    this.fetchAvailableTemplates()
    if (this.showcasePage) {
      this.selectedOrganization = this.showcasePage.organization
      this.existingImages = this.showcasePage.photos
      this.existingLogo = this.showcasePage.logos
      this.existingDocuments = this.showcasePage.documents
    }
    this.fetchUserProfile()
  }

  fieldHasError (fieldName: string): boolean {
    return !!this.formErrors[fieldName]
  }

  fetchAvailableTemplates () {
    axios.get(API_URLS.SHOWCASE_PAGE.AVAILABLE_TEMPLATES(this.category)).then((response) => {
      this.availableTemplates = response.data
    })
  }

  fetchAvailableOrganization () {
    axios.get(addContextToUrl(
      API_URLS.ORGANIZATIONS.LIST, { extraParams: { review_status: '2', ...this.userIsOrgaUser ? { own: this.userIsOrgaUser } : {} } })
    ).then((response) => {
      this.availableOrganizations = response.data.results
    })
  }

  onFileUploaded (files) {
    this.$emit("file-uploaded", files)
  }

  onFileDeleted (image) {
    this.existingImages = this.existingImages.filter(i => i.slug !== image.slug)
  }

  onFilesUpdated (files) {
    this.existingImages = files
  }

  onLogoUploaded (file) {
    this.$emit("logo-uploaded", file)
  }

  onLogoDeleted () {
    this.existingLogo = null
  }

  onLogoUpdated (file) {
    this.existingLogo = file
  }

  onDocumentUploaded (file) {
    this.$emit("document-uploaded", file)
  }

  onDocumentDeleted () {
    this.existingDocuments = null
  }

  onDocumentUpdated (file) {
    this.existingDocuments = file
  }

  onAlbumInfoFetched (albumInfo) {
    this.albumInfo = albumInfo
  }

  onColorChanged (newVal) {
    this.showcaseForm.primary_color = newVal
  }

  onContactPersonSelected (contactPerson) {
    this.$emit("contact-person-selected", contactPerson)
  }

  onOrganizationChanged (orgaSlug: string): void {
    // todo dont fetch the whole orga
    let url = API_URLS.ORGANIZATIONS.RETRIEVE(orgaSlug)
    if (this.userIsOrgaUser) {
      url += '?own=true'
    }
    axios.get(url).then(response => {
      const color = response.data.color
      if (color && (!this.showcasePage || (this.showcasePage && !this.showcasePage.primary_color))) {
        this.showcaseForm.primary_color = color
      }
    })
  }

  onProjectSelectionChanged (responseData) {
    this.reloadDisplayedProjectsTable = !this.reloadDisplayedProjectsTable
    this.$emit("project-selection-changed", responseData)
  }

  onContactPersonSelectionChanged (responseData) {
    this.$emit("contact-person-selection-changed", responseData)
  }

  onLinkCreated (link) {
    this.$emit('link-created', link)
  }
}
