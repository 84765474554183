
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoBanner from '@/components/processing_steps/banners/InfoBanner.vue'
import axios from 'axios'

@Component({
  components: {
    InfoBanner
  }
})
export default class OrgaNotSubmittedBanner extends Vue {
  @Prop({ default: false }) isSiteAdmin!: boolean

  showInfoBanner = false

  orgaSlug = ""

  async mounted (): Promise<void> {
    if (!this.orgaSlug) {
      await this.fetchOrgaSlug()
    }
    this.setShowInfoBanner()
  }

  async fetchOrgaSlug () {
    if (this.isSiteAdmin) return
    await axios.get("/api/v4/organizations/?own=true").then(response => {
      this.orgaSlug = response.data.results[0].slug
    })
  }

  setShowInfoBanner (): void {
    if (this.isSiteAdmin) return
    let url = "/api/v4/processing-steps/show-info-banner/"
    if (this.orgaSlug) {
      url += `?orga_slug=${this.orgaSlug}/`
    }
    axios.get(url).then((response) => {
      this.showInfoBanner = response.data.show_orga_not_submitted_banner
      this.$emit('fetched', this.showInfoBanner)
    })
  }

  closeInfoModal () {
    this.showInfoBanner = false
    this.$emit('close-info-modal')
    window.location.href = `/account/organization/${this.orgaSlug}/update/`
  }
}

