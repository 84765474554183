
import { Component, Mixins, Prop } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import CodeStringInput from '@/components/funding_code/shared/CodeStringInput.vue'
import { API_URLS } from '@/utils/helpers'
import { IShowcasePage } from '@/types/showcase'
import GenericTable from '@/components/GenericTable.vue'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import SelectTable from './SelectTable.vue'
import CopyURLButton from '@/components/buttons/CopyURLButton.vue'
@Component({
  components: {
    CodeStringInput,
    GenericTable,
    SelectTable,
    CopyURLButton
  }
})
export default class ByProject extends Mixins(UserMixin) {
  @Prop({ required: false }) showcasePage: IShowcasePage
  selectedProjects: TGenericObject[] = []
  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'title', label: this.$gettext('Page title'), sortable: true },
    { key: 'organization.title', label: this.$gettext('Organization') },
    { key: 'fixed_action_col', label: this.$gettext('Actions') }
  ]

  get showcaseProjects () {
    if (!this.showcasePage) {
      return []
    } else {
      return this.showcasePage.projects
    }
  }

  get initialArrayNotSelected () {
    return this.showcaseProjects.filter(item => !this.isShowcaseProject(item))
  }

  get initialArraySelected () {
    return this.showcaseProjects.filter(item => this.isShowcaseProject(item))
  }

  get totalProjectsCount () {
    return this.showcasePage ? this.showcasePage.projects_count : 0
  }

  isShowcaseProject (project) {
    if (!this.showcasePage) {
      return false
    }
    return this.showcasePage.projects.includes(project.slug)
  }

  get apiUrl () {
    return API_URLS.PROJECTS.LIST
  }

  get userIsOrgaUser () {
    return this.userProfile?.usertype?.choice === 4
  }

  get onlySelected (): TGenericObject {
    return {
      ...this.userIsOrgaUser ? { own: this.userIsOrgaUser } : {},
      review_status: '2',
      status: '2',
      showcase_page_slug: this.showcasePage.slug
    }
  }

  get onlyNotSelected (): TGenericObject {
    return {
      ...this.userIsOrgaUser ? { own: this.userIsOrgaUser } : {},
      review_status: '2',
      status: '2',
      showcase_page_slug: this.showcasePage.slug,
      showcase_exclude_own: 'true'
    }
  }

  onItemsRemoved (project) {
    this.$emit("projects-removed", project)
  }

  onItemsSelected (projects) {
    this.$emit("projects-selected", projects)
  }
}
