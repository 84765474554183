
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { IPhoto } from '@/types/photos'
import VideoComponent from '@/components/explore/VideoComponent.vue'
import { IframeHTMLAttributes } from 'vue/types/jsx'

@Component({
  components: {
    Splide,
    SplideSlide,
    VideoComponent
  }
})
export default class ImageSlider extends Vue {
  @Ref('videoComponent') videoComponents!: VideoComponent[]
  @Ref() readonly mainSlider: SplideSlide
  @Ref() readonly thumbnailSlider: SplideSlide
  @Prop() photos!: IPhoto[]
  @Prop({ default: false }) thumbnails!: boolean
  @Prop({ default: false }) isFullWidthSlider!: boolean
  @Prop({ default: false }) noWrapper!: boolean

  activeSlideIndex = 0
  mainOptions = {
    type: 'slide',
    rewind: true,
    perPage: 1,
    perMove: 1,
    gap: '1rem',
    pagination: false,
    center: true,
    breakpoints: {
      640: {
        height: 300,
        fixedHeight: 300
      }
    },
    arrows: this.showSplideArrow()
  }

  thumbnailOptions = {
    type: 'slide',
    rewind: true,
    gap: '1rem',
    pagination: false,
    fixedWidth: 110,
    fixedHeight: 70,
    cover: true,
    focus: 'center',
    isNavigation: true,
    updateOnMove: true,
    breakpoints: {
      640: {
        fixedHeight: 300
      }
    }
  }

  get onlyOnePhoto () {
    return this.photos.length === 1
  }

  showSplideArrow (): boolean {
    return this.photos.length > 1
  }

  showSlideDescription (slide: IPhoto): boolean {
    return !!(slide.description || slide.main_description)
  }

  splideMoved (slide: SplideSlide, index: number) {
    if (!this.videoComponents) { return }
    const videoComponent = this.videoComponents[this.activeSlideIndex]
    if (videoComponent) {
      videoComponent.removeMessageListener()
      videoComponent.showVideo = false
    }
    this.activeSlideIndex = index
    this.resetVideos()
  }

  resetVideos (): void {
    this.mainSlider.$el.querySelectorAll('iframe').forEach((IFrame: IframeHTMLAttributes) => {
      const iframeSrc = IFrame.src
      IFrame.src = iframeSrc
    })
  }

  mounted () {
    if (this.thumbnails) {
      const thumbnailSplide = this.thumbnailSlider?.splide
      if (thumbnailSplide) {
        this.mainSlider?.sync(thumbnailSplide)
      }
    }
  }
}
