
import { Component, Vue, Prop } from 'vue-property-decorator'
import CharField from '../commons/fields/CharField.vue'
import TextField from '../commons/fields/TextField.vue'
import DocumentField from '../commons/fields/DocumentField.vue'
import { API_URLS } from '@/utils/helpers'
import SingleSelectField from '../commons/fields/SingleSelectField.vue'
import FormContainer from '../commons/forms/FormContainer.vue'
import CheckboxField from '../commons/fields/CheckboxField.vue'
import HtmlField from '../commons/fields/HtmlField.vue'
import IntegerField from '../commons/fields/IntegerField.vue'
import DatePickerField from "@/components/processing_steps/commons/fields/DatePickerField.vue"
import { IPSAdditionalField, IPSAddtitionalProcessingStep, IProcessingStep } from '@/types/processingSteps'

@Component({
  components: {
    DatePickerField,
    CharField,
    TextField,
    DocumentField,
    SingleSelectField,
    FormContainer,
    CheckboxField,
    HtmlField,
    IntegerField
  }
})
export default class EditAdditionalStep extends Vue {
  @Prop({ required: true }) step!: IPSAddtitionalProcessingStep
  @Prop({ required: true }) additionalStep!: IPSAddtitionalProcessingStep
  @Prop({ required: true }) activeStep!: IPSAddtitionalProcessingStep

  isCharField (additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 1
  }

  isTextField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 2
  }

  isDocumentField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 3
  }

  isCheckboxField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 4
  }

  isHtmlField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 6
  }

  isIntegerField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 7
  }

  isDateField(additonalField: IPSAdditionalField) {
    return additonalField.input_type.value === 10
  }

  getUploadUrl(additonalField: IPSAdditionalField) {
    return API_URLS.PHOTOS.UPLOAD_PHOTO(additonalField.value.value.slug)
  }

  handleFilesUploaded() {
    // todo use argument photos: IPhoto[] to set existingPhotos - one for each field with type documentfield and cancel the emit then
    this.$emit('files-uloaded')
  }

  isSelectField (additonalField: IPSAdditionalField): boolean {
    return additonalField.input_type.value === 5
  }

  stepClicked(step: IProcessingStep) {
    this.$emit('step-clicked', step)
  }

  saveBtnClicked() {
    this.$emit('save-btn-clicked', this.activeStep)
  }

  nextBtnClicked() {
    this.$emit('next-btn-clicked', this.activeStep)
  }

  saveAndNextBtnClicked() {
    this.$emit('save-and-next-btn-clicked', this.activeStep)
  }

  prevButtonClicked () {
    this.$emit('prev-btn-clicked', this.activeStep)
  }
}

