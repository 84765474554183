

import PreviewButton from '@/components/buttons/PreviewButton.vue'
import ProjectLinkButton from '@/components/buttons/ProjectLinkButton.vue'
import FileUploader from '@/components/file_uploader/FileUploader.vue'
import ContactPersonSelect from '@/components/processing_steps/commons/forms/ContactPersonSelect.vue'
import NavigationLinks from '@/components/navigation_link/NavigationLinks.vue'
import { IResultReport, IResultReportForm } from '@/types/foerderApp'
import { API_URLS } from '@/utils/helpers'
import axios from 'axios'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ToastMixin from '@/mixins/ToastMixin'
import FormContainer from '../../commons/forms/FormContainer.vue'
import CharField from "@/components/processing_steps/commons/fields/CharField.vue"
import TextFieldEditor from '../../commons/fields/TextFieldEditor.vue'

@Component({
  components: {
    NavigationLinks,
    FileUploader,
    PreviewButton,
    ProjectLinkButton,
    ContactPersonSelect,
    FormContainer,
    CharField,
    TextFieldEditor
  }
})
export default class ResultReportForm extends ToastMixin {
  @Prop() projectSlug: string
  resultReport: IResultReport | null = null
  resultReportForm: IResultReportForm = {
    title: '',
    text: '',
    links: [],
    is_public: false
  }

  @Watch('resultReportForm', { deep: true })
  onResultReportFormChanged(newVal: IResultReportForm) {
    this.$emit('form-updated', newVal)
  }

  async mounted (): Promise<void> {
    this.fetchResultReport()
  }

  get photoUploadUrl (): string {
    return this.resultReport ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.resultReport.photo_album_slug) : null
  }

  get fileUploadUrl (): string {
    return this.resultReport ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.resultReport.file_album_slug) : null
  }

  get resultReportPreviewUrl (): string {
    return this.projectSlug ? `/project/${this.projectSlug}/public-result-report/?preview_token=${this.resultReport.preview_token}` : null
  }

  get resultReportPublicUrl (): string {
    return this.projectSlug ? `/project/${this.projectSlug}/public-result-report/` : null
  }

  setResultReportForm (resultReport): void {
    this.resultReportForm = {
      title: resultReport.title,
      text: resultReport.text,
      links: resultReport.links,
      is_public: resultReport.is_public
    }
  }

  async fetchResultReport (): Promise<void> {
    axios.get(API_URLS.RESULT_REPORT.FOR_PROJECT(this.projectSlug) + '&own=true')
      .then(response => {
        this.resultReport = response.data
        this.setResultReportForm(this.resultReport)
        this.$emit("result-report-fetched", this.resultReport.slug)
      })
  }

  onPhotosUploaded (data): void {
    this.resultReport.photos = data
  }

  onFilesUploaded (data): void {
    this.resultReport.files = data
  }

  onPhotosDeleted (photo): void {
    this.resultReport.photos = this.resultReport.photos.filter(p => p.slug !== photo.slug)
  }

  onFilesDeleted (photo): void {
    this.resultReport.files = this.resultReport.files.filter(p => p.slug !== photo.slug)
  }
}
