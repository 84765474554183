
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconAndText extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: false }) text!: string
  @Prop({ required: false }) iconType!: 'orgaInReview' | 'orgaIsLive' | 'null'

  get iconClass (): string | null {
    if (this.iconType === 'null') return null
    if (!this.iconType) return `fa-check`
    if (this.iconType === 'orgaInReview') return 'fa-clock'
    if (this.iconType === 'orgaIsLive') return 'fa-check'
  }
}
