
import axios from 'axios'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import CharField from "@/components/processing_steps/commons/fields/CharField.vue"
import EmailInput from "@/components/processing_steps/commons/fields/EmailInput.vue"

@Component({
  components: { EmailInput, CharField }
})
export default class InviteAdminModal extends Vue {
  @Prop({ default: false }) readonly visible!: boolean
  @Prop() organizationSlug!: string
  username = ''
  email = ''
  usernameError = ''
  emailError = ''

  @Emit('update:visible')
  closeModal() {
    return false
  }

  inviteAdmin() {
    const postData = {
      username: this.username,
      email: this.email
    }
    const inviteUrl = '/api/v4/users/organisation/' + this.organizationSlug + '/invite/'// Die URL muss entsprechend der tatsächlichen API angepasst werden
    axios.post(inviteUrl, postData)
      .then(response => {
        this.$emit('success', response)
        this.closeModal()
      })
      .catch(error => {
        let usernameError = ''
        let emailError = ''
        if (error.response.data.username) {
          usernameError = error.response.data.username[0]
        }
        if (error.response.data.email) {
          emailError = error.response.data.email[0]
        }
        this.usernameError = usernameError
        this.emailError = emailError
        this.$emit('error', error)
      })
    return postData
  }
}
