
import { Component, Mixins, Prop } from 'vue-property-decorator'
import axios from 'axios'
import StepNavigation from '@/components/processing_steps/StepNavigation.vue'
import FormButtons from '@/components/processing_steps/FormButtons.vue'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import ContactPersonForm from '@/components/processing_steps/contact_person/ContactPersonForm.vue'
import ContactPersonPhoto from '@/components/processing_steps/contact_person/ContactPersonPhoto.vue'
import StepContainer from '../commons/StepContainer.vue'
import ProcessingStepMixin from '../ProcessingStepMixin.vue'
import { IPhoto } from '@/types/photos'
import ValidationMixin from '../ValidationMixin.vue'
import FieldValidationMixin from '../FieldValidationMixin.vue'
import { IEditContactPerson, IPSContactPersonBaseForm, IProcessingStep, IPSSelectOption } from '@/types/processingSteps'
import { API_URLS } from '@/utils/helpers'

@Component({
  components: {
    StepNavigation,
    ContactPersonForm,
    ContactPersonPhoto,
    FormTitle,
    FormButtons,
    StepContainer
  }
})
export default class EditContactPerson extends Mixins(ProcessingStepMixin, ValidationMixin, FieldValidationMixin) {
  @Prop() apiUrl: string
  contactPerson: IEditContactPerson | null = null
  existingPhotos: IPhoto[] = []
  availableOrganizations: IPSSelectOption[] = []

  firstStep: IProcessingStep = {
    key: "basic",
    title: "Basisinformationen",
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: false,
      saveAndNext: false
    }
  }

  secondStep: IProcessingStep = {
    key: "photos",
    title: "Photos",
    completed: false,
    required: false,
    active: false,
    buttons: {
      save: false,
      saveAndNext: false
    }
  }

  contactPersonForm: IPSContactPersonBaseForm = {
    term_of_address: "1",
    academic_title: "",
    first_name: "",
    last_name: "",
    email: "",
    vita: "",
    job_title: "",
    street: "",
    city: "",
    postal_code: "",
    phone: "",
    mobile: "",
    fax: "",
    public_contact_info: "",
    related_organization: ""
  }

  contactPersonFormErrors: IPSContactPersonBaseForm = {
    term_of_address: "",
    academic_title: "",
    first_name: "",
    last_name: "",
    email: "",
    vita: "",
    job_title: "",
    street: "",
    city: "",
    postal_code: "",
    phone: "",
    mobile: "",
    fax: "",
    public_contact_info: "",
    related_organization: ""
  }

  isCreate = false

  async mounted (): Promise<void> {
    this.isCreate = !this.apiUrl
    if (this.isCreate) {
      this.firstStep.buttons.save = false
    }
    this.steps = [this.firstStep, this.secondStep]
    await this.fetchContactPerson()
    await this.fetchUsersOrganizations()
    this.setActiveStep(null)
    this.setContactPersonForm()
    this.validateSteps()
    this.updateBreadCrumbs()
  }

  updateBreadCrumbs() {
    this.$emit("breadcrumbs-set", [])
  }

  validateSteps(): void {
    this.steps[0].completed = this.basicInfoCompleted
    this.steps[1].completed = this.contactPerson?.photo ? this.contactPerson.photo.length > 0 : false
  }

  async fetchContactPerson (): Promise<void> {
    if (this.isCreate) return
    await axios.get(this.apiUrl).then((response) => {
      this.contactPerson = response.data
      this.existingPhotos = this.contactPerson.photo
      this.validateSteps()
    })
  }

  setContactPersonForm (): void {
    this.availableOrganizations = this.usersOrganizations
    if (this.contactPerson === null) {
      this.contactPersonForm.related_organization = this.availableOrganizations && this.availableOrganizations.length > 0 ? this.availableOrganizations[0].value : ""
      return
    }
    this.contactPersonForm.term_of_address = this.contactPerson.term_of_address
    this.contactPersonForm.academic_title = this.contactPerson.academic_title
    this.contactPersonForm.first_name = this.contactPerson.first_name
    this.contactPersonForm.last_name = this.contactPerson.last_name
    this.contactPersonForm.email = this.contactPerson.email
    this.contactPersonForm.vita = this.contactPerson.vita
    this.contactPersonForm.job_title = this.contactPerson.job_title
    this.contactPersonForm.street = this.contactPerson.street
    this.contactPersonForm.city = this.contactPerson.city
    this.contactPersonForm.postal_code = this.contactPerson.postal_code
    this.contactPersonForm.phone = this.contactPerson.phone
    this.contactPersonForm.mobile = this.contactPerson.mobile
    this.contactPersonForm.fax = this.contactPerson.fax
    this.contactPersonForm.public_contact_info = this.contactPerson.public_contact_info
    this.contactPersonForm.related_organization = this.contactPerson.related_organization.slug
  }

  nextBtnClicked (): void {
    this.gotoNextStepOrFinish(this.steps, this.activeStep)
  }

  previousBtnClicked (currentStep: IProcessingStep): void {
    this.gotoPreviousStep(this.steps, currentStep)
  }

  stepBtnClicked (step: IProcessingStep): void {
    if (!this.stepValidated(this.activeStep) && !this.isPreviousStep(step, this.activeStep, this.steps)) {
      return
    }
    this.setActiveStep(step)
  }

  handlePhotosChanged (): void {
    this.fetchContactPerson()
  }

  get basicInfoCompleted (): boolean {
    if (!this.isValidEmail(this.contactPersonForm.email) && !this.isCreate) {
      return false
    }
    return true
  }

  stepValidated (step: IProcessingStep): boolean {
    if (step.key === "basic") {
      const valid = this.basicInfoCompleted
      if (!valid) {
        this.showError({ title: this.$gettext("Error"), message: this.$gettext("Please fill out all required fields.") })
        return false
      }
    } else if (step.key === "photos") {
      const valid = this.contactPerson?.photo ? this.contactPerson.photo.length > 0 : false
      if (!valid) {
        this.showInformation({ title: this.$gettext("Proposal"), message: this.$gettext("Please add at least one photo.") })
        return false
      }
    }
    return true
  }

  getUpdateUrl (contactPersonSlug): string {
    if (!contactPersonSlug) {
      return ""
    }
    if (this.isSiteAdmin) {
      return "/site-admin/contactpersons/" + contactPersonSlug + "/update/"
    } else {
      return "/account/contactpersons/" + contactPersonSlug + "/update/"
    }
  }

  async onFileInputClicked (): Promise<void> {
    await this.saveBaseInformation(this.activeStep, false)
  }

  async saveBaseInformation (currentStep: IProcessingStep | null, redirect: boolean): Promise<boolean> {
    let saved = false
    if (!this.stepValidated(currentStep)) {
      if (!this.isValidEmail(this.contactPersonForm.email)) {
        this.contactPersonFormErrors.email = this.$gettext("Please enter a valid email address.")
      }
      return
    }
    const nextStep = currentStep?.key === "basic" ? this.secondStep : null
    const requestData = {
      ...this.contactPersonForm,
      related_organization_slug: this.contactPersonForm.related_organization
    }
    if (!this.isCreate) {
      const updateUrl = API_URLS.CONTACT_PERSON.RETRIEVE(this.contactPerson.slug)
      await axios.put(updateUrl, requestData).then(() => {
        this.showSuccessMsg(null)
        if (redirect) {
          this.gotoNextStepOrFinish(this.steps, this.activeStep)
        }
        saved = true
      }).catch(() => {
        this.showError(null)
      })
    } else {
      await axios.post(API_URLS.CONTACT_PERSON.LIST, requestData).then(async (response) => {
        if (nextStep) {
          this.isCreate = false
          // todo, when everything is finished, change api to return the contactPerson in the same format as in fetchContactPerson
          const cpSlug = response.data.redirect_to.split("/")[3]
          this.showSuccessMsg(null)
          const newApiUrl = "/api/v4/contactpersons/" + cpSlug + "/"
          this.$emit('update-api-url', newApiUrl)
          await axios.get(newApiUrl).then((response) => {
            this.contactPerson = response.data
            this.existingPhotos = this.contactPerson.photo
            this.validateSteps()
            this.gotoNextStepOrFinish(this.steps, currentStep)
          })
          saved = true
        }
      }).catch((error) => {
        this.showError(null)
        const errors = error.response.data
        Object.keys(errors).forEach((key) => {
          const msg = errors[key]
          this.contactPersonFormErrors[key] = Array.isArray(msg) ? msg[0] : msg
        })
      })
    }
    this.$emit("created-or-updated", saved)
    return saved
  }

  finish (): void {
    this.$emit("finish")
  }
}
