
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TextAndButton extends Vue {
  @Prop() text!: string
  @Prop() btnText!: string

  handleClick() {
    this.$emit('clicked')
  }
}
