
import { IPSSelectOption } from '@/types/processingSteps'
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
import SearchField from './SearchField.vue'
import HdsCheckboxCross from './HdsCheckboxCross.vue'

@Component({
  components: {
    SearchField,
    HdsCheckboxCross
  }
})
export default class MultiSelectField extends Vue {
  @Prop({ default: () => [] }) value!: (string | number)[]
  @Prop({ default: '' }) error!: string
  @Prop({ default: () => [] }) options!: IPSSelectOption[]
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly!: boolean
  @Prop({ default: false }) isDisabled!: boolean
  @Prop({ default: '' }) searchPlaceholder!: string
  @Prop({ default: false }) small!: boolean

  state: boolean | null = null

  search = ''
  selectedValues: (string | number)[] = []

  get filteredOptions() {
    return this.options.filter(option =>
      option.text.toLowerCase().includes(this.search.toLowerCase())
    ).sort((a, b) => a.text.localeCompare(b.text))
  }

  mounted () {
    this.selectedValues = this.value
  }

  handleSearchChange(value: string) {
    this.search = value
  }

  toggleSelection(value: string | number) {
    const index = this.selectedValues.indexOf(value)
    if (index === -1) {
      this.selectedValues.push(value)
    } else {
      this.selectedValues.splice(index, 1)
    }
    this.updateValue(this.selectedValues)
  }

  @Emit('input')
  updateValue(value: (string | number)[]) {
    this.selectedValues = value
    return this.selectedValues
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }
}
