

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SingleSelectField from '../../commons/fields/SingleSelectField.vue'

@Component({
  components: {
    SingleSelectField
  }
})
export default class SelectFundingScope extends Vue {
  @Prop() fundingScopeSlug!: string
  @Prop() availableFundingScopes!: string[]
  @Prop() fundingScopeFormErrors!: { funding_scope_slug: string }
  selectedFundingScope = ''

  mounted () {
    this.selectedFundingScope = this.fundingScopeSlug
  }

  @Watch('fundingScopeSlug')
  onFundingScopeSlugChanged(newVal: string) {
    this.$emit('update:fundingScopeSlug', newVal)
  }
}
