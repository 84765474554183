
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
@Component
export default class DatePickerField extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly!: boolean
  @Prop({ default: false }) isDisabled!: boolean

  state: boolean | null = null

  @Emit('input')
  updateValue(newValue: string) {
    return newValue
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }
}
