
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormTitle from '@/components/processing_steps/FormTitle.vue'
import CharField from '@/components/processing_steps/commons/fields/CharField.vue'
import UrlField from '@/components/processing_steps/commons/fields/UrlField.vue'
import TextField from "@/components/processing_steps/commons/fields/TextField.vue"
import SingleSelectField from "@/components/processing_steps/commons/fields/SingleSelectField.vue"
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { IPSFundingScopeBaseForm, IPSFundingScopeBaseFormErrors, IPSSelectOption } from '@/types/processingSteps'

@Component({
  components: {
    FormContainer,
    SingleSelectField,
    TextField,
    FormTitle,
    CharField,
    UrlField
  }
})
export default class BaseInformationForm extends Vue {
  @Prop() fundingScopeForm!: IPSFundingScopeBaseForm
  @Prop() fundingScopeFormErrors!: IPSFundingScopeBaseFormErrors
  @Prop() availableOrganizations!: IPSSelectOption[]
}
