
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TermOfAddressField extends Vue {
  @Prop() value!: string
  @Prop() error!: string
  @Prop() customClass!: string

  options = [
    {
      text: this.$gettext("Term of address"),
      value: 1
    },
    {
      text: this.$gettext("Mrs."),
      value: 2
    },
    {
      text: this.$gettext("Mr."),
      value: 3
    }
  ]

  get state (): boolean {
    return !this.error
  }
}
