
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
@Component
export default class IntegerField extends Vue {
  @Prop({ default: null }) value!: number | null
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: false }) readOnly!: boolean

  state: boolean | null = null
  displayError = ''

  @Emit('input')
  updateValue(event: Event) {
    const newValue = (event.target as HTMLInputElement).value
    const parsedValue = parseInt(newValue, 10)
    if (isNaN(parsedValue) || newValue.includes('.')) {
      this.displayError = this.$gettext('Please enter a valid integer')
      this.state = false
      return null
    } else {
      this.displayError = ''
      this.state = null
      return parsedValue
    }
  }

  onBlur() {
    this.$emit('blur')
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
    this.displayError = this.error
  }
}
