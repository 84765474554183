
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormTitle from '../../FormTitle.vue'
import SingleSelectField from '../../commons/fields/SingleSelectField.vue'
import TextField from '../../commons/fields/TextField.vue'
import FormContainer from '../../commons/forms/FormContainer.vue'
import CharField from '../../commons/fields/CharField.vue'
import { IPSProjectBaseInformationForm, IPSSelectOption } from '@/types/processingSteps'

@Component({
  components: {
    SingleSelectField,
    TextField,
    FormTitle,
    FormContainer,
    CharField
  }
})
export default class ProjectBaseInformationForm extends Vue {
  @Prop() projectBaseInformationForm!: IPSProjectBaseInformationForm
  @Prop() availableOrganizations!: IPSSelectOption[]
  @Prop() availableProjectCategories!: IPSSelectOption[]
  @Prop() formErrors!: IPSProjectBaseInformationForm
  @Prop({ default: true }) projectIsDraft!: boolean
}
