
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator'
@Component
export default class HtmlField extends Vue {
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) error!: string
  @Prop({ default: null }) label!: string
  @Prop({ default: false }) required!: boolean

  state: boolean | null = null

  @Emit('input')
  updateValue(newValue: InputEvent) {
    const input = newValue.target as HTMLInputElement
    return input.value
  }

  @Watch('error')
  onPropertyChanged(value: string) {
    this.state = value ? false : null
  }
}
