
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { IPSEditProject } from '@/types/processingSteps'
import { Component, Vue, Prop } from 'vue-property-decorator'
import IconAndText from '@/components/processing_steps/banners/IconAndText.vue'
import ProjectSummary from '@/components/processing_steps/project/forms/completition_forms/ProjectSummary.vue'

@Component({
  components: {
    FormContainer,
    IconAndText,
    ProjectSummary
  }
})
export default class AccountProjectWaitingForReview extends Vue {
  @Prop() project: IPSEditProject
}
