
import { IPSEditFundingScope } from '@/types/processingSteps'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class FundingScopeSummaryMixin extends Vue {
  fundingScope: IPSEditFundingScope | null = null

  get summaryItems () {
    return [
      this.organizationDetails,
      [{ title: "", items: [] }],
      this.bankDataDetails
    ]
  }

  get organizationDetails (): { title: string, items: { title: string, values: string[]}[]} {
    return {
      title: this.$gettext('Funding scope'),
      items: [
        {
          title: this.$gettext('Name'),
          values: [this.fundingScope.name]
        },
        {
          title: this.$gettext('Organization\'s name'),
          values: [this.fundingScope.related_organization.title]
        },
        {
          title: this.$gettext('Address'),
          values: [this.fundingScope.street, this.fundingScope.postal_code, this.fundingScope.city, this.fundingScope.country]
        }
      ]
    }
  }

  get contactPersonDetails () {
    return {
      title: this.$gettext('Contact person'),
      items: [
        {
          title: this.$gettext('Contact person\'s name'),
          values: [this.fundingScope.contact_person?.addressable_name || '-']
        }
      ]
    }
  }

  get bankDataDetails () {
    return {
      title: this.$gettext('Bank account'),
      items: [
        {
          title: this.$gettext('Bank name'),
          values: [this.fundingScope.bank_name]
        },
        {
          title: this.$gettext('Owner'),
          values: [this.fundingScope.owner]
        },
        {
          title: this.$gettext('IBAN'),
          values: [this.fundingScope.iban]
        },
        {
          title: this.$gettext('BIC'),
          values: [this.fundingScope.bic]
        }
      ]
    }
  }

  formatDate (date: string): string {
    return new Date(date).toLocaleDateString()
  }

  formatStatuaryPurposes (purposes: { text: string, value: boolean, key: string }[]): string[] {
    return purposes
      .filter(purpose => purpose.value)
      .map((purpose, index) => `${index + 1}. ${purpose.text}`)
  }
}
