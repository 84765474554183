
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormContainer from '../../commons/forms/FormContainer.vue'
import CharField from '../../commons/fields/CharField.vue'
import TextFieldEditor from '../../commons/fields/TextFieldEditor.vue'
import { IPSWhatWeNeedForm, IPSWhatWeNeedFormErrors } from '@/types/processingSteps'
import DocumentField from '@/components/processing_steps/commons/fields/DocumentField.vue'
import { IPhoto } from '@/types/photos'
import NavigationLinks from '@/components/navigation_link/NavigationLinks.vue'
import { INavigationLink } from "@/types/foerderApp"

@Component({
  components: {
    FormContainer,
    CharField,
    TextFieldEditor,
    DocumentField,
    NavigationLinks
  }
})
export default class WhatWeNeed extends Vue {
  @Prop() noTitle!: boolean
  @Prop({ default: () => Vue.prototype.$gettext("What we need") }) stepTitle!: string
  @Prop() form!: IPSWhatWeNeedForm
  @Prop() formErrors!: IPSWhatWeNeedFormErrors

  @Prop({ required: true }) uploadUrl!: string | null
  @Prop({ required: true }) documentUploadUrl!: string | null
  @Prop({ default: () => [] }) existingPhotos!: IPhoto[]
  @Prop({ default: () => [] }) existingDocuments!: IPhoto[]

  updateLinks(updatedLinks: INavigationLink[]) {
    this.$set(this.form, 'links', updatedLinks)
  }
}
