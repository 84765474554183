
import { Component, Prop, Vue } from 'vue-property-decorator'
import StepNavigation from '@/components/processing_steps/StepNavigation.vue'
import FormButtons from '@/components/processing_steps/FormButtons.vue'
import { IProcessingStep } from '@/types/processingSteps'

@Component({
  components: {
    StepNavigation,
    FormButtons
  }
})
export default class StepContainer extends Vue {
  @Prop({ required: true, type: Array }) steps!: IProcessingStep[]
  @Prop({ required: true }) activeStep!: IProcessingStep

  stepClicked(step: IProcessingStep) {
    this.$emit('step-clicked', step)
  }

  saveBtnClicked() {
    this.$emit('save-btn-clicked', this.activeStep)
  }

  nextBtnClicked() {
    this.$emit('next-btn-clicked', this.activeStep)
  }

  saveAndNextBtnClicked() {
    this.$emit('save-and-next-btn-clicked', this.activeStep)
  }

  prevButtonClicked () {
    this.$emit('prev-btn-clicked', this.activeStep)
  }

  finishBtnClicked () {
    this.$emit('finish-btn-clicked', this.activeStep)
  }
}
