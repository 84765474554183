
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FormTitle from '../../FormTitle.vue'
import SingleSelectField from '../../commons/fields/SingleSelectField.vue'
import TextField from '../../commons/fields/TextField.vue'
import FormContainer from '../../commons/forms/FormContainer.vue'
import CharField from "@/components/processing_steps/commons/fields/CharField.vue"
import { IPSBankAccount } from '@/types/processingSteps'

@Component({
  components: {
    SingleSelectField,
    TextField,
    FormTitle,
    FormContainer,
    CharField
  }
})
export default class SelectBankAccount extends Vue {
  @Prop() initialBankAccountSlug!: string
  @Prop() availableBankAccounts!: IPSBankAccount[]

  selectedBankAccount = ''

  mounted () {
    this.selectedBankAccount = this.initialBankAccountSlug
  }

  @Watch('selectedBankAccount')
  onInitialBankAccountSlugChanged(newVal: string) {
    this.$emit('update:initialBankAccountSlug', newVal)
  }
}
