import { Vue, Component } from 'vue-property-decorator'

@Component
export default class StatusFormatterMixin extends Vue {
  liveIconClass = 'far fa-check fa-lg'
  reviewIconClass = 'far fa-clock fa-lg'
  draftIconClass = 'far fa-clipboard fa-lg'
  pausedIconClass = 'fas fa-pause fa-lg'
  archivedIconClass = 'fal fa-times fa-lg'

  /*
    Draft = ChoiceItem(1, _("in Progress"))
    Public = ChoiceItem(2, _("complete"))
    Archived = ChoiceItem(3, _("archived"))
    Paused = ChoiceItem(4, _("Paused"))
  */

  fundingPageStatusFormatter(status: number) {
    let iconClass = ''
    let tooltip = ''

    switch (status) {
      case 1:
        iconClass = this.draftIconClass
        tooltip = this.$gettext('Draft')
        break
      case 2:
        iconClass = this.liveIconClass
        tooltip = this.$gettext('Live')
        break
      case 3:
        iconClass = this.archivedIconClass
        tooltip = this.$gettext('Finished')
        break
      case 4:
        iconClass = this.pausedIconClass
        tooltip = this.$gettext('Paused')
        break
      default:
        iconClass = ''
        tooltip = this.$gettext('Unknown status')
    }

    return { icon: iconClass, tooltip }
  }

  projectStatusFormatter(status: {
    live: boolean,
    draft: boolean,
    review: boolean,
    paused: boolean,
    archived: boolean,
    orga_in_review: boolean,
  }): { icon: string, tooltip: string } {
    let iconClass = ''
    let tooltip = ''
    if (status.orga_in_review && !status.draft) {
      iconClass = this.reviewIconClass
      tooltip = this.$gettext('Organization in review')
    } else if (status.draft) {
      iconClass = this.draftIconClass
      tooltip = this.$gettext('Draft')
    } else if (status.live) {
      iconClass = this.liveIconClass
      tooltip = this.$gettext('Live')
    } else if (status.paused && !status.archived) {
      iconClass = this.pausedIconClass
      tooltip = this.$gettext('Paused')
    } else if (status.archived) {
      iconClass = this.archivedIconClass
      tooltip = this.$gettext('Finished')
    }
    return { icon: iconClass, tooltip }
  }
}
