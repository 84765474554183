
import { Watch, Component, Mixins } from 'vue-property-decorator'
import { IShowcasePageForm } from '@/types/showcase'
import ToastMixin from '@/mixins/ToastMixin'
import { TGenericObject } from '@/types/base'
import { isArray } from 'lodash'
@Component
export default class ShowcaseCreateUpdate extends Mixins(ToastMixin) {
  requiredFields = ["title", "organization", "template"]
  category = null

  pageForm: IShowcasePageForm = {
    title: "",
    salutation: "",
    text: "",
    template: "",
    primary_color: "",
    organization: "",
    category: "",
    photos: [],
    logo: [],
    documents: [],
    contact_person_title: "",
    contact_person: null,
    is_public: false,
    links: [],
    show_map: false,
    show_result_reports: false
  }

  formErrors: TGenericObject = {
    title: null,
    organization: null,
    template: null
  }

  @Watch('pageForm', { deep: true })
  onMyFormChange (updatedForm) {
    this.requiredFields.forEach((field) => {
      if (updatedForm[field]) {
        this.formErrors[field] = null
      }
    })
  }

  get categoryOption (): string {
    const categoryKey = isArray(this.$route.query.category) ? this.$route.query.category[0] : this.$route.query.category
    if (categoryKey === "promotion_recommendation") {
      return "1"
    } else if (categoryKey === "funding_request") {
      return "2"
    } else if (categoryKey === "funding_result") {
      return "3"
    }
  }

  get formData () {
    const templateOption = this.pageForm.template
    const formData = new FormData()
    formData.append('title', this.pageForm.title)
    formData.append('primary_color', this.pageForm.primary_color)
    formData.append('template', templateOption)
    for (let i = 0; i < this.pageForm.photos.length; i++) {
      formData.append('files', this.pageForm.photos[i], this.pageForm.photos[i].name)
    }
    for (let i = 0; i < this.pageForm.logo.length; i++) {
      formData.append('logo', this.pageForm.logo[i], this.pageForm.logo[i].name)
    }
    for (let i = 0; i < this.pageForm.documents.length; i++) {
      formData.append('documents', this.pageForm.documents[i], this.pageForm.documents[i].name)
    }
    for (let i = 0; i < this.pageForm.links.length; i++) {
      const link = JSON.stringify(this.pageForm.links[i])
      formData.append('links', link)
    }
    formData.append('organization', this.pageForm.organization)
    formData.append('text', this.pageForm.text)
    formData.append('contact_person_title', this.pageForm.contact_person_title)
    formData.append('salutation', this.pageForm.salutation)
    formData.append('is_public', String(this.pageForm.is_public))
    formData.append('show_map', String(this.pageForm.show_map))
    formData.append('show_result_reports', String(this.pageForm.show_result_reports))
    return formData
  }

  formIsValid (): boolean {
    let valid = true
    this.requiredFields.forEach((field) => {
      if (!this.formData.get(field)) {
        valid = false
        this.formErrors[field] = this.$gettext("This field is required")
      }
    })
    if (!valid) {
      this.makeToast(
        'danger',
        this.$gettext('Error'),
        this.$gettext('Please fill out the required fields')
      )
      return false
    }
    return true
  }

  handleOrganizationSelected (organization): void {
    this.pageForm.organization = organization
  }
}
