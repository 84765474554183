
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { IPhoto, IVideoProviders, IVideoProvider } from '@/types/photos'
import { setCookie } from '@/utils/helpers'

@Component({
  name: 'video-component'
})
export default class VideoComponent extends Vue {
  @Ref('iframeEl') readonly iframeEl!: HTMLIFrameElement
  @Prop({ required: true }) photo!: IPhoto
  @Prop({ default: false }) isContactPerson!: boolean
  @Prop({ default: "0" }) rounded!: string
  @Prop({ default: true }) descOnImage!: boolean
  videoProviders: IVideoProviders = {
    youtube: {
      name: "Youtube",
      cookieName: "youtube",
      cookieMsg: this.$gettext("YouTube cookies are not accepted"),
      acceptCookieMsg: this.$gettext("Activate YouTube")
    },
    streamio: {
      name: "Streamio",
      cookieName: "streamio",
      cookieMsg: this.$gettext("Streamio cookies are not accepted"),
      acceptCookieMsg: this.$gettext("Activate Streamio")
    },
    vimeo: {
      name: "Vimeo",
      cookieName: "vimeo",
      cookieMsg: this.$gettext("Vimeo cookies are not accepted"),
      acceptCookieMsg: this.$gettext("Activate Vimeo")
    },
    mozaik: {
      name: "Mozaik",
      cookieName: "mozaik",
      cookieMsg: this.$gettext("Mozaik cookies are not accepted"),
      acceptCookieMsg: this.$gettext("Activate Mozaik")
    }
  }

  @Watch("photo")
  handlePhotoChanged () {
    this.showVideo = false
    this.destroyYoutubePlayer()
  }

  get showImageDescription () {
    return !this.showVideo && (this.photo.main_description || this.photo.description)
  }

  get videoProvider (): IVideoProvider {
    return this.videoProviders[this.photo.video_provider]
  }

  get isMozaikVideo () {
    return this.videoProvider?.name === "Mozaik"
  }

  get isYoutubeVideo () {
    return this.videoProvider?.name === "Youtube"
  }

  get isStreamioVideo () {
    return this.videoProvider?.name === "Streamio"
  }

  get iframeProps() {
    if (this.isMozaikVideo) {
      return {
        src: this.photo.video + '&b=false&autoplay=true',
        class: 'mozaik-responsive-iframe',
        frameborder: '0',
        scrolling: 'no',
        allow: 'autoplay; clipboard-write;',
        allowfullscreen: true,
        sandbox: 'allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
      }
    } else if (this.isYoutubeVideo) {
      return {
        src: this.photo.video + '?enablejsapi=1&autoplay=1',
        id: this.photo.slug,
        class: 'embed-responsive-item',
        frameborder: '0',
        allow: 'autoplay; clipboard-write;',
        allowfullscreen: true
      }
    } else {
      return {
        src: this.photo.video,
        class: 'embed-responsive-item',
        frameborder: '0',
        allow: 'autoplay; clipboard-write;',
        allowfullscreen: true
      }
    }
  }

  showCookieBanner = false
  showVideo = false
  youtubePlayer: { destroy: () => void } = null

  gotoVideo (): void {
    this.addMessageListener()
    const cookieValues = this.$cookies.get('cookie_rule_detail')
    if (!cookieValues || !cookieValues.includes(this.videoProvider.cookieName)) {
      this.showCookieBanner = true
    } else {
      this.showCookieBanner = false
      this.showVideo = true
      this.$emit('iframe-active')
      if (this.isYoutubeVideo) {
        this.$nextTick(() => {
          this.createYouTubePlayer()
        })
      }
    }
  }

  handleStreamioMessage(event) {
    if (event.origin !== 'https://www.streamio.com') return
    const message = event.data
    if (message.event === 'streamio_ended') {
      this.showVideo = false
    }
  }

  addCookie (): void {
    const existingCookie = this.$cookies.get('cookie_rule_detail')
    const requiredCookieValue = this.videoProvider.cookieName

    if (existingCookie) {
      const updatedValue = `${existingCookie},${requiredCookieValue}`
      setCookie("cookie_rule_detail", updatedValue)
    } else {
      setCookie("cookie_rule_detail", requiredCookieValue)
    }
    if (this.isYoutubeVideo) {
      window.location.reload()
    }

    this.showCookieBanner = false
    this.showVideo = true
  }

  createYouTubePlayer() {
    this.youtubePlayer = new window.YT.Player(this.iframeEl, {
      events: {
        onStateChange: this.onPlayerStateChange.bind(this)
      }
    })
  }

  onPlayerStateChange(event: { data: number }) {
    if (event.data === window.YT.PlayerState.ENDED) {
      this.showVideo = false
      this.destroyYoutubePlayer()
    }
  }

  beforeDestroy() {
    this.removeMessageListener()
  }

  addMessageListener() {
    if (this.isStreamioVideo) {
      window.addEventListener('message', this.handleStreamioMessage)
    }
  }

  removeMessageListener() {
    if (this.isStreamioVideo) {
      window.removeEventListener('message', this.handleStreamioMessage)
    }
  }

  destroyYoutubePlayer() {
    this.youtubePlayer?.destroy?.()
    this.youtubePlayer = null
  }
}
