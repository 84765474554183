
import { Component, Mixins, Ref } from 'vue-property-decorator'
import axios from 'axios'
import { API_URLS, SITE_URLS } from "@/utils/helpers"
import ShowcasePageForm from './ShowcasePageForm.vue'
import ShowcaseCreateUpdate from './ShowcaseCreateUpdate.vue'
import { IShowcasePage } from '@/types/showcase'
import { IPhoto } from '@/types/photos'
import ShowcaseCategoryMixin from './ShowcaseCategoryMixin.vue'
import { IProcessingStep } from '@/types/processingSteps'
import ProcessingStepMixin from '../processing_steps/ProcessingStepMixin.vue'
import StepContainer from '../processing_steps/commons/StepContainer.vue'
import CharField from '../processing_steps/commons/fields/CharField.vue'
import FormContainer from '../processing_steps/commons/forms/FormContainer.vue'
import DocumentField from '../processing_steps/commons/fields/DocumentField.vue'
import ColorInput from '@/components/processing_steps/organization/forms/ColorInput.vue'
import TextFieldEditor from '../processing_steps/commons/fields/TextFieldEditor.vue'
import NavigationLinks from '@/components/navigation_link/NavigationLinks.vue'
import ShowcaseProjectSelect from './ShowcaseProjectSelect.vue'
import DisplayedProjectsOverview from './project_select/DisplayedProjectsOverview.vue'
import ContactPersonSelect from '@/components/processing_steps/commons/forms/ContactPersonSelect.vue'
import ProjectLinkButton from '@/components/buttons/ProjectLinkButton.vue'
import ShowcaseProjectFilter from './ShowcaseProjectFilter.vue'
import ShowcaseSummaries from './completition/ShowcaseSummaries.vue'
import SingleSelectField from '@/components/processing_steps/commons/fields/SingleSelectField.vue'
import ConfirmModal from '../processing_steps/commons/modals/ConfirmModal.vue'

@Component({
  components: {
    ShowcasePageForm,
    StepContainer,
    FormContainer,
    CharField,
    DocumentField,
    ColorInput,
    TextFieldEditor,
    NavigationLinks,
    ShowcaseProjectSelect,
    DisplayedProjectsOverview,
    ContactPersonSelect,
    ProjectLinkButton,
    ShowcaseProjectFilter,
    ShowcaseSummaries,
    SingleSelectField,
    ConfirmModal
  }
})
export default class ShowcasePageEdit extends Mixins(ShowcaseCreateUpdate, ShowcaseCategoryMixin, ProcessingStepMixin) {
  @Ref() contactPersonSelect!: ContactPersonSelect
  @Ref() showcaseProjectFilter!: ShowcaseProjectFilter

  existingImages: IPhoto[] = []
  existingLogos: IPhoto[] = []
  existingDocuments: IPhoto[] = []
  showcasePage: IShowcasePage | null = null
  availableTemplates: { value: string, text: string }[] = []
  mapOrImageOptions: { value: boolean, text: string }[] = []

  activeStep = null
  steps: IProcessingStep[] = []
  availableOrganizations: { value: string, text: string }[] = []

  projectLimitReachedModalOpen = false
  projectLimitReachedError = ""

  firstStep: IProcessingStep = {
    key: "basic",
    title: this.$gettext("Basic information"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  secondStep: IProcessingStep = {
    key: "content",
    title: this.$gettext("Content"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  thirdStep: IProcessingStep = {
    key: "contact_persons",
    title: this.$gettext("Contact person"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fourthStep: IProcessingStep = {
    key: "project_select",
    title: this.$gettext("Project selection"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fifthStep: IProcessingStep = {
    key: "completion",
    title: this.$gettext("Release"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: false,
      saveAndNext: false
    }
  }

  get photoUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.photo_album_slug) : null
  }

  get logoUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.logo_album_slug) : null
  }

  get documentUploadUrl () {
    return this.showcasePage ? API_URLS.PHOTOS.UPLOAD_PHOTO(this.showcasePage.document_album_slug) : null
  }

  get previewHref () {
    return SITE_URLS.SHOWCASE.PREVIEW(this.showcasePage.slug, this.showcasePage.preview_token)
  }

  get publicHref () {
    return SITE_URLS.SHOWCASE.PUBLIC(this.showcasePage.slug)
  }

  get baseInformationIsValid (): boolean {
    return !!this.pageForm.title && !!this.pageForm.contact_person_title && !!this.pageForm.template
  }

  get contentIsValid (): boolean {
    return !!this.pageForm.text && (this.existingImages !== undefined && this.existingImages.length > 0)
  }

  get contactPersonStepIsValid (): boolean {
    return !!this.showcasePage.contact_person
  }

  get canChangeTemplate (): boolean {
    /* if the template could change after adding filters,
    we would have to even more complexity,
    bc we would have to change the users filters according to the new admin filters */
    return !this.showcasePage || !this.showcasePage.filter_container.length
  }

  projectLimitOk (): boolean {
    if (!this.showcasePage || !this.showcasePage.template || this.showcasePage.template.project_limit === 0 || this.showcasePage.filter_growing === true) {
      return true
    }
    return this.showcaseProjectFilter.getCurrentlySelectedProjects().length <= this.showcasePage.template.project_limit
  }

  async mounted (): Promise<void> {
    if (this.$route.params.slug) {
      await this.fetchShowcasePage(this.$route.params.slug)
      this.setForm(this.showcasePage)
    }
    await this.fetchAvailableTemplates()
    await this.fetchAvailableOrganizations()
    this.updateBreadCrumbs()
    this.setSteps()
    this.setActiveStep(this.firstStep)
    this.setMapOrImageOptions()

    if (this.showcasePage) {
      this.setAllData()
      this.checkAndValidateSteps()
    }
  }

  setAllData (): void {
    this.steps.forEach(step => {
      this.checkAndValidateStep(step)
    })
  }

  setSteps (): void {
    this.steps = [
      this.firstStep,
      this.secondStep,
      this.thirdStep,
      this.fourthStep,
      this.fifthStep
    ]
  }

  gotoStep (clickedStep: IProcessingStep): void {
    if (!this.baseInformationIsValid) {
      this.showError({ title: 'Error!', message: 'Please fill out the required fields' })
      return
    }
    this.setActiveStep(clickedStep)
  }

  updateLinks () {
    // todo implement
    this.pageForm.links = []
  }

  showContactAdminError (): void {
    this.showError({ title: 'Error!', message: 'Please contact an administrator' })
  }

  handlePhotosChanged (photos: IPhoto[]): void {
    this.existingImages = photos
    this.checkAndValidateStep(this.activeStep)
  }

  handleLogoChanged (photos: IPhoto[]): void {
    this.existingLogos = photos
    this.checkAndValidateStep(this.activeStep)
  }

  handleFilesChanged (): void {
    axios.get(API_URLS.SHOWCASE_PAGE.RETRIEVE(this.showcasePage.slug)).then((response) => {
      this.existingDocuments = response.data.documents
    })
    this.setAllData()
  }

  handleProjectLimitReached (): void {
    const projectsCount = this.showcaseProjectFilter.getCurrentlySelectedProjects().length
    const projectLimit = this.showcasePage.template.project_limit
    const difference = projectsCount - projectLimit
    this.projectLimitReachedError = this.$gettextInterpolate(this.$gettext('Your selection of %{ count } projects exceeds the limit of %{ limit } projects on your funding page. Please remove %{ diff } projects from the table.'), { limit: projectLimit, count: projectsCount, diff: difference })
    this.projectLimitReachedModalOpen = true
  }

  setMapOrImageOptions (): void {
    this.mapOrImageOptions = [
      { value: false, text: this.$gettext('Layout with images') },
      { value: true, text: this.$gettext('Layout with map') }
    ]
  }

  async saveBtnClicked (currentStep: IProcessingStep): Promise<boolean> {
    let saved = false
    if (currentStep.key === "basic") {
      saved = await this.saveBaseInformation()
    } else if (currentStep.key === "content") {
      saved = await this.saveContent()
    } else if (currentStep.key === "contact_persons") {
      if (this.showcasePage) {
        saved = this.contactPersonStepIsValid
        if (!saved) {
          const message = this.$gettext("Please add a public contact person")
          this.showError({ title: 'Error!', message })
        }
      }
    } else if (currentStep.key === "project_select") {
      if (this.projectLimitOk()) {
        await this.showcaseProjectFilter.saveProjectsForEachFilterContainer()
        this.showcaseProjectFilter.saveFilterSettings()
        saved = true
      } else {
        this.handleProjectLimitReached()
      }
    }
    if (currentStep.key !== 'basic') {
      if (saved) {
        this.showSuccessMsg(null)
      } else {
        this.showError(null)
      }
    }
    this.checkAndValidateSteps()
    return saved
  }

  async fetchAvailableTemplates () {
    await axios.get(API_URLS.SHOWCASE_PAGE_TEMPLATE.AVAILABLE_TEMPLATES).then((response) => {
      this.availableTemplates = response.data
    })
  }

  async saveAndNextBtnClicked (currentStep: IProcessingStep): Promise<void> {
    const saved = await this.saveBtnClicked(currentStep)
    if ((this.baseInformationIsValid && saved) || (this.baseInformationIsValid && currentStep.required === false)) {
      this.gotoNextStepOrFinish(this.steps, currentStep)
    }
  }

  async fetchShowcasePage (slug: string): Promise<void> {
    await axios.get(API_URLS.SHOWCASE_PAGE.RETRIEVE(slug)).then((response) => {
      this.showcasePage = response.data
      this.category = this.showcasePage.category_key
    })
  }

  async fetchAvailableOrganizations (): Promise<void> {
    if (!this.isSiteAdmin) { return }
    await axios.get(API_URLS.ORGANIZATIONS.MINIMAL_LIST).then((response) => {
      this.availableOrganizations = response.data.map((org) => {
        return { value: org.slug, text: org.title }
      })
    })
  }

  async fetchThisShowcasePage(): Promise<void> {
    await axios.get(API_URLS.SHOWCASE_PAGE.RETRIEVE(this.showcasePage.slug)).then(async () => {
      await this.fetchShowcasePage(this.showcasePage.slug)
      this.setForm(this.showcasePage)
      this.setAllData()
    })
  }

  openCreateContactPersonModal (): void {
    this.contactPersonSelect.openEditModal(null)
  }

  async onPublicContactPersonChanged (): Promise<void> {
    await this.fetchShowcasePage(this.showcasePage.slug)
    this.checkAndValidateStep(this.activeStep)
  }

  checkAndValidateSteps(): void {
    this.steps.forEach(step => {
      this.checkAndValidateStep(step)
    })
  }

  checkAndValidateStep(step: IProcessingStep) {
    if (step.required === false) {
      this.setAdditionalStepValid(step)
      return
    }
    if (step.key === "basic") {
      if (this.baseInformationIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "content") {
      if (this.contentIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "contact_persons") {
      if (this.contactPersonStepIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'project_select') {
      if (this.showcasePage.filter_container.length > 0) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'completion') {
      if (this.showcasePage.status !== 1) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    }
  }

  get isCreate (): boolean {
    return this.showcasePage === null
  }

  updateBreadCrumbs() {
    const prefix = this.isSiteAdmin ? "/site-admin" : "/account"
    const tableUrl = prefix + '/showcase/?category=' + this.showcasePage?.category_key
    const createUrl = prefix + '/showcase/create/?category=' + this.showcasePage?.category_key
    const editUrl = prefix + '/showcase/' + this.showcasePage?.slug + '/update/?category=' + this.showcasePage?.category_key
    let breadcrumbs = []
    const projectIcon = 'projectIcon'
    if (this.isCreate) {
      breadcrumbs = [
        { title: this.$gettext("Funding pages"), url: tableUrl, icon: projectIcon },
        { title: this.$gettext("Create"), url: createUrl }
      ]
    } else {
      breadcrumbs = [
        { title: this.$gettext("Funding pages"), url: tableUrl, icon: projectIcon },
        { title: this.showcasePage?.title, url: editUrl }
      ]
    }
    this.$emit("breadcrumbs-set", breadcrumbs) // emits to ProcessingSteps.vue to set the new breadcrumbs
  }

  setForm (showcasePage) {
    this.pageForm.title = showcasePage.title
    this.pageForm.primary_color = showcasePage.primary_color
    this.pageForm.template = showcasePage.template.id.toString()
    this.pageForm.organization = showcasePage.organization
    this.pageForm.salutation = showcasePage.salutation
    this.pageForm.text = showcasePage.text
    this.pageForm.contact_person_title = showcasePage.contact_person_title
    this.pageForm.contact_person = showcasePage.contact_person
    this.pageForm.is_public = showcasePage.is_public
    this.pageForm.links = showcasePage.links
    this.pageForm.show_map = showcasePage.show_map
    this.pageForm.show_result_reports = showcasePage.show_result_reports

    this.existingImages = showcasePage.photos
    this.existingLogos = showcasePage.logos
    this.existingDocuments = showcasePage.documents
  }

  async saveBaseInformation(): Promise<boolean> {
    let saved = false
    if (this.isCreate) {
      await axios.post(API_URLS.SHOWCASE_PAGE.LIST, this.formData).then(async response => {
        saved = true
        this.showSuccessMsg(null)
        await this.fetchShowcasePage(response.data.slug)
        this.setAllData()
        if (this.isSiteAdmin) {
          this.$router.push({ name: 'site-admin-showcase-update', params: { slug: response.data.slug } })
        } else {
          this.$router.push({ name: 'account-showcase-update', params: { slug: response.data.slug } })
        }
      })
    } else {
      await axios.put(API_URLS.SHOWCASE_PAGE.RETRIEVE(this.showcasePage.slug), this.formData).then(async () => {
        await this.fetchShowcasePage(this.showcasePage.slug)
        this.showSuccessMsg(null)
        saved = true
      })
    }
    return saved
  }

  async createShowcasePageIfNotExists (): Promise<void> {
    if (!this.showcasePage) {
      if (!this.baseInformationIsValid) {
        this.showError(null)
      } else {
        await this.saveBaseInformation()
      }
    }
  }

  async saveContent(): Promise<boolean> {
    let saved = false
    const postData = {
      text: this.pageForm.text,
      organization: this.pageForm.organization
    }
    await axios.put(API_URLS.SHOWCASE_PAGE.RETRIEVE(this.showcasePage.slug), postData).then(async () => {
      await this.fetchShowcasePage(this.showcasePage.slug)
      this.setAllData()
      saved = true
    })
    return saved
  }

  backToTable () {
    if (this.isSiteAdmin) {
      this.$router.push({ name: 'site-admin-showcase-table' })
    } else {
      this.$router.push({ name: 'account-showcase-table' })
    }
  }

  onFundingPageStatusChanged (statusVerbose: string): void {
    const hasBeenPublished = statusVerbose === 'public'
    const hasBeenPaused = statusVerbose === 'paused'
    const hasBeenArchived = statusVerbose === 'archived'
    const hasBeenResumed = statusVerbose === 'resumed'

    if (hasBeenPublished) {
      this.showSuccessMsg(null)
      setTimeout(() => {
        this.backToTable()
      }, 700)
    } else if (hasBeenPaused) {
      this.showSuccessMsg({ title: this.$gettext('Funding proposal paused'), message: this.$gettext('You can resume it at any time.') })
      this.fetchThisShowcasePage()
    } else if (hasBeenArchived) {
      this.showSuccessMsg({ title: this.$gettext('Funding proposal finished'), message: this.$gettext('Your funding proposal is finished.') })
      this.fetchThisShowcasePage()
    } else if (hasBeenResumed) {
      this.showSuccessMsg({ title: this.$gettext('Funding proposal resumed'), message: this.$gettext('You can pause or finish it.') })
      this.fetchThisShowcasePage()
    }
    // this.$router.push({ name: 'account-showcase-update', params: { slug } }) - stay on edit page?
  }
}
