
import { Component, Mixins } from 'vue-property-decorator'
import ProcessingStepMixin from '../../ProcessingStepMixin.vue'
import { IPSEditProject, IProcessingStep } from '@/types/processingSteps'
import ProjectBaseInformationMixin from './ProjectBaseInformationMixin.vue'
import BaseInformationValidationMixin from './BaseInformationValidationMixin.vue'

@Component
export default class ProjectStepMixin extends Mixins(ProcessingStepMixin, ProjectBaseInformationMixin, BaseInformationValidationMixin) {
  steps: IProcessingStep[] = []

  // todo add type for IEditProject with the required fields to create/update projects
  project: IPSEditProject | null = null

  additionalStepsAfterFirstStep = []

  firstStep: IProcessingStep = {
    key: "basic",
    title: this.$gettext("Basic information"),
    completed: false,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  secondStep: IProcessingStep = {
    key: "what_we_need",
    title: this.$gettext("What we need"),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  thirdStep: IProcessingStep = {
    key: "contact_persons",
    title: "Kontaktpersonen",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fourthStep: IProcessingStep = {
    key: "assignment",
    title: this.$gettext('Categories and tags'),
    completed: false,
    required: false,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fifthStep: IProcessingStep = {
    key: "individual_bank_account",
    title: "Finanzierungskonto",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  sixtStep: IProcessingStep = {
    key: "funding_code",
    title: this.$gettext('Application'),
    completed: true,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  seventhStep: IProcessingStep = {
    key: "result_report",
    title: "Ergebnisbericht",
    completed: false,
    required: false,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  eightStep: IProcessingStep = {
    key: "completion",
    title: "Abschluss",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: this.isSiteAdmin,
      saveAndNext: false,
      finish: {
        show: false
      }
    }
  }

  setSteps (): void {
    this.steps = [
      this.firstStep,
      ...this.additionalStepsAfterFirstStep,
      this.secondStep,
      this.thirdStep,
      this.fourthStep,
      ...(this.isSiteAdmin ? [this.fifthStep] : []),
      this.sixtStep,
      this.seventhStep,
      this.eightStep
    ]
  }

  checkAndValidateStep(step: IProcessingStep) {
    if (step.key === "completion" && !step.completed) {
      step.completed = !!(this.project.status !== 1 && this.project.status)
    }
    if (step.required === false) {
      this.setAdditionalStepValid(step)
      return
    }
    if (step.key === "basic") {
      if (this.baseInformationIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "photos") {
      if (this.photoStepIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "contact_persons") {
      if (this.contactPersonIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'individual_bank_account') {
      if (this.project?.individual_bank_account) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'result_report') {
      if (this.project.has_public_result_report) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'assignment') {
      if (this.project.funding_scope_slug !== "") {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === 'financing_needs') {
      if (this.project.goal?.in_cents > 0) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    } else if (step.key === "what_we_need") {
      if (this.whatWeNeedIsValid) {
        this.setAdditionalStepValid(step)
      } else {
        this.setAdditionalStepInvalid(step)
      }
    }
  }

  get whatWeNeedIsValid () {
    const requiredFields = ["description"]
    return requiredFields.every(field => this.project && this.project.what_we_need && this.project.what_we_need[field])
  }

  get photoStepIsValid () {
    const value = this.project?.photos.length > 0
    return value
  }

  get contactPersonIsValid () {
    return this.project?.has_public_contact_person
  }
}
