

import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import ToastMixin from '@/mixins/ToastMixin'
import FormContainer from './FormContainer.vue'
import CharField from "@/components/processing_steps/commons/fields/CharField.vue"
import axios from "axios"
import InviteAdminModal from "@/components/modals/InviteAdminModal.vue"
import SearchField from '@/components/processing_steps/commons/fields/SearchField.vue'
import DeleteButton from "@/components/buttons/DeleteButton.vue"
@Component({
  components: {
    InviteAdminModal,
    CharField,
    FormContainer,
    SearchField,
    DeleteButton
  }
})
export default class AdminSelectForm extends Mixins(UserMixin, ToastMixin) {
  @Prop() selectedAdmins!: string[]
  @Prop() organizationSlug!: string

  searchQuery = ''
  filteredResults = []
  dropdownVisible = false
  showInviteModal = false

  @Watch('searchQuery')
  onSearchQueryChanged() {
    if (this.searchQuery.length > 2) {
      const url = '/api/v3/actions/typeahead/'
      const postdata = {
        fields: ['username', 'email'],
        model: 'user',
        search_string: this.searchQuery
      }
      axios.post(url, postdata)
        .then(response => {
          this.filteredResults = response.data.map(innerArray => innerArray[0])
        })
    }
  }

  selectResult(option: string) {
    this.searchQuery = option
    this.dropdownVisible = false
  }

  onInviteSucceded() {
    this.$emit('user-was-invited')
  }

  onAddClick() {
    if (this.filteredResults.includes(this.searchQuery)) {
      const updatedAdmins = [...this.selectedAdmins, this.searchQuery]
      this.$emit('update:selectedAdmins', updatedAdmins)
      this.searchQuery = ''
    }
  }

  removeAdmin(index) {
    this.selectedAdmins.splice(index, 1)
  }
}
