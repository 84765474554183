
import CheckboxField from '@/components/processing_steps/commons/fields/CheckboxField.vue'
import FormContainer from '@/components/processing_steps/commons/forms/FormContainer.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CompletitionLayout from '@/components/processing_steps/commons/CompletitionLayout.vue'

@Component({
  components: {
    FormContainer,
    CheckboxField,
    CompletitionLayout
  }
})
export default class AccountPublishOrgaForm extends Vue {
  @Prop() termsAccepted!: boolean
  @Prop() isDraft!: boolean
  @Prop() isComplete!: boolean
  @Prop() summaryItems!: { title: string, items: { title: string, values: string[] }[]}[]
  @Prop() termsAndPrivacyHtml!: string

  get isCompleteButNotSubmitted (): boolean {
    return this.isComplete && this.isDraft
  }

  get statusExplanation (): string {
    return this.isComplete
      ? this.$gettext('The organizational data is complete. Do you want to submit the organization for review by Haus des Stiftens now? You can create funding areas and funding requirements until they have been approved by Haus des Stiftens, but you cannot publish them yet.')
      : this.$gettext('The organizational data is incomplete and you can only create funding areas and funding requirements once you have entered the organizational data in full.')
  }
}
