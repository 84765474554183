
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CharField from '@/components/processing_steps/commons/fields/CharField.vue'

@Component({
  components: {
    CharField
  }
})
export default class ColorInput extends Vue {
@Prop() value: string

localValue = "#"
placeholder = '#fbbb59'

@Watch('value')
onValueChange (value: string): void {
  this.localValue = value
}

mounted (): void {
  this.localValue = this.value
}

updateLocatValue (value: string): void {
  this.localValue = value
}

updateValue (event: Event): void {
  this.$emit("change", (event.target as HTMLInputElement).value)
  this.$emit("update:value", (event.target as HTMLInputElement).value)
}

updateValueText (value: string): void {
  if (!value.startsWith('#')) {
    value = '#' + value
  }
  this.$emit("change", value)
  this.$emit("update:value", value)
}
}
