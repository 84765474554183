
import { IProcessingStep } from '@/types/processingSteps'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class StepNavigation extends Vue {
  @Prop({ default: () => [] }) navigationSteps!: IProcessingStep[]
  @Prop() steps!: IProcessingStep[]
  @Prop() activeStep!: IProcessingStep

  clicked (step: IProcessingStep): void {
    this.$emit('step-clicked', step)
  }

  isActiveStep(step: IProcessingStep): boolean {
    return this.activeStep.key === step.key
  }

  @Watch('steps', { deep: true })
  onStepsChanged(newSteps: IProcessingStep[], oldSteps: IProcessingStep[]) {
    for (let i = 0; i < newSteps.length; i++) {
      if (oldSteps[i] && newSteps[i].completed !== oldSteps[i].completed) {
        this.steps[i].completed = newSteps[i].completed
      }
    }
  }
}
