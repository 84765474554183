
import { Component, Mixins, Prop } from 'vue-property-decorator'
import UserMixin from '@/mixins/UserMixin'
import { API_URLS } from '@/utils/helpers'
import { IShowcasePage } from '@/types/showcase'
import { BvTableFieldArrayWithStickColumn, TGenericObject } from '@/types/base'
import SelectTable from './SelectTable.vue'
@Component({
  components: {
    SelectTable
  }
})
export default class ByOrganization extends Mixins(UserMixin) {
  @Prop({ required: false }) showcasePage: IShowcasePage

  fields: BvTableFieldArrayWithStickColumn = [
    { key: 'title', label: this.$gettext('Page title'), sortable: true },
    { key: 'projects', label: this.$gettext('Projects count'), sortable: true, formatter: (projects) => { return projects?.filter((project) => project.status === 2).length || "-" } },
    { key: 'fixed_action_col', label: this.$gettext('Actions') }
  ]

  get showcaseOrganizations () {
    if (!this.showcasePage) {
      return []
    } else {
      return this.showcasePage.organizations
    }
  }

  get onlySelected (): TGenericObject {
    return {
      review_status: '2',
      showcase_page_slug: this.showcasePage.slug
    }
  }

  get onlyNotSelected (): TGenericObject {
    return {
      review_status: '2',
      showcase_page_slug: this.showcasePage.slug,
      showcase_exclude_own: 'true'
    }
  }

  get initialArray () {
    if (!this.showcasePage) {
      return []
    } else {
      return this.showcasePage.organizations
    }
  }

  get apiUrl () {
    return API_URLS.ORGANIZATIONS.LIST
  }

  get totalProjectsCount () {
    return this.showcasePage ? this.showcasePage.projects_count : 0
  }

  handleItemsRemoved (organizations) {
    this.$emit("organizations-removed", organizations)
  }

  handleItemsSelected (organizations) {
    this.$emit("organizations-selected", organizations)
  }
}
