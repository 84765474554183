
import { Component, Prop, Watch } from 'vue-property-decorator'
import NavigationIcon from '@/components/promoter_signup/NavigationIcon.vue'
import OrganizationForm from '@/components/promoter_signup/OrganizationForm.vue'
import ContactPersonForm from '@/components/promoter_signup/ContactPersonForm.vue'
import Summary from '@/components/promoter_signup/Summary.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { IPSAddressForm } from '@/types/processingSteps'

@Component({
  components: {
    NavigationIcon,
    OrganizationForm,
    Summary,
    ContactPersonForm
  }
})
export default class ConfirmAddressModal extends ToastMixin {
  @Prop() locationForm!: IPSAddressForm
  @Prop() formattedAddress!: IPSAddressForm
  @Prop() modalOpen!: boolean

  isOpen = false

  @Watch('modalOpen')
  onModalOpenChange (value: boolean): void {
    this.isOpen = value
  }

  changeAddress (): void {
    this.$emit('change-address', this.formattedAddress)
  }

  keepAddress (): void {
    this.$emit('keep-address')
  }
}
