
import { Component, Mixins, Prop } from 'vue-property-decorator'
import ToastMixin from '@/mixins/ToastMixin'
import { IFundingCode } from "@/types/funding-code"
import axios from "axios"
@Component
export default class RedirectToFormBtn extends Mixins(ToastMixin) {
  @Prop() projectSlug!: string
  @Prop() fundingCode!: IFundingCode

  disabled = false
  isLoading = false

  mounted () {
    this.disabled = false
  }

  async redirectToExternalForm (): Promise<void> {
    this.isLoading = true
    this.disabled = true
    const url = this.fundingCode.form_link + '&slug=' + this.projectSlug + "&foerder-code=" + this.fundingCode.code_string
    await axios.get(url).then(response => {
      const documentHref = response.data
      this.$emit('document-href-updated', documentHref, this.fundingCode.id)
      this.isLoading = false
    }).catch(() => {
      this.isLoading = false
      this.disabled = false
      this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to open form link'))
    })
  }

  linkWasClicked (): boolean {
    const clickedLinks = JSON.parse(localStorage.getItem('clickedCodeLinks') || '[]')
    return clickedLinks.some(link => link.code_string === this.fundingCode.code_string && link.projectSlug === this.projectSlug)
  }
}
