
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Loader } from '@googlemaps/js-api-loader'
import { ExploreModule } from "@/store/modules/explore"
import { IPageSettings } from "@/types/cms"
import { IPSAddressForm, IPSLocationForm } from '@/types/processingSteps'

@Component
export default class SingeLocationMap extends Vue {
  @Prop() locationForm!: IPSLocationForm
  @Prop() address!: IPSAddressForm
  pageSettings: IPageSettings | null = null

  @Watch('locationForm.longitude')
  @Watch('locationForm.latitude')
  onLocationChange (): void {
    if (!this.pageSettings) {
      return
    }
    this.initializeMap()
  }

  async created (): Promise<void> {
    this.$wait.start('load data')
    const fetchContext = {
      slug: "home"
    }
    await ExploreModule.fetchPageSettings(fetchContext)
    this.pageSettings = ExploreModule.pageSettingsMap.home
    this.$wait.end('load data')
    this.initializeMap()
  }

  initializeMap() {
    const loader = new Loader({
      apiKey: this.pageSettings.googleMapsApiKey, // Set your Google Maps API key here
      version: "weekly",
      libraries: ["places"]
    })
    loader.load().then(() => {
      const mapOptions = {
        center: {
          lat: this.locationForm.latitude,
          lng: this.locationForm.longitude
        },
        zoom: 14
      }
      if (this.$refs.mapContainer instanceof HTMLElement) {
        const map = new google.maps.Map(this.$refs.mapContainer, mapOptions)

        // Add a marker at the center of the map
        // eslint-disable-next-line no-new
        new google.maps.Marker({
          position: mapOptions.center,
          map
        })
      }
    })
  }
}
