
import {
  IPSAddtitionalProcessingStep,
  IProcessingStep,
  IPSEditOrganization,
  IPSAdminEditOrganization
} from '@/types/processingSteps'
import { Component, Vue } from 'vue-property-decorator'
import EditOrganization from '../EditOrganization.vue'
import axios from 'axios'

@Component
export default class OrganisationStepMixin extends Vue {
  steps: (IProcessingStep | IPSAddtitionalProcessingStep)[] = []
  organization: IPSEditOrganization | IPSAdminEditOrganization | null = null
  additionalStepsAfterFirstStep = []
  projectCategoryAvailability = false

  firstStep: IProcessingStep = {
    key: "basic",
    title: "Basisinformationen",
    completed: true,
    required: true,
    active: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  secondStep: IProcessingStep = {
    key: "who_we_are",
    title: this.$gettext('Who we are'),
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  thirdStep: IProcessingStep = {
    key: "contact_persons",
    title: "Kontaktpersonen",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fourthStep: IProcessingStep = {
    key: "assignment",
    title: this.$gettext('Categories and tags'),
    completed: false,
    required: false,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  fifthStep: IProcessingStep = {
    key: "bank_account",
    title: "Bankkonto",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  sixthStep: IProcessingStep = {
    key: "proof",
    title: "Nachweis",
    completed: false,
    active: false,
    required: true,
    buttons: {
      save: true,
      saveAndNext: true
    }
  }

  seventhStep: IProcessingStep = {
    key: "completition",
    title: "Abschluss",
    completed: false,
    required: true,
    active: false,
    buttons: {
      save: this.forSiteAdmin,
      saveAndNext: false,
      finish: {
        show: false,
        text: this.forSiteAdmin ? null : this.$gettext('Complete')
      }
    }
  }

  async mounted(): Promise<void> {
    await this.setProjectCategoryAvailability()
  }

  get forSiteAdmin (): boolean {
    const parent = this.$parent as EditOrganization
    const value = parent.isSiteAdmin || false
    return value
  }

  get isLive () {
    return this.organization ? this.organization.review_status.choice === 2 : false
  }

  get isDraft () {
    return this.organization ? this.organization.status.choice === 1 : false
  }

  async setProjectCategoryAvailability() {
    await axios.get("/api/v4/projectcategories/?available_for=organizations").then((response) => {
      this.projectCategoryAvailability = response.data.results.length > 0
    })
  }

  setSteps (): void {
    this.steps = [
      this.firstStep,
      ...this.additionalStepsAfterFirstStep,
      this.secondStep,
      this.thirdStep,
      ...(this.projectCategoryAvailability ? [this.fourthStep] : []),
      this.fifthStep,
      this.sixthStep,
      this.seventhStep
    ]
    if (this.isDraft && !this.forSiteAdmin) {
      this.seventhStep.buttons.finish.show = false
    }
  }
}
