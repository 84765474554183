
import DeleteModal from '@/components/modals/DeleteModal.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import NavigationLink from './NavigationLink.vue'
import { INavigationLink } from '@/types/foerderApp'
import GenericTable from '@/components/GenericTable.vue'
import axios from 'axios'
import { API_URLS } from '@/utils/helpers'
import DeleteButton from '../buttons/DeleteButton.vue'
import EditButton from '../buttons/EditButton.vue'
import ConfirmModal from '../processing_steps/commons/modals/ConfirmModal.vue'
import CharField from '../processing_steps/commons/fields/CharField.vue'
@Component({
  components: {
    DeleteModal,
    NavigationLink,
    GenericTable,
    DeleteButton,
    EditButton,
    ConfirmModal,
    CharField
  }
})
export default class NavigationLinks extends Vue {
  @Prop({ required: false }) currentLinks: INavigationLink[]
  @Prop() instanceSlug: string
  @Prop() instanceType: string

  selectedLink: INavigationLink | null = null
  deleteModalOpen = false
  editModalOpen = false
  displayedLinks: INavigationLink[] = []
  fadeClass = ''
  modalFormError = ''

  mounted () {
    this.displayedLinks = this.currentLinks
  }

  fields = [
    {
      key: 'href',
      label: this.$gettext('Links')
    },
    {
      key: 'name',
      label: this.$gettext('Link text')
    },
    {
      key: 'actions',
      label: this.$gettext('Actions')
    }
  ]

  onLinkCreated (responseData, link = null) {
    this.$emit('link-created', link)
    this.fadeOutAndIn()
  }

  onLinkUpdated (responseData) {
    this.displayedLinks = responseData
    this.$emit('links-changed', this.displayedLinks)
    this.fadeOutAndIn()
  }

  onLinkDeleted (responseData) {
    this.currentLinks = responseData
    this.fadeOutAndIn()
  }

  fadeOutAndIn () {
    this.fadeClass = 'fade-element'
    setTimeout(() => {
      this.fadeClass = ''
    }, 1000)
  }

  openDeleteModal (link: INavigationLink): void {
    this.selectedLink = link
    this.deleteModalOpen = true
  }

  openEditModal (link: INavigationLink): void {
    this.selectedLink = link
    this.editModalOpen = true
  }

  async deleteLink (): Promise<void> {
    const formData = new FormData()
    formData.append('instance_slug', this.instanceSlug)
    formData.append('instance_type', this.instanceType)
    await axios.delete(API_URLS.NAVIGATION_LINK.RETRIEVE(this.selectedLink.id), { data: formData }).then(response => {
      this.displayedLinks = response.data.links
      this.$emit('links-changed', this.displayedLinks)
      this.deleteModalOpen = false
      this.selectedLink = null
      this.fadeOutAndIn()
    })
  }

  async updateLink () {
    const formData = new FormData()
    formData.append('link', JSON.stringify(this.selectedLink))
    formData.append('instance_slug', this.instanceSlug)
    formData.append('instance_type', this.instanceType)
    this.modalFormError = ''
    await axios.put(API_URLS.NAVIGATION_LINK.RETRIEVE(this.selectedLink.id), formData).then(() => {
      this.editModalOpen = false
      this.selectedLink = null
    }).catch(error => {
      if (error.response && error.response.data) {
        this.openEditModal(this.selectedLink)
        this.modalFormError = error.response.data.msg.href[0]
      }
    })
  }
}
