
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class HdsCheckbox extends Vue {
  @Prop({ default: false }) readonly value!: boolean

  get isChecked() {
    return this.value
  }

  set isChecked(val: boolean) {
    this.$emit('input', val)
  }

  @Emit('change')
  emitChange(event: Event) {
    return (event.target as HTMLInputElement).checked
  }
}
